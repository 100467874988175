import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_LOGIN,
  REGISTER,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  GET_COUNTRY_LIST,
  UPDATE_USER_PROFILE,
  GET_ACTIVE_USER_LIST,
  GET_PENDING_USER_LIST,
  GET_INACTIVE_USER_LIST,
} from "./actionTypes";
import {
  getLoginSuccess,
  getLoginFail,
  registerSuccess,
  registerFail,
  forgetPasswordSuccess,
  forgetPasswordFail,
  resetPasswordSuccess,
  resetPasswordFail,
  getCountryListSuccess,
  getCountryListFail,
  updateUserProfileSuccess,
  updateUserProfileFail,
  getActiveUserListSuccess,
  getActiveUserListFail,
  getPendingUserListSuccess,
  getPendingUserListFail,
  getInactiveUserListSuccess,
  getInactiveUserListFail,
} from "./actions";
import {
  login,
  Registration,
  forgetPassword,
  resetPassword,
  getCountryList,
  updateUserProfile,
  getUserList,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onGetLogin(data) {
  try {
    const response = yield call(login, data);

    if (response) {
      if (response.code === 200) {
        yield put(getLoginSuccess(response.data));
      } else {
        yield put(getLoginFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getLoginFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getLoginFail(error.response));
  }
}

function* onRegistration(data) {
  try {
    const response = yield call(Registration, data);
    if (response) {
      if (response.code === 200) {
        yield put(registerSuccess(response.data));
      } else {
        showError(response.message);
        yield put(registerFail(response.message));
      }
    } else {
      yield put(registerFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(registerFail(error.response));
  }
}

function* onForgetPassword(data) {
  try {
    const response = yield call(forgetPassword, data);
    if (response) {
      if (response.code === 200) {
        yield put(forgetPasswordSuccess(response.data));
      } else {
        showError(response.message);
        yield put(forgetPasswordFail(response.message));
      }
    } else {
      yield put(forgetPasswordFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(forgetPasswordFail(error.response));
  }
}

function* onResetPassword(data) {
  try {
    const response = yield call(resetPassword, data);
    if (response) {
      if (response.code === 200) {
        yield put(resetPasswordSuccess(response.data));
      } else {
        showError(response.message);
        yield put(resetPasswordFail(response.message));
      }
    } else {
      yield put(resetPasswordFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(resetPasswordFail(error.response));
  }
}

function* onGetCountryList(data) {
  try {
    const response = yield call(getCountryList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getCountryListSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getCountryListFail(response.message));
      }
    } else {
      yield put(getCountryListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCountryListFail(error.response));
  }
}

function* onUpdateUserProfile(data) {
  try {
    const response = yield call(updateUserProfile, data);
    if (response) {
      if (response.code === 200) {
        yield put(updateUserProfileSuccess(response.message));
      } else {
        showError(response.message);
        yield put(updateUserProfileFail(response.message));
      }
    } else {
      yield put(updateUserProfileFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateUserProfileFail(error.response));
  }
}

function* onGetActiveUserList(data) {
  try {
    const response = yield call(getUserList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getActiveUserListSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getActiveUserListFail(response.message));
      }
    } else {
      yield put(getActiveUserListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getActiveUserListFail(error.response));
  }
}

function* onGetPendingUserList(data) {
  try {
    const response = yield call(getUserList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getPendingUserListSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getPendingUserListFail(response.message));
      }
    } else {
      yield put(getPendingUserListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getPendingUserListFail(error.response));
  }
}

function* onGetInactiveUserList(data) {
  try {
    const response = yield call(getUserList, data);
    if (response) {
      if (response.code === 200) {
        yield put(getInactiveUserListSuccess(response.data));
      } else {
        showError(response.message);
        yield put(getInactiveUserListFail(response.message));
      }
    } else {
      yield put(getInactiveUserListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getInactiveUserListFail(error.response));
  }
}

function* LoginSaga() {
  yield takeLatest(GET_LOGIN, onGetLogin);
  yield takeLatest(REGISTER, onRegistration);
  yield takeLatest(FORGET_PASSWORD, onForgetPassword);
  yield takeLatest(RESET_PASSWORD, onResetPassword);
  yield takeLatest(GET_COUNTRY_LIST, onGetCountryList);
  yield takeLatest(UPDATE_USER_PROFILE, onUpdateUserProfile);
  yield takeLatest(GET_ACTIVE_USER_LIST, onGetActiveUserList);
  yield takeLatest(GET_PENDING_USER_LIST, onGetPendingUserList);
  yield takeLatest(GET_INACTIVE_USER_LIST, onGetInactiveUserList);
}

export default LoginSaga;
