import React, { useState, useEffect, useMemo } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ActiveUserList from "./ActiveUserList";
import PendingUserList from "./PendingUserList";
import InactiveUserList from "./InactiveUserList";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("active");
  return (
    <>
    <div className="admin-right-wrap right-content-wrap">
      <div className="user-info-sec">
        <ul className="d-flex user-info-inner custom-tabs nav nav-tabs">
          <li
            className={activeTab === "active" ? "active-list" : ""}
            onClick={() => setActiveTab("active")}
          >
            <span>Active User</span>
          </li>
          <li
            className={activeTab === "inActive" ? "active-list" : ""}
            onClick={() => setActiveTab("inActive")}
          >
            <span>Inactive User</span>
          </li>
          <li
            className={activeTab === "pending" ? "active-list" : ""}
            onClick={() => setActiveTab("pending")}
          >
            <span>Pending User</span>
          </li>
        </ul>
        {activeTab === "active" && <ActiveUserList />}
        {activeTab === "inActive" && <InactiveUserList />}
        {activeTab === "pending" && <PendingUserList />}
      </div>
    </div>

      {/* <div className="right-content-wrap">
        <Tabs
          defaultActiveKey="active"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="active" title="Active User">
            <ActiveUserList />
          </Tab>
          <Tab eventKey="pending" title="Pending User">
            <PendingUserList />
          </Tab>
          <Tab eventKey="inactive" title="Inactive User">
            <InactiveUserList />
          </Tab>
        </Tabs>
      </div> */}
    </>
  );
};

export default Settings;
