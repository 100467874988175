import React, { useEffect } from "react";
import Swal from "sweetalert2";

const DeleteConfirm = (props) => {
  useEffect(() => {
    Swal.fire({
      title: props.msg,
      text: "",
      icon: props.icon,
      position: "top",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: "#65cc88",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      customClass: {
        container: "clock-out-dialog",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteRecord();
      } else {
        props.handleDeleteClose();
      }
    });
  }, []);

  return <></>;
};
export default DeleteConfirm;
