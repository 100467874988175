import Loadable from "react-loadable";

import { Loading } from "../../views/common/navigation";

const FeaturedList = Loadable({
  loader: () => import("./components/featuredList"),
  loading: Loading,
});

export const routes = [
  {
    path: "/admin/featured-list",
    exact: true,
    name: "FeaturedList",
    element: FeaturedList,
  },
];

export * from "./components";
