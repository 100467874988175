//Login
export const LOGIN = "login";

//Register
export const REGISTER = "createUser";

// Forget password
export const FORGET_PASSWORD = "forgotPassword";

// Reset password
export const RESET_PASSWORD = "resetPassword";

/***************************************
 ░░░░░░░░░░░░░ create canvas ░░░░░░░░░░░ 
****************************************/

/***************************************
 ░░░░░░░░░░░░░ get canvas by id ░░░░░░░░ 
****************************************/

// get rider and driver
export const GET_RIDER_AND_DRIVER = "rider/get/";

// get 90 day plan
export const GET_90DAY_PLAN = "day_pan/get/";

// get tech stack
export const GET_TECh_STACK = "tack_stack/get/";

// get tesla_value
export const GET_TESLA_VALUE = "tesla_value/get/";

// get vision board
export const GET_VISION_BOARD = "vision/get/";

/***************************************
 ░░░░░░░░░░░░░ get canvas by id ░░░░░░░░
****************************************/

/***************************************
 ░░░░░░░░░░░░░ save canvas ░░░░░░░░░░░ 
****************************************/

// save rider and driver
export const SAVE_RIDER_AND_DRIVER = "rider/store";

// save 90 day plan
export const SAVE_90DAY_PLAN = "day_pan/store";

// save tech stack
export const SAVE_TECh_STACK = "tack_stack/store";

// save tesla_value
export const SAVE_TESLA_VALUE = "tesla_value/store";

// save vision board
export const SAVE_VISION_BOARD = "vision/store";

/***************************************
 ░░░░░░░░░░░░░ save canvas ░░░░░░░░░░░ 
****************************************/

/***************************************
 ░░░░░░░░░░░░░ update canvas ░░░░░░░░░░░ 
****************************************/

// update rider and driver
export const UPDATE_RIDER_AND_DRIVER = "rider/pudate/";

// update 90 day plan
export const UPDATE_90DAY_PLAN = "day_pan/pudate/";

// update tech stack
export const UPDATE_TECh_STACK = "tack_stack/update/";

// update tesla_value
export const UPDATE_TESLA_VALUE = "tesla_value/pudate/";

// update vision board
export const UPDATE_VISION_BOARD = "vision/update/";

/***************************************
 ░░░░░░░░░░░░░ update canvas ░░░░░░░░░░░ 
****************************************/

/***************************************
 ░░░░░░░░░░░░░ delete canvas ░░░░░░░░░░░ 
****************************************/

// delete rider and driver
export const DELETE_RIDER_AND_DRIVER = "riders-drivers/delete/";

// delete 90 day plan
export const DELETE_90DAY_PLAN = "90-days-plane/delete/";

// delete tech stack
export const DELETE_TECh_STACK = "tech-stack/delete/";

// delete tesla_value
export const DELETE_TESLA_VALUE = "tesla-value-canvas/delete/";

// delete Vision Board
export const DELETE_VISION_BOARD = "vision/delete/";

/***************************************
 ░░░░░░░░░░░░░ delete canvas ░░░░░░░░░░░ 
****************************************/

// get all canvas list
export const GET_ALL_CANVAS_LIST = "featured_models/get_all";

// get canvas by status
export const GET_CANVAS_BY_STATUS = "get_model_all/get_all?model_status=";

// get all canvas by status
export const GET_All_CANVAS_BY_STATUS =
  "get_canvas_by_status/get_all?model_status=";

// buy SUBSCRIPTION
export const BUY_SUBSCRIPTION = "subscription_plan/byplan";

// get user subscription
export const GET_USER_SUBSCRIPTION = "subscription_plan/get_byplan_list";

// GET COUNTRY LIST
export const GET_COUNTRY_LIST = "country_list";

// update user profile
export const UPDATE_USER_PROFILE = "profileUpdate";

// update user password
export const UPDATE_USER_PASSWORD = "changePassword";

// share canvas
export const SHARE_CANVAS = "sharingStore";

// remove shared canvas
export const REMOVE_SHARED_CANVAS = "sharingRemove";

// get shared canvas list
export const SHARED_CANVAS_LIST = "sharingList/";

/***************************************
 ░░░░░░░░░░░░░ admin apis ░░░░░░░░░░░░░░ 
****************************************/

// Admin Login
export const ADMINLOGIN = "admin/login";

// get user list
export const GET_USER_LIST = "allUserList/";

// change user status
export const CHANGE_USER_STATUS = "admin/user/statusUpdate";

// send mail
export const SEND_MAIL = "send-mail";

// update user data
export const UPDATE_USER_DATA = "admin/user/updateUser";

// update featured models
export const UPDATE_FEATURED_MODELS = "featured_models/featuredUpdate";

// get user By id
export const GET_USER_BY_ID = "user/get/";

// get models count
export const GET_MODELS_COUNT = "admin/modelsCount";

// get user count
export const GET_USER_COUNT = "admin/userCount";

// get models count by userId
export const GET_MODELS_COUNT_BU_USERID = "admin/userModelsCount/";

/***************************************
 ░░░░░░░░░░░░░ admin apis ░░░░░░░░░░░░░░
****************************************/

// create stripe payment
export const CREATE_STRIPE_PAYMENT = "create-subscription";

// cancel stripe payment
export const CANCEL_STRIPE_PAYMENT = "cancel-subscription/";

// get user card details
export const GET_USER_CARD_DETAILS = "card_details/get";

// update user card details
export const UPDATE_USER_CARD_DETAILS = "card_details/update";

// delete Account
export const DELETE_ACCOUNT = "user/account/delete";
