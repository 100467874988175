import {
  GET_RIDRER_AND_DRIVER,
  GET_RIDRER_AND_DRIVER_SUCCESS,
  GET_RIDRER_AND_DRIVER_FAIL,
  GET_DAY90_PLAN,
  GET_DAY90_PLAN_SUCCESS,
  GET_DAY90_PLAN_FAIL,
  GET_TECH_STACK,
  GET_TECH_STACK_SUCCESS,
  GET_TECH_STACK_FAIL,
  GET_TESLA_VALUE,
  GET_TESLA_VALUE_SUCCESS,
  GET_TESLA_VALUE_FAIL,
  GET_VISION_BOARD,
  GET_VISION_BOARD_SUCCESS,
  GET_VISION_BOARD_FAIL,
} from "./actionTypes";

const initialState = {
  getRiderAndDriver: null,
  getDay90Plan: null,
  getTechStack: null,
  getTeslaValue: null,
  getVisionBoard: null,
  loadingLogins: false,
  error: {
    message: "",
  },
};

const GetCanvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RIDRER_AND_DRIVER:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_RIDRER_AND_DRIVER_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        getRiderAndDriver: action.payload,
      };
      break;
    case GET_RIDRER_AND_DRIVER_FAIL:
      state = {
        ...state,
        getRiderAndDriver: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_DAY90_PLAN:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_DAY90_PLAN_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        getDay90Plan: action.payload,
      };
      break;
    case GET_DAY90_PLAN_FAIL:
      state = {
        ...state,
        getDay90Plan: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_TECH_STACK:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_TECH_STACK_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        getTechStack: action.payload,
      };
      break;
    case GET_TECH_STACK_FAIL:
      state = {
        ...state,
        getTechStack: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_TESLA_VALUE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_TESLA_VALUE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        getTeslaValue: action.payload,
      };
      break;
    case GET_TESLA_VALUE_FAIL:
      state = {
        ...state,
        getTeslaValue: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_VISION_BOARD:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_VISION_BOARD_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        getVisionBoard: action.payload,
      };
      break;
    case GET_VISION_BOARD_FAIL:
      state = {
        ...state,
        getVisionBoard: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetCanvasReducer;
