import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_RIDRER_AND_DRIVER,
  GET_DAY90_PLAN,
  GET_TECH_STACK,
  GET_TESLA_VALUE,
  GET_VISION_BOARD,
} from "./actionTypes";
import {
  getRiderAndDriverSuccess,
  getRiderAndDriverFail,
  getDay90PlanSuccess,
  getDay90PlanFail,
  getTechStackSuccess,
  getTechStackFail,
  getTeslaValueSuccess,
  getTeslaValueFail,
  getVisionBoardSuccess,
  getVisionBoardFail,
} from "./actions";
import {
  getRiderAndDriver,
  get90DayPlan,
  getTechStack,
  getTeslaValue,
  getVisionBoard,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onGetRiderAndDriver(data) {
  try {
    const response = yield call(getRiderAndDriver, data);

    if (response) {
      if (response.code === 200) {
        yield put(getRiderAndDriverSuccess(response.data));
      } else {
        yield put(getRiderAndDriverFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getRiderAndDriverFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getRiderAndDriverFail(error.response));
  }
}

function* onGet90DayPlan(data) {
  try {
    const response = yield call(get90DayPlan, data);

    if (response) {
      if (response.code === 200) {
        yield put(getDay90PlanSuccess(response.data));
      } else {
        yield put(getDay90PlanFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getDay90PlanFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getDay90PlanFail(error.response));
  }
}

function* onGetTechStack(data) {
  try {
    const response = yield call(getTechStack, data);

    if (response) {
      if (response.code === 200) {
        yield put(getTechStackSuccess(response.data));
      } else {
        yield put(getTechStackFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getTechStackFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTechStackFail(error.response));
  }
}

function* onGetTeslaValue(data) {
  try {
    const response = yield call(getTeslaValue, data);

    if (response) {
      if (response.code === 200) {
        yield put(getTeslaValueSuccess(response.data));
      } else {
        yield put(getTeslaValueFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getTeslaValueFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getTeslaValueFail(error.response));
  }
}

function* onGetVisionBoard(data) {
  try {
    const response = yield call(getVisionBoard, data);

    if (response) {
      if (response.code === 200) {
        yield put(getVisionBoardSuccess(response.data));
      } else {
        yield put(getVisionBoardFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getVisionBoardFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getVisionBoardFail(error.response));
  }
}

function* GetCanvasSaga() {
  yield takeLatest(GET_RIDRER_AND_DRIVER, onGetRiderAndDriver);
  yield takeLatest(GET_DAY90_PLAN, onGet90DayPlan);
  yield takeLatest(GET_TECH_STACK, onGetTechStack);
  yield takeLatest(GET_TESLA_VALUE, onGetTeslaValue);
  yield takeLatest(GET_VISION_BOARD, onGetVisionBoard);
}

export default GetCanvasSaga;
