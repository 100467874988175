export const GET_LOGIN = "GET_LOGIN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAIL = "GET_LOGIN_FAIL";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_FAIL = "GET_COUNTRY_LIST_FAIL";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const GET_ACTIVE_USER_LIST = "GET_ACTIVE_USER_LIST";
export const GET_ACTIVE_USER_LIST_SUCCESS = "GET_ACTIVE_USER_LIST_SUCCESS";
export const GET_ACTIVE_USER_LIST_FAIL = "GET_ACTIVE_USER_LIST_FAIL";

export const GET_PENDING_USER_LIST = "GET_PENDING_USER_LIST";
export const GET_PENDING_USER_LIST_SUCCESS = "GET_PENDING_USER_LIST_SUCCESS";
export const GET_PENDING_USER_LIST_FAIL = "GET_PENDING_USER_LIST_FAIL";

export const GET_INACTIVE_USER_LIST = "GET_INACTIVE_USER_LIST";
export const GET_INACTIVE_USER_LIST_SUCCESS = "GET_INACTIVE_USER_LIST_SUCCESS";
export const GET_INACTIVE_USER_LIST_FAIL = "GET_INACTIVE_USER_LIST_FAIL";

export const GET_USER_DATA = "GET_USER_DATA";
