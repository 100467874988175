import {
  UPDATE_RIDRER_AND_DRIVER,
  UPDATE_RIDRER_AND_DRIVER_SUCCESS,
  UPDATE_RIDRER_AND_DRIVER_FAIL,
  UPDATE_90DAY_PLAN,
  UPDATE_90DAY_PLAN_SUCCESS,
  UPDATE_90DAY_PLAN_FAIL,
  UPDATE_TECH_STACK,
  UPDATE_TECH_STACK_SUCCESS,
  UPDATE_TECH_STACK_FAIL,
  UPDATE_TESLA_VALUE,
  UPDATE_TESLA_VALUE_SUCCESS,
  UPDATE_TESLA_VALUE_FAIL,
  UPDATE_VISION_BOARD,
  UPDATE_VISION_BOARD_SUCCESS,
  UPDATE_VISION_BOARD_FAIL,
} from "./actionTypes";

const initialState = {
  updateRiderAndDriver: null,
  update90DayPlan: null,
  updateTechStack: null,
  updateTeslaValue: null,
  updateVisionBoard: null,
  loadingLogins: false,
  error: {
    message: "",
  },
};

const UpdateCanvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RIDRER_AND_DRIVER:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_RIDRER_AND_DRIVER_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        updateRiderAndDriver: action.payload,
      };
      break;
    case UPDATE_RIDRER_AND_DRIVER_FAIL:
      state = {
        ...state,
        updateRiderAndDriver: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case UPDATE_90DAY_PLAN:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_90DAY_PLAN_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        update90DayPlan: action.payload,
      };
      break;
    case UPDATE_90DAY_PLAN_FAIL:
      state = {
        ...state,
        update90DayPlan: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case UPDATE_TECH_STACK:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_TECH_STACK_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        updateTechStack: action.payload,
      };
      break;
    case UPDATE_TECH_STACK_FAIL:
      state = {
        ...state,
        updateTechStack: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case UPDATE_TESLA_VALUE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_TESLA_VALUE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        updateTeslaValue: action.payload,
      };
      break;
    case UPDATE_TESLA_VALUE_FAIL:
      state = {
        ...state,
        updateTeslaValue: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case UPDATE_VISION_BOARD:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_VISION_BOARD_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        updateVisionBoard: action.payload,
      };
      break;
    case UPDATE_VISION_BOARD_FAIL:
      state = {
        ...state,
        updateVisionBoard: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UpdateCanvasReducer;
