import React, { useState, useRef, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import { DAY90_PLAN } from "../../../../store/saveCanvas/actionTypes";
import { UserId } from "../../../../Utils/getUserId";
import { day90PlanSuccess } from "../../../../store/saveCanvas/actions";
import { UPDATE_90DAY_PLAN } from "../../../../store/updateCanvas/actionTypes";
import { update90DayPlanSuccess } from "../../../../store/updateCanvas/actions";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../../common/sharedCanvasModal";
import Loader from "../../../common/loader";
import { useNavigatingAway } from "../../../../Utils/useNavigatingAway";
import DeleteConfirm from "../../../common/deleteConfirm";
import PlanIcon from "../../../../assets/images/plan.png";

const options = [
  { value: "start", label: "Started" },
  { value: "parked", label: "Parked" },
  { value: "need_help", label: "Need Help" },
  { value: "in_prodution", label: "In Production" },
];

function DaysPlane({ dataPlan }) {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [needHelp, setNeedHelp] = useState(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const [activeTab, setActiveTab] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);

  const [status, setStatus] = useState({ value: "start", label: "Started" });
  const [data, SetData] = useState({
    oneToThree: [],
    threeToSix: [],
    sixToNine: [],
  });
  const [canvasId, setCanvasId] = useState("");
  const [headerActive, setHeaderActive] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [subHeaderActive, setSubHeaderActive] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);

  let { day90Plan, saveCanvasLoading } = useSelector(
    (state) => state.SaveCanvasReducer
  );
  let { update90DayPlan } = useSelector((state) => state.UpdateCanvas);
  let { loadingLogins } = useSelector((state) => state.GetCanvas);

  document.title = "90 Days Plane | ATW";

  const handleChange = (e, tab) => {
    setCanShowDialogLeavingPage(true);
    let tabValue = data[tab];
    if (e.key === "Enter") {
      if (e.target.value.trim() === "") {
        showError("Value Can't Be Empty");
        return;
      }

      tabValue.push({
        check: false,
        value: e.target.value,
      });
      inputRef.current.value = "";
    }

    SetData({ ...data, tab: tabValue });
  };

  const printDocument = async () => {
    handleSave("pdf");
  };

  useEffect(() => {
    if (dataPlan) {
      SetData({
        oneToThree: dataPlan.plan_one,
        threeToSix: dataPlan.plan_two,
        sixToNine: dataPlan.plan_three,
      });
      setCanvasId(dataPlan._id);
      setStatus(
        options.find((option) => {
          if (option.value === dataPlan.model_status) return option;
        })
      );
      setHeaderText(dataPlan.title);
      setSubHeaderText(dataPlan.sub_title);
    }
  }, [dataPlan]);

  const handleCheckBox = (value, index, tab) => {
    let tabValue = data[tab];
    tabValue[index].check = value;
    SetData({ ...data, [tab]: tabValue });
  };

  const handleDelete = (index, tab) => {
    let tabValue = data[tab];

    tabValue.splice(index, 1);

    SetData({ ...data, tab: tabValue });
  };

  const handleSave = (checkPdf) => {
    setCanShowDialogLeavingPage(false);
    if (headerText === "") {
      showError("Enter Canvas Title");
      return;
    }
    if (subHeaderText === "") {
      showError("Enter Canvas Description");
      return;
    }
    if (checkValidation()) {
      showError("Canvas can't be Empty");
    } else {
      if (checkPdf === "pdf") setGeneratePdf(true);

      let payload = {
        user_id: UserId(),
        modal_id: id,
        title: headerText,
        sub_title: subHeaderText,
        plan_one: data.oneToThree,
        plan_two: data.threeToSix,
        plan_three: data.sixToNine,
        model_status: status.value,
        completed: canvasCompleted(),
      };

      if (canvasId === "") {
        dispatch({
          type: DAY90_PLAN,
          payload,
        });
      } else {
        payload.modal_id = dataPlan.modal_id.id;
        payload["id"] = canvasId;
        delete payload.user_id;
        dispatch({
          type: UPDATE_90DAY_PLAN,
          payload,
        });
      }
    }
  };

  useEffect(() => {
    if (day90Plan) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/day_pan/pdf/${day90Plan._id}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/day_pan/pdf/${day90Plan._id}`,
            "_blank"
          );
        }

        setGeneratePdf(false);
      } else {
        if (canvasId === "") {
          setCanvasId(day90Plan._id);
          showSuccess("Canvas Saved Successfully");
          navigate(`/edit/canvas/90-days-plane/${day90Plan._id}`);
        }
      }
      dispatch(day90PlanSuccess(null));
    }
  }, [day90Plan]);

  useEffect(() => {
    if (update90DayPlan) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/day_pan/pdf/${canvasId}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/day_pan/pdf/${canvasId}`,
            "_blank"
          );
        }
        setGeneratePdf(false);
      } else {
        showSuccess("Canvas Updated Successfully");
      }
      dispatch(update90DayPlanSuccess(null));
    }
  }, [update90DayPlan]);

  const checkValidation = () => {
    if (
      data.oneToThree.length === 0 &&
      data.threeToSix.length === 0 &&
      data.sixToNine.length === 0
    )
      return true;
  };

  const canvasCompleted = () => {
    let partialValue = 0;
    for (const property in data) {
      if (data[property].length !== 0) partialValue += 1;
    }
    return Math.round((100 * partialValue) / Object.keys(data).length);
  };

  const handleStatusChange = (value) => {
    setCanShowDialogLeavingPage(true);

    setStatus(value);
  };

  const handleHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        headerText === "" && showError("Heading Can't Be Empty");
        setHeaderActive(false);
        return;
      }
      setHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setHeaderText(e.target.value);
  };

  const handleSubHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        subHeaderText === "" && showError("Description Can't Be Empty");
        setSubHeaderActive(false);
        return;
      }
      setSubHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setSubHeaderText(e.target.value);
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block blue-bg">
          <div className="title-inner">
            <div className="left-block">
              <i className="icon">
                <img src={PlanIcon} alt="Plan" />
              </i>
              <h2 className="title">90 Days Plan</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>

              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>90 Days Plan Model Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>Use the editable text fields to create your Model.</p>
                    <p className="mt-3">
                      Use your mouse cursor to click on the various grey boxes
                      to edit the Model Title, Model Description and each of the
                      key information sections in the model itself.
                    </p>

                    <p className="mt-3">
                      Once finished, click{" "}
                      <span className="gray-btn">Save</span>, and then click
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      to open the drop down menu. You can then choose whether to
                      Share or download. Clicking download will open the filled
                      model in a PDF for you to save to your computer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-inner" onClick={() => setHeaderActive(true)}>
            {headerActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleHeaderText(e)}
                  placeholder="Enter Canvas Heading"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : headerText === "" ? (
              <h1 className="title">Enter Canvas Heading</h1>
            ) : (
              <h1 className="title">{headerText}</h1>
            )}
          </div> */}
          {/* <div className="title-inner" onClick={() => setSubHeaderActive(true)}>
            {subHeaderActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleSubHeaderText(e)}
                  placeholder="Enter Canvas Description"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : subHeaderText === "" ? (
              <span className="sub-title">Enter Canvas Description</span>
            ) : (
              <span className="sub-title">{subHeaderText}</span>
            )}
          </div> */}
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Title"
                  autoFocus
                  className="form-control heading"
                  onChange={(e) => {
                    setHeaderText(e.target.value);

                    setCanShowDialogLeavingPage(true);
                  }}
                  value={headerText}
                />
              </div>
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Description"
                  className="form-control description"
                  onChange={(e) => {
                    setSubHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={subHeaderText}
                />
              </div>
            </div>
          </div>
          <div className="right-wrap d-flex">
            <div className="right-inner">
              <div className="mb-2">
                <button
                  type="button"
                  className="custom-dropdown-wrapper btn gray-bg"
                >
                  <span>Status:</span>
                  <Select
                    className="custom-select"
                    options={options}
                    value={status}
                    isSearchable={false}
                    onChange={(e) => handleStatusChange(e)}
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "transform 0.3s",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }),
                    }}
                  />
                </button>
              </div>
              <div className="d-flex mb-2">
                <button
                  type="button"
                  className="btn gray-bg mr-3"
                  onClick={() => handleSave()}
                  disabled={saveCanvasLoading}
                >
                  {canvasId === "" ? "Save" : "Update"}
                </button>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle className="gray-bg btn">
                    Other Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                <button
                // onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                >
                  Video
                </button>
              </Dropdown.Item> */}
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => printDocument()}
                      >
                        Download
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => {
                          canvasId === ""
                            ? showError("Please Save The Canvas")
                            : setShowShareModal(true);
                        }}
                      >
                        Share
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-inner">
          <div className="data-block-outer">
            {/* <div className="info-wrapper">
              <img src={info} alt="Info" />
              <span className="tooltiptext">
                The 90 day plan canvas provides the framework for adding action
                items in blocks of 30 days with an option to tick off as they
                are actioned.
              </span>
            </div> */}
            <div className="data-block">
              <div className="grid-block days-plan two-col-grid">
                <div className="row title-row">
                  <div className="left-col">
                    <div className="content-block">
                      <h3 className="title">DAYS</h3>
                    </div>
                  </div>
                  <div className="right-col">
                    <div className="content-block">
                      <h3 className="title">GOALS</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="left-col">
                    <div className="content-block">
                      <h4 className="days">1-30 Days</h4>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("oneToThree")}
                    >
                      <div className="content">
                        <ol>
                          {activeTab !== "oneToThree" &&
                            data.oneToThree.length === 0 && (
                              // <li>Click to add</li>

                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  placeholder="Click to add"
                                />
                              </div>
                            )}
                          {data.oneToThree.length !== 0 &&
                            data.oneToThree.map((value, index) => (
                              <div className="form-check" key={index}>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={value.check}
                                    onChange={() =>
                                      handleCheckBox(
                                        !value.check,
                                        index,
                                        "oneToThree"
                                      )
                                    }
                                  />
                                  <div className="list-wrapper">
                                    <div
                                      className={
                                        value.check ? "text-strick" : ""
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: value.value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "oneToThree")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "oneToThree" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "oneToThree")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="left-col">
                    <div className="content-block">
                      <h4 className="days">30-60 Days</h4>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("threeToSix")}
                    >
                      <div className="content">
                        <ol>
                          {activeTab !== "threeToSix" &&
                            data.threeToSix.length === 0 && (
                              // <li>Click to add</li>

                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  placeholder="Click to add"
                                />
                              </div>
                            )}
                          {data.threeToSix.length !== 0 &&
                            data.threeToSix.map((value, index) => (
                              <div className="form-check" key={index}>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={value.check}
                                    onChange={() =>
                                      handleCheckBox(
                                        !value.check,
                                        index,
                                        "threeToSix"
                                      )
                                    }
                                  />
                                  <div className="list-wrapper">
                                    <div
                                      className={
                                        value.check ? "text-strick" : ""
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: value.value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "threeToSix")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "threeToSix" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "threeToSix")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="left-col">
                    <div className="content-block">
                      <h4 className="days">60-90 Days</h4>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("sixToNine")}
                    >
                      <div className="content">
                        <ol>
                          {activeTab !== "sixToNine" &&
                            data.sixToNine.length === 0 && (
                              // <li>Click to add</li>

                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  placeholder="Click to add"
                                />
                              </div>
                            )}
                          {data.sixToNine.length !== 0 &&
                            data.sixToNine.map((value, index) => (
                              <div className="form-check" key={index}>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={value.check}
                                    onChange={() =>
                                      handleCheckBox(
                                        !value.check,
                                        index,
                                        "sixToNine"
                                      )
                                    }
                                  />
                                  <div className="list-wrapper">
                                    <div
                                      className={
                                        value.check ? "text-strick" : ""
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: value.value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "sixToNine")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "sixToNine" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "sixToNine")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={canvasId}
            adminId={dataPlan && dataPlan.user_id._id}
          />
        )}
        {showDialogLeavingPage && (
          <DeleteConfirm
            handleDeleteClose={cancelNavigation}
            deleteRecord={confirmNavigation}
            msg={
              "There are some changes. If you proceed your changes will be lost"
            }
            icon={"error"}
          />
        )}
      </div>
    </>
  );
}

export default DaysPlane;
