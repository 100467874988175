import Loadable from "react-loadable";

import { Loading } from "../../views/common/navigation";

const Login = Loadable({
  loader: () => import("./components/Login"),
  loading: Loading,
});

export const routes = [
  {
    path: "/admin",
    exact: true,
    name: "Login",
    element: Login,
  },
];

export * from "./components";
