import { takeLatest, put, call } from "redux-saga/effects";
import {
  RIDRER_AND_DRIVER,
  DAY90_PLAN,
  TECH_STACK,
  TESLA_VALUE,
  VISION_BOARD,
} from "./actionTypes";
import {
  riderAndDriverSuccess,
  riderAndDriverFail,
  day90PlanSuccess,
  day90PlanFail,
  techStackSuccess,
  techStackFail,
  teslaValueSuccess,
  teslaValueFail,
  VisionBoardSuccess,
  VisionBoardFail,
} from "./actions";
import {
  saveRiderAndDriver,
  save90DayPlan,
  saveTechStack,
  saveTeslaValue,
  saveVisionBoard,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onSaveRiderAndDriver(data) {
  try {
    const response = yield call(saveRiderAndDriver, data);

    if (response) {
      if (response.code === 200) {
        yield put(riderAndDriverSuccess(response.data));
      } else {
        yield put(riderAndDriverFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(riderAndDriverFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(riderAndDriverFail(error.response));
  }
}

function* onSave90DayPlan(data) {
  try {
    const response = yield call(save90DayPlan, data);

    if (response) {
      if (response.code === 200) {
        yield put(day90PlanSuccess(response.data));
      } else {
        yield put(day90PlanFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(day90PlanFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(day90PlanFail(error.response));
  }
}

function* onSaveTechStack(data) {
  try {
    const response = yield call(saveTechStack, data);

    if (response) {
      if (response.code === 200) {
        yield put(techStackSuccess(response.data));
      } else {
        yield put(techStackFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(techStackFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(techStackFail(error.response));
  }
}

function* onSaveTeslaValue(data) {
  try {
    const response = yield call(saveTeslaValue, data);

    if (response) {
      if (response.code === 200) {
        yield put(teslaValueSuccess(response.data));
      } else {
        yield put(teslaValueFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(teslaValueFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(teslaValueFail(error.response));
  }
}

function* onSaveVisionBoard(data) {
  try {
    const response = yield call(saveVisionBoard, data);

    if (response) {
      if (response.code === 200) {
        yield put(VisionBoardSuccess(response.data));
      } else {
        yield put(VisionBoardFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(VisionBoardFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(VisionBoardFail(error.response));
  }
}

function* SaveCanvasSaga() {
  yield takeLatest(RIDRER_AND_DRIVER, onSaveRiderAndDriver);
  yield takeLatest(DAY90_PLAN, onSave90DayPlan);
  yield takeLatest(TECH_STACK, onSaveTechStack);
  yield takeLatest(TESLA_VALUE, onSaveTeslaValue);
  yield takeLatest(VISION_BOARD, onSaveVisionBoard);
}

export default SaveCanvasSaga;
