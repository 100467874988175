import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_CANVAS_BY_STATUS } from "../../../store/canvas/actionTypes";
import moment from "moment";
import {
  deleteRiderAndDriver,
  delete90DayPlan,
  deleteTechStack,
  deleteTeslaValue,
  deleteVisionBoard,
} from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import { getCanvasByStatusSuccess } from "../../../store/canvas/actions";
import ReactPaginate from "react-paginate";
import Loader from "../../common/loader";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../common/sharedCanvasModal";

const ModalStarted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);

  const [totalPage, setTotalPage] = useState(0);
  const [canvasData, setCanvasData] = useState([]);
  const [shareData, setShareData] = useState({
    canvasId: "",
    adminId: "",
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { canvasByStatus, canvasLoading } = useSelector(
    (state) => state.canvas
  );

  useEffect(() => {
    document.title = "Model Need Help | ATW";

    loadData();
    return () => dispatch(getCanvasByStatusSuccess(null));
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_CANVAS_BY_STATUS,
      payload: "need_help",
    });
  };

  const canvasList = useMemo(() => {
    let list = [];
    if (canvasByStatus) {
      for (const property in canvasByStatus) {
        if (canvasByStatus[property].length !== 0) {
          canvasByStatus[property].map((data) => list.push(data));
        }
      }
      setTotalPage(Math.ceil(list.length / recordPerPage));

      return list;
    }
  }, [canvasByStatus]);

  useEffect(() => {
    if (canvasList) {
      setCanvasData(getFilteredData(0));
    }
  }, [canvasList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (canvasList[i]) list.push(canvasList[i]);
    }
    return list;
  };

  const deleteCanvas = async (path, id) => {
    let deletePath = path.split("/")[2];
    let canvasId = { payload: id };

    switch (deletePath) {
      case "atw-startup":
        setIsLoading(true);
        await deleteRiderAndDriver(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            showError(res.message);
          }
        });
        break;
      case "90-days-plane":
        setIsLoading(true);
        await delete90DayPlan(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "tech-stack":
        setIsLoading(true);
        await deleteTechStack(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "value-proposition":
        setIsLoading(true);
        await deleteTeslaValue(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "vision-board":
        setIsLoading(true);
        await deleteVisionBoard(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
    }
  };

  const handlePageClick = (e) => {
    setCanvasData(getFilteredData(e.selected * recordPerPage));
  };

  return (
    <>
      {(canvasLoading || isLoading) && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block">
          <h1 className="title">Models Need help</h1>
        </div>
        <div className="right-content-inner">
          {canvasData && canvasData.length !== 0 ? (
            canvasData.map(
              (
                {
                  title,
                  sub_title,
                  createdAt,
                  updatedAt,
                  user_id,
                  image_name,
                  modal_id,
                  completed,
                  redirect_path,
                  _id,
                },
                index
              ) => (
                <div className="modal-detail-block models-started" key={index}>
                  <div className="inner-wrap">
                    <div className="row">
                      <div className="col left-col">
                        <div className="img-block">
                          <img
                            crossOrigin="anonymous"
                            src={REACT_APP_APP_URL + image_name}
                          ></img>
                        </div>
                        <span className="content">
                          {modal_id && modal_id.name}
                        </span>
                      </div>
                      <div className="col content-col">
                        <h4 className="title">{title}</h4>
                        <span className="sub-title">{sub_title}</span>
                        <p>
                          <strong>Started : </strong>
                          <span>
                            {moment(createdAt).format("Do MMMM YYYY")} by{" "}
                            {user_id.name}
                          </span>
                        </p>
                        <p>
                          <strong>Edited : </strong>
                          <span>
                            {" "}
                            {moment(updatedAt).format("Do MMMM YYYY")} by{" "}
                            {user_id.name}
                          </span>
                        </p>
                        <p>
                          <strong>Completed : </strong>
                          <span>{completed}%</span>
                        </p>
                      </div>
                      <div className="col dropdown-col">
                        <Dropdown className="custom-dropdown">
                          <Dropdown.Toggle className="custom-dropdown-button"></Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <button
                                onClick={() =>
                                  navigate(`/edit${redirect_path}/${_id}`)
                                }
                              >
                                Edit
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <button
                                onClick={() => deleteCanvas(redirect_path, _id)}
                              >
                                Delete
                              </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <button
                                onClick={() => {
                                  setShowShareModal(true);
                                  setShareData({
                                    canvasId: _id,
                                    adminId: user_id._id,
                                  });
                                }}
                              >
                                Share
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <p className="no-record-found">No Records found</p>
          )}
        </div>

        {totalPage > 1 && (
          <div className="my-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={totalPage}
              previousLabel="Prev "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={shareData.canvasId}
            adminId={shareData.adminId}
          />
        )}
      </div>
    </>
  );
};

export default ModalStarted;
