export const GET_RIDRER_AND_DRIVER = "GET_RIDRER_AND_DRIVER";
export const GET_RIDRER_AND_DRIVER_SUCCESS = "GET_RIDRER_AND_DRIVER_SUCCESS";
export const GET_RIDRER_AND_DRIVER_FAIL = "GET_RIDRER_AND_DRIVER_FAIL";

export const GET_DAY90_PLAN = "GET_DAY90_PLAN";
export const GET_DAY90_PLAN_SUCCESS = "GET_DAY90_PLAN_SUCCESS";
export const GET_DAY90_PLAN_FAIL = "GET_DAY90_PLAN_FAIL";

export const GET_TECH_STACK = "GET_TECH_STACK";
export const GET_TECH_STACK_SUCCESS = "GET_TECH_STACK_SUCCESS";
export const GET_TECH_STACK_FAIL = "GET_TECH_STACK_FAIL";

export const GET_TESLA_VALUE = "GET_TESLA_VALUE";
export const GET_TESLA_VALUE_SUCCESS = "GET_TESLA_VALUE_SUCCESS";
export const GET_TESLA_VALUE_FAIL = "GET_TESLA_VALUE_FAIL";

export const GET_VISION_BOARD = "GET_VISION_BOARD";
export const GET_VISION_BOARD_SUCCESS = "GET_VISION_BOARD_SUCCESS";
export const GET_VISION_BOARD_FAIL = "GET_VISION_BOARD_FAIL";
