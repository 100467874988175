import React, { useState, useEffect, useMemo } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditUser from "./EditUser";
import EditUserPassword from "./EditUserPassword";

const Settings = () => {
  return (
    <>
      <div className="right-content-wrap admin-right-wrap settings-table">
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="custom-tabs"
        >
          <Tab eventKey="profile" title="Update Profile">
            <EditUser />
          </Tab>
          <Tab eventKey="Password" title="Update Password">
            <EditUserPassword />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default Settings;
