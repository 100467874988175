import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const Settings = Loadable({
  loader: () => import("./components/Settings"),
  loading: Loading,
});

const EditUser = Loadable({
  loader: () => import("./components/EditUser"),
  loading: Loading,
});

const EditUserPassword = Loadable({
  loader: () => import("./components/EditUserPassword"),
  loading: Loading,
});

export const routes = [
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    element: Settings,
  },
  {
    path: "/update-profile",
    exact: true,
    name: "EditUser",
    element: EditUser,
  },
  {
    path: "/update-password",
    exact: true,
    name: "EditUserPassword",
    element: EditUserPassword,
  },
];

export * from "./components";
