import React, { useState, useEffect } from "react";
import { getModelsCount, getUserCount } from "../../../helpers/backendHelper";
import { showError } from "../../../Utils/alertMessage";
import atwicon from "../../../assets/images/frame-black.png";
import techstackicon from "../../../assets/images/stack.png";
import valueicon from "../../../assets/images/proposition.png";
import daysicon from "../../../assets/images/plan.png";
import usericon from "../../../assets/images/user.png";
import visionicon from "../../../assets/images/vision.png";
import { techStack } from "../../../store/saveCanvas/actions";

const Home = () => {
  const [modalCount, setModalCount] = useState({});
  const [userCount, setUserCount] = useState("");

  const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    await getModelsCount().then((res) => {
      if (res.code === 200) setModalCount(res.data[0]);
      else showError(res.message);
    });

    await getUserCount().then((res) => {
      if (res.code === 200) setUserCount(res.data);
      else showError(res.message);
    });
  };

  return (
    <>
      <div className="right-content-wrap admin-right-wrap">
        <div className="title-block">
          <h1 className="title">Dashboard</h1>
        </div>
        <div className="four-col-block-admin row">
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={atwicon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">ATW Start Up Model</span>
                <span className="number">{modalCount.riders}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={techstackicon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">Technology Stack</span>
                <span className="number">{modalCount.tack_stack}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={valueicon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">Value Proposition</span>
                <span className="number">{modalCount.tesla_alue}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={daysicon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">90 day Plan</span>
                <span className="number">{modalCount.dayplan}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={visionicon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">Vision Board</span>
                <span className="number">{modalCount.vision}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="inner-col">
              <div className="img-block">
                <img src={usericon} alt="Image" />
              </div>
              <div className="content">
                <span className="title">User</span>
                <span className="number">{userCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
