export const RIDRER_AND_DRIVER = "RIDRER_AND_DRIVER";
export const RIDRER_AND_DRIVER_SUCCESS = "RIDRER_AND_DRIVER_SUCCESS";
export const RIDRER_AND_DRIVER_FAIL = "RIDRER_AND_DRIVER_FAIL";

export const DAY90_PLAN = "DAY90_PLAN";
export const DAY90_PLAN_SUCCESS = "DAY90_PLAN_SUCCESS";
export const DAY90_PLAN_FAIL = "DAY90_PLAN_FAIL";

export const TECH_STACK = "TECH_STACK";
export const TECH_STACK_SUCCESS = "TECH_STACK_SUCCESS";
export const TECH_STACK_FAIL = "TECH_STACK_FAIL";

export const TESLA_VALUE = "TESLA_VALUE";
export const TESLA_VALUE_SUCCESS = "TESLA_VALUE_SUCCESS";
export const TESLA_VALUE_FAIL = "TESLA_VALUE_FAIL";

export const VISION_BOARD = "VISION_BOARD";
export const VISION_BOARD_SUCCESS = "VISION_BOARD_SUCCESS";
export const VISION_BOARD_FAIL = "VISION_BOARD_FAIL";
