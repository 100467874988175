import { all } from "redux-saga/effects";

import LoginSaga from "./login/saga";
import SaveCanvasSaga from "./saveCanvas/saga";
import UpdateCanvas from "./updateCanvas/saga";
import Canvas from "./canvas/saga";
import GetCanvas from "./getCanvas/saga";
import Subscription from "./subscription/saga";

export default function* rootSaga() {
  yield all([
    LoginSaga(),
    SaveCanvasSaga(),
    UpdateCanvas(),
    Canvas(),
    GetCanvas(),
    Subscription(),
  ]);
}
