import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { showError } from "../../../../Utils/alertMessage";

const MilestoneModal = ({
  showMilestoneModal,
  setShowMilestoneModal,
  data,
  SetData,
  setCanShowDialogLeavingPage,
}) => {
  const inputRef = useRef("");

  const [tempData, setTempData] = useState([]);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    if (showMilestoneModal.index !== "new" && showMilestoneModal.index !== "") {
      const index = showMilestoneModal.index;
      setTempData(data[index].what);
    }
  }, [showMilestoneModal]);

  const handleAddButton = () => {
    if (textValue.trim() === "") {
      showError("Value Can't Be Empty");
      return;
    }
    let data = [...tempData];
    data.push({ complete: false, value: textValue });
    setTempData(data);
    inputRef.current.value = "";
    setTextValue("");
  };

  const handleSubmit = () => {
    if (tempData.length === 0) {
      showError("Milestones can't be empty");
      return;
    }
    setCanShowDialogLeavingPage(true);

    let tabValue = [...data];
    if (showMilestoneModal.index === "new") {
      tabValue.push({
        priority: tabValue.length + 1,
        achievements: "",
        why: "",
        what: tempData,
        status: "",
        dueDate: "",
        how: "",
      });
    } else {
      tabValue[showMilestoneModal.index]["what"] = tempData;
    }
    SetData(tabValue);
    setShowMilestoneModal({
      index: "",
      show: false,
    });
    setTempData([]);
  };

  const handleCheckbox = (index) => {
    let data = [...tempData];
    const value = data[index]["complete"];
    data[index]["complete"] = !value;
    setTempData(data);
  };

  const handleDelete = (index) => {
    let data = [...tempData];
    data.splice(index, 1);
    setTempData(data);
  };

  return (
    <Modal
      show={showMilestoneModal.show}
      onHide={() =>
        setShowMilestoneModal({
          index: "",
          show: false,
        })
      }
      className="share-modal milestone-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Milestone Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <textarea
            rows={2}
            type="text"
            ref={inputRef}
            autoFocus
            onChange={(e) => setTextValue(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="d-flex justify-content-center btn-wrapper">
          <Button onClick={() => handleAddButton()}>Add</Button>
        </div>
        <div className="checklist-wrapper">
          {tempData.length !== 0 &&
            tempData.map(({ complete, value }, index) => (
              <div key={index} className="list-wrap">
                <input
                  type="checkBox"
                  checked={complete}
                  onChange={() => handleCheckbox(index)}
                />
                <span>{value}</span>
                <button
                  className="close-btn"
                  onClick={() => handleDelete(index)}
                >
                  <span>X</span>
                </button>
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="btn black-bg"
          onClick={() => {
            setShowMilestoneModal({
              index: "",
              show: false,
            });
            setTempData([]);
          }}
        >
          Close
        </Button>
        <Button className="btn black-bg" onClick={() => handleSubmit()}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MilestoneModal;
