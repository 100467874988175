import React, { useState, useEffect, useMemo } from "react";
import frameImg from "../../../assets/images/frame-black.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_CANVAS_BY_STATUS } from "../../../store/canvas/actionTypes";
import moment from "moment";
import {
  deleteRiderAndDriver,
  delete90DayPlan,
  deleteTechStack,
  deleteTeslaValue,
  deleteVisionBoard,
} from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import { getCanvasByStatusSuccess } from "../../../store/canvas/actions";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import SharedCanvas from "../../common/sharedCanvasModal";
import Loader from "../../common/loader";
import StackIcon from "../../../assets/images/stack.png";

const ModalStarted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);
  const url = window.location.pathname;

  const [totalPage, setTotalPage] = useState(0);
  const [canvasData, setCanvasData] = useState([]);
  const [needHelp, setNeedHelp] = useState(false);

  const [filteredCanvasData, setFilteredCanvasData] = useState([]);

  const [shareData, setShareData] = useState({
    canvasId: "",
    adminId: "",
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStatus, setActiveStatus] = useState("all");

  const { canvasByStatus, canvasLoading } = useSelector(
    (state) => state.canvas
  );

  const { userData } = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    document.title = "Model Started | ATW";

    loadData();
    return () => dispatch(getCanvasByStatusSuccess(null));
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_CANVAS_BY_STATUS,
      payload: "start",
    });
  };

  const canvasList = useMemo(() => {
    let list = [];
    if (canvasByStatus) {
      for (const property in canvasByStatus) {
        if (canvasByStatus[property].length !== 0) {
          canvasByStatus[property].map((data) => list.push(data));
        }
      }

      // setTotalPage(Math.ceil(list.length / recordPerPage));
      const sortedList = list.sort(function (a, b) {
        return new Date(a.updatedAt) - new Date(b.updatedAt);
      });
      return sortedList.reverse();
    }
  }, [canvasByStatus]);

  useEffect(() => {
    if (canvasList) {
      // setCanvasData(getFilteredData(0));
      setFilteredCanvasData(canvasList);
    }
  }, [canvasList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (canvasList[i]) list.push(canvasList[i]);
    }
    return list;
  };

  const deleteCanvas = async (path, id) => {
    let deletePath = path.split("/")[2];
    let canvasId = { payload: id };
    switch (deletePath) {
      case "atw-startup":
        setIsLoading(true);
        await deleteRiderAndDriver(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            showError(res.message);
          }
        });
        break;
      case "90-days-plane":
        setIsLoading(true);
        await delete90DayPlan(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "tech-stack":
        setIsLoading(true);
        await deleteTechStack(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "value-proposition":
        setIsLoading(true);
        await deleteTeslaValue(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
      case "vision-board":
        setIsLoading(true);
        await deleteVisionBoard(canvasId).then((res) => {
          if (res.code === 200) {
            showSuccess("Canvas Deleted Successfully");
            loadData();
            setIsLoading(false);
          } else {
            showError(res.message);
            setIsLoading(false);
          }
        });
        break;
    }
  };

  // const handlePageClick = (e) => {
  //   // setCanvasData(getFilteredData(e.selected * recordPerPage));
  //   setFilteredCanvasData(getFilteredData(e.selected * recordPerPage));
  // };

  const getColour = (status) => {
    if (status == "start") return "btn yellow-bg";
    if (status == "parked") return "btn orange-bg";
    if (status == "need_help") return "btn red-bg";
    if (status == "in_prodution") return "btn green-bg";
  };

  const getStatus = (status) => {
    if (status == "start") return "Started";
    if (status == "parked") return "Parked";
    if (status == "need_help") return "Need Help";
    if (status == "in_prodution") return "In Production";
  };

  const getStatusWiseData = (status) => {
    setActiveStatus(status);
    let filCanvasData = [];
    if (canvasList.length !== 0 && status !== "all") {
      filCanvasData = canvasList.filter((data) => {
        if (data.model_status == status) return data;
      });

      setFilteredCanvasData(filCanvasData);
      // setTotalPage(Math.ceil(filCanvasData.length / recordPerPage));
    } else {
      setFilteredCanvasData(canvasList);

      // setTotalPage(Math.ceil(canvasList.length / recordPerPage));
    }
  };

  return (
    <>
      {(canvasLoading || isLoading) && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block no-bg">
          <div className="title-inner">
            <div className="left-block">
              <h2 className="title">My Models</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>
              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>My Models Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>
                      Here you can see all the Models you have either created or
                      edited, when you edited them, as well as their status. Use
                      your mouse cursor to click on a Model to open it for
                      editing.
                    </p>
                    <p className="mt-3">
                      If you need to find a Model quickly, you can search for a
                      specific Model by clicking the{" "}
                      <span className="gray-btn">
                        Search by Model Status <i className="icon"></i>
                      </span>{" "}
                      to find the Models by their status.
                    </p>

                    <p className="mt-3">
                      Clicking{" "}
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      will display the options to Edit, Delete and Share the
                      Model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-wrap">
          <div className="left-wrap d-flex">
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle className="custom-dropdown-button btn gray-bg">
                Search by Model {getStatus(activeStatus) || "Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <button
                    className={`btn gray-bg ${
                      activeStatus === "all" ? `active` : ``
                    }`}
                    onClick={() => {
                      getStatusWiseData("all");
                    }}
                  >
                    ALL
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className={`btn gray-bg ${
                      activeStatus === "start" ? `active` : ``
                    }`}
                    onClick={() => {
                      getStatusWiseData("start");
                    }}
                  >
                    Started
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className={`btn gray-bg ${
                      activeStatus === "parked" ? `active` : ``
                    }`}
                    onClick={() => {
                      getStatusWiseData("parked");
                    }}
                  >
                    Parked
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className={`btn gray-bg ${
                      activeStatus === "need_help" ? `active` : ``
                    }`}
                    onClick={() => {
                      getStatusWiseData("need_help");
                    }}
                  >
                    Need Help
                  </button>
                </Dropdown.Item>
                <Dropdown.Item>
                  <button
                    className={`btn gray-bg ${
                      activeStatus === "in_prodution" ? `active` : ``
                    }`}
                    onClick={() => {
                      getStatusWiseData("in_prodution");
                    }}
                  >
                    In Production
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* <div className="right-content-inner">
        {canvasData && canvasData.length !== 0 ? (
          canvasData.map(
            (
              {
                title,
                sub_title,
                createdAt,
                updatedAt,
                user_id,
                image_name,
                modal_id,
                completed,
                redirect_path,
                _id,
              },
              index
            ) => (
              <div className="modal-detail-block models-started" key={index}>
                <div className="inner-wrap">
                  <div className="row">
                    <div className="col left-col">
                      <div className="img-block">
                        <img
                          crossOrigin="anonymous"
                          src={REACT_APP_APP_URL + image_name}
                        ></img>
                      </div>
                      <span className="content">
                        {modal_id && modal_id.name}
                      </span>
                    </div>
                    <div className="col content-col">
                      <h4 className="title">{title}</h4>
                      <span className="sub-title">{sub_title}</span>
                      <p>
                        <strong>Started : </strong>
                        <span>
                          {" "}
                          {moment(createdAt).format("Do MMMM YYYY")} by{" "}
                          {user_id.name}
                        </span>
                      </p>
                      <p>
                        <strong>Edited : </strong>
                        <span>
                          {" "}
                          {moment(updatedAt).format("Do MMMM YYYY")} by{" "}
                          {user_id.name}
                        </span>
                      </p>
                      <p>
                        <strong>Completed : </strong>
                        <span>{completed}%</span>
                      </p>
                    </div>
                    <div className="col dropdown-col">
                      <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle className="custom-dropdown-button"></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button
                              onClick={() =>
                                navigate(`/edit${redirect_path}/${_id}`)
                              }
                            >
                              Edit
                            </button>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <button
                              onClick={() => deleteCanvas(redirect_path, _id)}
                            >
                              Delete
                            </button>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <button
                              onClick={() => {
                                setShowShareModal(true);
                                setShareData({
                                  canvasId: _id,
                                  adminId: user_id._id,
                                });
                              }}
                            >
                              Share
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>               
            )
          )
        ) : url === "/dashboard" ? (
          ""
        ) : (
          <p className="no-record-found">No Records found</p>
        )}
      </div>       */}

        <div className="right-content-inner pt-5">
          {filteredCanvasData && filteredCanvasData.length !== 0 ? (
            filteredCanvasData.map(
              (
                {
                  title,
                  sub_title,
                  createdAt,
                  updatedAt,
                  user_id,
                  image_name,
                  modal_id,
                  completed,
                  redirect_path,
                  _id,
                  model_status,
                },
                index
              ) => (
                <div className="modal-detail-block models-started blue-bg">
                  <div
                    className="link-to"
                    onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                  ></div>
                  <div className="inner-wrap">
                    <div className="row">
                      <div className="col left-col">
                        <div className="img-block">
                          <img
                            crossOrigin="anonymous"
                            src={REACT_APP_APP_URL + image_name}
                          ></img>
                        </div>
                      </div>
                      <div className="col content-col">
                        <h2 className="title">{title}</h2>
                        <span className="sub-title">{sub_title}</span>
                        <p>
                          <strong>Date Started: </strong>
                          <span>
                            {" "}
                            {moment(createdAt).format("Do MMMM YYYY")} by{" "}
                            {user_id.name}
                          </span>
                        </p>
                        <p>
                          <strong>Date of Last Edit: </strong>
                          <span>
                            {" "}
                            {moment(updatedAt).format("Do MMMM YYYY")} by{" "}
                            {user_id.name}
                          </span>
                        </p>
                        <p>
                          <strong>Completed : </strong>
                          <span>{completed}%</span>
                        </p>
                      </div>
                      <div className="col dropdown-col">
                        <div className="btn-dropdown-wrap">
                          <button className={getColour(model_status)}>
                            {getStatus(model_status)}
                          </button>
                          <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle className="custom-dropdown-button btn gray-bg">
                              Other Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <button
                                  className="btn gray-bg"
                                  onClick={() =>
                                    navigate(`/edit${redirect_path}/${_id}`)
                                  }
                                >
                                  Edit
                                </button>
                              </Dropdown.Item>
                              {user_id._id == userData.user_id && (
                                <Dropdown.Item>
                                  <button
                                    className="btn gray-bg"
                                    onClick={() =>
                                      deleteCanvas(redirect_path, _id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item>
                                <button
                                  className="btn gray-bg"
                                  onClick={() => {
                                    setShowShareModal(true);
                                    setShareData({
                                      canvasId: _id,
                                      adminId: user_id._id,
                                    });
                                  }}
                                >
                                  Share
                                </button>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            //  : url === "/dashboard" ? (
            //   ""
            // )
            <p className="no-record-found">No Records found</p>
          )}
        </div>
        {/* {totalPage > 1 && (
          <div className="my-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={totalPage}
              previousLabel="Prev "
              renderOnZeroPageCount={null}
            />
          </div>
        )} */}

        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={shareData.canvasId}
            adminId={shareData.adminId}
            loadData={loadData}
          />
        )}
      </div>
    </>
  );
};

export default ModalStarted;
