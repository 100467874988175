import Redirect from "../Utils/customHook";
import { showError } from "../Utils/alertMessage";
import store from "../store";

const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

export async function get(url, config) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  const token = store.getState().LoginReducer.userData
    ? store.getState().LoginReducer.userData.token
    : "";

  try {
    let response = await fetch(apiurl, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    });
    let res = await response.json();

    if (res.code === 412) {
      await localStorage.clear();
      showError("User is not Authorized");
      Redirect();
    } else {
      return res;
    }
  } catch (error) {
    console.log(error, "error");
  }
}

export async function post(url, config) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  const token = store.getState().LoginReducer.userData
    ? store.getState().LoginReducer.userData.token
    : "";
  try {
    let response = await fetch(apiurl, {
      //mode: 'no-cors',
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(config.payload),
    });
    let res = await response.json();
    if (res.code === 412) {
      localStorage.clear();
      showError("User is not Authorized");
      Redirect();
    } else {
      return res;
    }
  } catch (error) {
    console.log(error, "error");
  }
}

export async function patch(url, config) {
  const apiurl = `${REACT_APP_APP_URL}/` + url;
  const token = store.getState().LoginReducer.userData.token;
  try {
    let response = await fetch(apiurl, {
      //mode: 'no-cors',
      method: "PATCH",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(config.payload),
    });
    let res = await response.json();
    if (res.code === 412) {
      localStorage.clear();
      showError("User is not Authorized");
      Redirect();
    } else {
      return res;
    }
  } catch (error) {
    console.log(error, "error");
  }
}

// export async function apiCall(url, config, method = "GET", headers = false) {
//   const apiurl = `${REACT_APP_APP_URL}/` + url;
//   const options = {
//     method: method,
//   };

//   let token = window.atob(storedData.token);
//   if (headers) {
//     options.headers = {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": "application/json",
//       Authorization: "Basic " + window.btoa(token),
//     };
//   } else {
//     options.headers = {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": "application/json",
//     };
//   }
//   if (config !== null) {
//     options.body = JSON.stringify(config.payload);
//   }
//   // console.log('options',options)
//   let response = await fetch(apiurl, options);

//   return response.json();
// }
