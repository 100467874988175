import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_CANVAS_LIST } from "../../../store/canvas/actionTypes";
import ModalStarted from "../../Models_Started/components/ModalStarted";
import Loader from "../../common/loader";
import atwLogo from "../../../assets/images/atw-logo.png";

const Home = () => {
  const [needHelp, setNeedHelp] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

  const { getCanvasList, canvasLoading } = useSelector((state) => state.canvas);

  useEffect(() => {
    document.title = "Dashboard | ATW";

    dispatch({
      type: GET_ALL_CANVAS_LIST,
    });
  }, []);

  const canvasList = useMemo(() => {
    if (getCanvasList) return getCanvasList;
  }, [getCanvasList]);
  return (
    <>
      {canvasLoading && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block no-bg">
          <div className="title-inner">
            <div className="left-block">
              <h2 className="title">Choosing a Model</h2>
            </div>
            {/*  */}
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>
              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>Choosing a Model Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>
                      Here you will find the 5 main Model templates to help you
                      get started with your business goals. Use your mouse
                      cursor to click on one of the{" "}
                      <span className="white-btn">Get Started</span> buttons,
                      and you will be taken to the corresponding Model.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
        {/* <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <h2>Featured Models</h2>
            </div>
          </div>
          <div className="right-wrap margin-left-auto">
            <button
              type="button"
              className="btn white-border"
              onClick={() => navigate("/create/canvas")}
            >
              CREATE MODEL
            </button>
          </div>
        </div> */}
        <div className="right-content-inner choose-modal-wrapper">
          <div className="two-col-section">
            <div className="row featured-model">
              {canvasList &&
                canvasList.length !== 0 &&
                canvasList.map(
                  (
                    {
                      image,
                      name,
                      redirect_path,
                      _id,
                      isFeatured,
                      description,
                    },
                    index
                  ) =>
                    isFeatured && (
                      <div key={index} className="col">
                        <div className="inner-col">
                          <div className="img-block">
                            <img
                              crossOrigin="anonymous"
                              src={REACT_APP_APP_URL + image}
                            ></img>
                          </div>
                          <span className="content">{name}</span>
                          <p>{description}</p>
                          <div className="d-flex justify-content-center mt-auto">
                            <button
                              type="button"
                              className="btn secondary-bg"
                              onClick={() =>
                                navigate(redirect_path + "/" + _id)
                              }
                            >
                              Get Started
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
          <div className="dashboard-start-modal mt-5">
            <ModalStarted />
          </div>
        </div>
      </div>
      <div className="logo-footer">
        <div className="container">
          <Link to="#" className="logo-wrap">
            <img src={atwLogo} alt="ATW" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
