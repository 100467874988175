import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { showError, showSuccess } from "../../../Utils/alertMessage";

import { UPDATE_USER_PROFILE } from "../../../store/login/actionTypes";
import { updateUserProfileSuccess } from "../../../store/login/actions";
import Loader from "../../common/loader";

const UpdateUserName = ({
  showEditName,
  setShowEditName,
  UserData,
  loadUserData,
}) => {
  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("data"));

  const { updateUser, loadingLogins } = useSelector(
    (state) => state.LoginReducer
  );

  const { _id, email, name, title, description } = UserData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email,
      name,
      description,
      title,
    },
  });

  const onSubmit = (data) => {
    userData.name = data.name;
    userData.title = data.title;
    userData.description = data.description;
    localStorage.setItem("data", JSON.stringify(userData));
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: data,
    });
  };

  useEffect(() => {
    if (updateUser) {
      showSuccess("User updated Successfully");
      dispatch(updateUserProfileSuccess(null));
      loadUserData(_id);
      setShowEditName(false);
    }
  }, [updateUser]);

  return (
    <Modal
      show={showEditName}
      onHide={() => setShowEditName(false)}
      className="share-modal"
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="pre-login-form">
        <Modal.Header closeButton>
          <Modal.Title>Share Canvas Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="top-block">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-dark">
                Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="emailHelp"
                placeholder="Enter Your Name"
                {...register("name", {
                  required: "Name is required",
                })}
              />
              <p className="text-danger">{errors.name?.message}</p>
            </div>

            <div className="form-group  ">
              <label htmlFor="exampleInputEmail1" className="text-dark">
                Email address <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control not-allowed"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                disabled={true}
                placeholder="Enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-dark">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="contact number"
                aria-describedby="emailHelp"
                placeholder="Enter Your Title"
                {...register("title")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="text-dark">
                Description
              </label>
              <textarea
                className="form-control"
                placeholder="Enter Your Description"
                {...register("description")}
                rows="3"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn black-bg"
            onClick={() => setShowEditName(false)}
          >
            Close
          </Button>

          <Button className="btn secondary-bg" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>

      {loadingLogins && <Loader />}
    </Modal>
  );
};

export default UpdateUserName;
