export const GET_ALL_CANVAS_LIST = "GET_ALL_CANVAS_LIST";
export const GET_ALL_CANVAS_LIST_SUCCESS = "GET_ALL_CANVAS_LIST_SUCCESS";
export const GET_ALL_CANVAS_LIST_FAIL = "GET_ALL_CANVAS_LIST_FAIL";

export const GET_CANVAS_BY_STATUS = "GET_CANVAS_BY_STATUS";
export const GET_CANVAS_BY_STATUS_SUCCESS = "GET_CANVAS_BY_STATUS_SUCCESS";
export const GET_CANVAS_BY_STATUS_FAIL = "GET_CANVAS_BY_STATUS_FAIL";

export const GET_ALL_CANVAS_BY_STATUS = "GET_ALL_CANVAS_BY_STATUS";
export const GET_ALL_CANVAS_BY_STATUS_SUCCESS =
  "GET_ALL_CANVAS_BY_STATUS_SUCCESS";
export const GET_ALL_CANVAS_BY_STATUS_FAIL = "GET_ALL_CANVAS_BY_STATUS_FAIL";
