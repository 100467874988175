import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const ModalParked = Loadable({
  loader: () => import("./components/ModalParked"),
  loading: Loading,
});

export const routes = [
  {
    path: "/model-parked",
    exact: true,
    name: "modalParked",
    element: ModalParked,
  },
];

export * from "./components";
