import {
  RIDRER_AND_DRIVER,
  RIDRER_AND_DRIVER_SUCCESS,
  RIDRER_AND_DRIVER_FAIL,
  DAY90_PLAN,
  DAY90_PLAN_SUCCESS,
  DAY90_PLAN_FAIL,
  TECH_STACK,
  TECH_STACK_SUCCESS,
  TECH_STACK_FAIL,
  TESLA_VALUE,
  TESLA_VALUE_SUCCESS,
  TESLA_VALUE_FAIL,
  VISION_BOARD,
  VISION_BOARD_SUCCESS,
  VISION_BOARD_FAIL,
} from "./actionTypes";

export const riderAndDriver = () => {
  return {
    type: RIDRER_AND_DRIVER,
  };
};

export const riderAndDriverSuccess = (data) => {
  return {
    type: RIDRER_AND_DRIVER_SUCCESS,
    payload: data,
  };
};

export const riderAndDriverFail = (error) => {
  return {
    type: RIDRER_AND_DRIVER_FAIL,
    payload: error,
  };
};

export const day90Plan = () => {
  return {
    type: DAY90_PLAN,
  };
};

export const day90PlanSuccess = (data) => {
  return {
    type: DAY90_PLAN_SUCCESS,
    payload: data,
  };
};

export const day90PlanFail = (error) => {
  return {
    type: DAY90_PLAN_FAIL,
    payload: error,
  };
};

export const techStack = () => {
  return {
    type: TECH_STACK,
  };
};

export const techStackSuccess = (data) => {
  return {
    type: TECH_STACK_SUCCESS,
    payload: data,
  };
};

export const techStackFail = (error) => {
  return {
    type: TECH_STACK_FAIL,
    payload: error,
  };
};

export const teslaValue = () => {
  return {
    type: TESLA_VALUE,
  };
};

export const teslaValueSuccess = (data) => {
  return {
    type: TESLA_VALUE_SUCCESS,
    payload: data,
  };
};

export const teslaValueFail = (error) => {
  return {
    type: TESLA_VALUE_FAIL,
    payload: error,
  };
};

export const VisionBoard = () => {
  return {
    type: VISION_BOARD,
  };
};

export const VisionBoardSuccess = (data) => {
  return {
    type: VISION_BOARD_SUCCESS,
    payload: data,
  };
};

export const VisionBoardFail = (error) => {
  return {
    type: VISION_BOARD_FAIL,
    payload: error,
  };
};
