import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ACTIVE_USER_LIST } from "../../../../store/login/actionTypes";
import { getActiveUserListSuccess } from "../../../../store/login/actions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import searchicon from "../../../../assets/images/search.png";

import { updateUserData } from "../../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../../Utils/alertMessage";

const UserList = () => {
  const dispatch = useDispatch();
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);

  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const { activeUserList } = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    loadData();

    return () => dispatch(getActiveUserListSuccess(null));
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_ACTIVE_USER_LIST,
      payload: "pending",
    });
  };

  useEffect(() => {
    if (activeUserList) {
      setUserData(getFilteredData(0));
      setTotalPage(Math.ceil(activeUserList.length / recordPerPage));
    }
  }, [activeUserList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (activeUserList[i]) list.push(activeUserList[i]);
    }
    return list;
  };

  const handlePageClick = (e) => {
    setUserData(getFilteredData(e.selected * recordPerPage));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setUserData(
      activeUserList.filter((canvas) => {
        return (
          canvas.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          canvas.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
          (canvas.contactNumber &&
            canvas.contactNumber
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          canvas.status.toLowerCase().includes(e.target.value.toLowerCase())
        );
      })
    );
  };
  const handleContacted = async (id, data) => {
    const connected = {
      payload: {
        user_id: id,
        value: { isConnected: !data },
      },
    };
    await updateUserData(connected).then((res) => {
      if (res.code === 200) {
        showSuccess("User Contacted Successfully");
        loadData();
      } else {
        showError(res.message);
      }
    });
  };

  return (
    <>
      <div className="nav-wrap">
        <div className="right-wrap margin-left-auto admin-seach">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            className="form-control"
            onChange={(e) => handleSearch(e)}
          />
          <img src={searchicon} alt="Logo" />
        </div>
      </div>
      <div className="admin-table-wrapper">
        <table className="table table-hover admin-table">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Created On</th>
              <th scope="col" className="text-center">Contacted</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {userData.length !== 0 ? (
              userData.map(
                (
                  {
                    name,
                    email,
                    contactNumber,
                    createdAt,
                    status,
                    _id,
                    isConnected,
                  },
                  index
                ) => (
                  <tr key={index}>
                    {/* <th>{index + 1}</th> */}
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{contactNumber}</td>
                    <td>{moment(createdAt).format("Do MMMM YYYY")}</td>
                    <td className="text-center">
                      <input                       
                        type="checkbox"
                        checked={isConnected}
                        onChange={() => handleContacted(_id, isConnected)}
                      />
                    </td>
                    <td>{status}</td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "50px" }}
                >
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalPage > 1 && search === "" && (
        <div className="my-pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={totalPage}
            previousLabel="Prev "
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default UserList;
