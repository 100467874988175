import React, { useState, useEffect, useMemo } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CardDetails from "./ChangeCardDetails";
import EditUser from "./EditUser";
import EditUserPassword from "./EditUserPassword";
import UserSubscription from "./UserSubscription";
import Videos from "./videos";
import Profile from "./profile";
import Subscription from "./subscription";

const Settings = () => {
  document.title = "Settings | ATW";

  return (
    <>
      <div className="right-content-wrap">
        <div className="custom-tab-outer">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="custom-tabs"
          >
            <Tab eventKey="profile" title="Profile">
              {/* <EditUser /> */}
              <Profile />
            </Tab>
            <Tab eventKey="Password" title="Change Password">
              <EditUserPassword />
            </Tab>
            {/* <Tab eventKey="Subscription" title="Subscription Details">
              <Subscription /> */}

            {/* <UserSubscription /> */}
            {/* </Tab> */}
            {/* <Tab eventKey="Card Details" title="Card Details">
              <CardDetails />
            </Tab> */}
            {/* <Tab eventKey="Video" title="Video">
              <Videos />
            </Tab> */}
            {/* <Tab eventKey="Profile Detail" title="Profile">
              <Profile />
            </Tab>
            <Tab eventKey="Subscription Detail" title="Subscription">
              <Subscription />
            </Tab>{" "} */}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Settings;
