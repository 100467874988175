import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_CANVAS_LIST } from "../../../store/canvas/actionTypes";

const CreateCanvas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

  const [search, setSearch] = useState("");
  const [canvasList, setCanvasList] = useState([]);

  const { getCanvasList } = useSelector((state) => state.canvas);

  useEffect(() => {
    document.title = "Canvas List | ATW";

    dispatch({
      type: GET_ALL_CANVAS_LIST,
    });
  }, []);

  useEffect(() => {
    if (getCanvasList) setCanvasList(getCanvasList);
  }, [getCanvasList]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCanvasList(
      getCanvasList.filter((canvas) => {
        return canvas.name.toLowerCase().includes(e.target.value.toLowerCase());
      })
    );
  };

  return (
    <>
      <div className="right-content-wrap">
        <div className="title-block">
          <h1 className="title">Choosing a Model</h1>
          <span className="sub-title">Video link</span>
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <h2>Canvas Models List</h2>
            </div>
          </div>
          <div className="right-wrap margin-left-auto">
            <div className="form-group">
              <input
                type="text"
                placeholder="Search Canvas"
                value={search}
                className="form-control"
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="right-content-inner choose-modal-wrapper">
          <div className="two-col-section">
            <div className="row featured-model">
              {canvasList.length !== 0 ? (
                canvasList.map(({ image, name, redirect_path, _id }, index) => (
                  <div
                    key={index}
                    className="col"
                    onClick={() => navigate(redirect_path + "/" + _id)}
                  >
                    <div className="inner-col">
                      <div className="img-block">
                        <img
                          crossOrigin="anonymous"
                          src={REACT_APP_APP_URL + image}
                        ></img>
                      </div>
                      <span className="content">{name}</span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-record-found">No Records found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCanvas;
