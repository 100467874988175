import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_ALL_CANVAS_LIST,
  GET_CANVAS_BY_STATUS,
  GET_ALL_CANVAS_BY_STATUS,
} from "./actionTypes";
import {
  getAllCanvasListSuccess,
  getAllCanvasListFail,
  getCanvasByStatusSuccess,
  getCanvasByStatusFail,
  getAllCanvasByStatusSuccess,
  getAllCanvasByStatusFail,
} from "./actions";
import {
  getAllCanvasList,
  getCanvasByStatus,
  getAllCanvasByStatus,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onGetAllCanvasList() {
  try {
    const response = yield call(getAllCanvasList);

    if (response) {
      if (response.code === 200) {
        yield put(getAllCanvasListSuccess(response.data));
      } else {
        yield put(getAllCanvasListFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getAllCanvasListFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getAllCanvasListFail(error.response));
  }
}

function* onGetCanvasByStatus(data) {
  try {
    const response = yield call(getCanvasByStatus, data);

    if (response) {
      if (response.code === 200) {
        yield put(getCanvasByStatusSuccess(response.data[0]));
      } else {
        yield put(getCanvasByStatusFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getCanvasByStatusFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getCanvasByStatusFail(error.response));
  }
}

function* onGetAllCanvasByStatus(data) {
  try {
    const response = yield call(getAllCanvasByStatus, data);

    if (response) {
      if (response.code === 200) {
        yield put(getAllCanvasByStatusSuccess(response.data[0]));
      } else {
        yield put(getAllCanvasByStatusFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getAllCanvasByStatusFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getAllCanvasByStatusFail(error.response));
  }
}

function* CanvasSaga() {
  yield takeLatest(GET_ALL_CANVAS_LIST, onGetAllCanvasList);
  yield takeLatest(GET_CANVAS_BY_STATUS, onGetCanvasByStatus);
  yield takeLatest(GET_ALL_CANVAS_BY_STATUS, onGetAllCanvasByStatus);
}

export default CanvasSaga;
