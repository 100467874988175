import React from "react";
import atwLogoHeader from "../../../assets/images/atw-logo-header.svg";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";
import hamburger from "../../../assets/images/hamburger.svg";
import { Link, useNavigate } from "react-router-dom";

const SideBar = () => {
  const navigate = useNavigate();
  const activeMenu = window.location.pathname.substring(7);
  return (
    <>
      <div className="sidebar admin-sidebar is-open">
        <div className="overlay"></div>
        <div className="bg-img">
          <img src={binaryCodeBg} alt="Binarycode bg" />
        </div>
        <div className="sidebar-wrap">
          <Link to="/admin/dashboard" className="logo-wrap">
            <i>
              <img src={atwLogoHeader} alt="Logo" />
            </i>
          </Link>
          <ul className="menu-list">
            <li className={activeMenu === "dashboard" ? `active` : ""}>
              <Link to="/admin/dashboard">Home</Link>
            </li>

            <li className={activeMenu === "user-list" ? `active` : ""}>
              <Link to="/admin/user-list">User List</Link>
            </li>

            <li className={activeMenu === "featured-list" ? `active` : ""}>
              <Link to="/admin/featured-list">Featured List</Link>
            </li>

            <li className={activeMenu === "need-help" ? `active` : ""}>
              <Link to="/admin/need-help">Need Help List</Link>
            </li>
            <li className={activeMenu === "settings" ? `active` : ""}>
              <Link to="/admin/settings">Settings</Link>
            </li>

            <li>
              <Link
                to="#"
                onClick={() => {
                  localStorage.clear();

                  navigate("/");
                }}
              >
                Log out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
