import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const ModalNeedHelp = Loadable({
  loader: () => import("./components/ModalNeedHelp"),
  loading: Loading,
});

export const routes = [
  {
    path: "/model-need_help",
    exact: true,
    name: "modalNeedHelp",
    element: ModalNeedHelp,
  },
];

export * from "./components";
