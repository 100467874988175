import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { REGISTER, GET_COUNTRY_LIST } from "../../../store/login/actionTypes";
import { registerSuccess } from "../../../store/login/actions";
import { showSuccess } from "../../../Utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import atwLogoHeader from "../../../assets/images/logo-large.png";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";
import PreLoginLeftBlock from "./PreLoginLeftBlock";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const { registration, countryList } = useSelector(
    (state) => state.LoginReducer
  );
  const [stateList, setStateList] = useState([]);
  const [countryName, setCountryName] = useState("Australia");
  const [enableTrial, setEnableTrial] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const onSubmit = (data) => {
    data.country = countryName;
    // if (enableTrial) data.status = "trial";
    dispatch({
      type: REGISTER,
      payload: data,
    });
  };

  useEffect(() => {
    document.title = "SignUp | ATW";

    dispatch({
      type: GET_COUNTRY_LIST,
    });
  }, []);

  useEffect(() => {
    if (registration) {
      showSuccess("User Created Successfully");
      navigate("/");
      // if (enableTrial)
      // else navigate("/payment", { state: { email: watch("email") } });
      dispatch(registerSuccess(null));
    }
  }, [registration]);

  const getStateList = (e) => {
    setCountryName(e.target.value);
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let jobTypeParentId = optionElement.getAttribute("data-id")
      ? optionElement.getAttribute("data-id")
      : "";

    if (jobTypeParentId !== "") {
      setStateList(countryList[jobTypeParentId].states);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="main-wrap login-page">
          <div className="prelogin-outer-wrap">
            <div className="img-form-section">
              <div className="bg-img">
                <img src={binaryCodeBg} alt="Background Img" />
              </div>
              <div className="container">
                <div className="row-wrap">
                  <PreLoginLeftBlock />
                  <div className="right-content-wrap">
                    <div className="title-block">
                      <h1 className="title">Sign Up</h1>
                      <span className="sub-title">
                        Create Account To Get Started
                      </span>
                    </div>
                    <div className="right-content-inner">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="pre-login-form"
                      >
                        <div className="top-block">
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              aria-describedby="emailHelp"
                              placeholder="Please enter your name"
                              {...register("name", {
                                required: "Name is required",
                              })}
                            />
                            <p className="error-message">
                              {errors.name?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Email<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Please enter your email id"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.email?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputPassword1"
                            >
                              Password<span className="required">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Please enter your password"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password length should be at least 8 characters",
                                },
                                maxLength: {
                                  value: 12,
                                  message:
                                    "Password cannot exceed more than 12 characters",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.password?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Contact Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contact number"
                              aria-describedby="emailHelp"
                              placeholder="04 0000 0000"
                              {...register("contactNumber")}
                            />
                          </div>
                          {/* <div className="form-group">
                            <label className="reg-label" htmlFor="exampleInputEmail1">
                              Street Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              aria-describedby="emailHelp"
                              placeholder="Enter Your Address"
                              {...register("address", {
                                required: "address is required",
                              })}
                            />
                            <p className="error-message">
                              {errors.address?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label className="reg-label" htmlFor="exampleInputEmail1">Suburb</label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              aria-describedby="emailHelp"
                              placeholder="Enter Your City"
                              {...register("city", {
                                required: "City is required",
                              })}
                            />
                            <p className="error-message">
                              {errors.city?.message}
                            </p>
                          </div>{" "} */}
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Country of Residence
                              <span className="required">*</span>
                            </label>

                            <select
                              className="form-select"
                              {...register("country")}
                              value={countryName}
                              onChange={(e) => getStateList(e)}
                            >
                              <option value="" data-id="">
                                Please Enter your country of residence
                              </option>
                              {countryList &&
                                countryList.length !== 0 &&
                                countryList.map(({ country }, index) => (
                                  <option
                                    value={country}
                                    key={index}
                                    data-id={index}
                                  >
                                    {country}
                                  </option>
                                ))}
                            </select>
                          </div>

                          {/* <div className="form-group signup d-flex">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="sign-form-check"
                              checked={enableTrial}
                              onChange={() => setEnableTrial(!enableTrial)}
                            />
                            <label
                              className="reg-label"
                              style={{ marginLeft: "10px" }}
                              htmlFor="sign-form-check"
                            >
                              Get 3 months FREE ($4.99 per month after)
                            </label>
                          </div>
                          {!enableTrial && (
                            <div className="form-group">
                              <label>
                                Subscription is $4.99 per month with option to
                                cancel any time.
                              </label>
                            </div>
                          )} */}
                          <div
                            className="form-group signup d-flex123
                          "
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="sign-form-check"
                              checked={acceptTerms}
                              onChange={() => setAcceptTerms(!acceptTerms)}
                            />
                            <label
                              className="reg-label"
                              style={{ marginLeft: "10px" }}
                              htmlFor="sign-form-check"
                            >
                              I agree to the{" "}
                              <a
                                href="https://afterthewhy.com/terms-and-conditions/"
                                target="_blank"
                              >
                                Terms and Conditions
                              </a>
                            </label>
                          </div>

                          <div className="d-flex justify-content-center btn-wrap">
                            <button
                              type="submit"
                              className="btn transparent-bg"
                              disabled={!acceptTerms}
                            >
                              Create Account
                            </button>
                          </div>
                        </div>
                        <div className="text-link-wrap">
                          <p className="content">Already have an Account ?</p>
                          <Link to="/" className="primary-link">
                            Log In
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
