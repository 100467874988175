import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_CANVAS_LIST } from "../../../store/canvas/actionTypes";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { updateFeaturedModels } from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import searchicon from "../../../assets/images/search.png";

const FeaturedList = () => {
  const dispatch = useDispatch();
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);

  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [updatedValue, setUpdatedValue] = useState([]);

  const { getCanvasList } = useSelector((state) => state.canvas);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_ALL_CANVAS_LIST,
    });
  };

  useEffect(() => {
    if (getCanvasList) {
      setUserData(getFilteredData(0));
      setTotalPage(Math.ceil(getCanvasList.length / recordPerPage));
    }
  }, [getCanvasList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (getCanvasList[i]) list.push(getCanvasList[i]);
    }
    return list;
  };

  const handlePageClick = (e) => {
    setUserData(getFilteredData(e.selected * recordPerPage));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setUserData(
      getCanvasList.filter((canvas) => {
        return canvas.name.toLowerCase().includes(e.target.value.toLowerCase());
      })
    );
  };

  const handleInputChange = (value, index) => {
    let data = userData;
    data[index].isFeatured = value;
    setUserData([...data]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let request = [];
    userData.map(({ isFeatured, _id }) => {
      request.push({
        featured_model_id: _id,
        isFeatured: isFeatured,
      });
    });

    const payloadData = {
      payload: { request },
    };
    await updateFeaturedModels(payloadData).then((res) => {
      if (res.code === 200) {
        showSuccess("List Updated Successfully");
      } else {
        showError(res.message);
      }
    });
  };

  return (
    <>
      <div className="right-content-wrap admin-right-wrap">
        <div className="title-block">
          <h1 className="title">Featured List</h1>
        </div>
        <div className="admin-right-sec">
          <div className="nav-wrap">
            <div className="right-wrap margin-left-auto admin-seach">
              <input
                type="text"
                placeholder="Search Canvas"
                value={search}
                className="form-control"
                onChange={(e) => handleSearch(e)}
              />
              <img src={searchicon} alt="Logo" />
            </div>
          </div>
          <div className="admin-table-wrapper featured-list-table mb-5">
            <table className="table table-hover admin-table">
              <thead>
                <tr>
                  <th scope="col">Name</th>

                  <th scope="col">Created On</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userData.length !== 0 ? (
                  userData.map(
                    ({ name, createdAt, _id, isFeatured }, index) => (
                      <tr key={index}>
                        <td>{name}</td>

                        <td>{moment(createdAt).format("Do MMMM YYYY")}</td>

                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isFeatured}
                            onChange={() =>
                              handleInputChange(!isFeatured, index)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ textAlign: "center", paddingTop: "50px" }}
                    >
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {userData.length !== 0 && (
            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn white-border"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          )}
        </div>

        {totalPage > 1 && search === "" && (
          <div className="my-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={totalPage}
              previousLabel="Prev "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FeaturedList;
