import {
  GET_ALL_CANVAS_LIST,
  GET_ALL_CANVAS_LIST_SUCCESS,
  GET_ALL_CANVAS_LIST_FAIL,
  GET_CANVAS_BY_STATUS,
  GET_CANVAS_BY_STATUS_SUCCESS,
  GET_CANVAS_BY_STATUS_FAIL,
  GET_ALL_CANVAS_BY_STATUS,
  GET_ALL_CANVAS_BY_STATUS_SUCCESS,
  GET_ALL_CANVAS_BY_STATUS_FAIL,
} from "./actionTypes";

export const getAllCanvasList = () => {
  return {
    type: GET_ALL_CANVAS_LIST,
  };
};

export const getAllCanvasListSuccess = (data) => {
  return {
    type: GET_ALL_CANVAS_LIST_SUCCESS,
    payload: data,
  };
};

export const getAllCanvasListFail = (error) => {
  return {
    type: GET_ALL_CANVAS_LIST_FAIL,
    payload: error,
  };
};

export const getCanvasByStatus = () => {
  return {
    type: GET_CANVAS_BY_STATUS,
  };
};

export const getCanvasByStatusSuccess = (data) => {
  return {
    type: GET_CANVAS_BY_STATUS_SUCCESS,
    payload: data,
  };
};

export const getCanvasByStatusFail = (error) => {
  return {
    type: GET_CANVAS_BY_STATUS_FAIL,
    payload: error,
  };
};

export const getAllCanvasByStatus = () => {
  return {
    type: GET_ALL_CANVAS_BY_STATUS,
  };
};

export const getAllCanvasByStatusSuccess = (data) => {
  return {
    type: GET_ALL_CANVAS_BY_STATUS_SUCCESS,
    payload: data,
  };
};

export const getAllCanvasByStatusFail = (error) => {
  return {
    type: GET_ALL_CANVAS_BY_STATUS_FAIL,
    payload: error,
  };
};
