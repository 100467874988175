import {
  UPDATE_RIDRER_AND_DRIVER,
  UPDATE_RIDRER_AND_DRIVER_SUCCESS,
  UPDATE_RIDRER_AND_DRIVER_FAIL,
  UPDATE_90DAY_PLAN,
  UPDATE_90DAY_PLAN_SUCCESS,
  UPDATE_90DAY_PLAN_FAIL,
  UPDATE_TECH_STACK,
  UPDATE_TECH_STACK_SUCCESS,
  UPDATE_TECH_STACK_FAIL,
  UPDATE_TESLA_VALUE,
  UPDATE_TESLA_VALUE_SUCCESS,
  UPDATE_TESLA_VALUE_FAIL,
  UPDATE_VISION_BOARD,
  UPDATE_VISION_BOARD_SUCCESS,
  UPDATE_VISION_BOARD_FAIL,
} from "./actionTypes";

export const updateRiderAndDriver = () => {
  return {
    type: UPDATE_RIDRER_AND_DRIVER,
  };
};

export const updateRiderAndDriverSuccess = (data) => {
  return {
    type: UPDATE_RIDRER_AND_DRIVER_SUCCESS,
    payload: data,
  };
};

export const updateRiderAndDriverFail = (error) => {
  return {
    type: UPDATE_RIDRER_AND_DRIVER_FAIL,
    payload: error,
  };
};

export const update90DayPlan = () => {
  return {
    type: UPDATE_90DAY_PLAN,
  };
};

export const update90DayPlanSuccess = (data) => {
  return {
    type: UPDATE_90DAY_PLAN_SUCCESS,
    payload: data,
  };
};

export const update90DayPlanFail = (error) => {
  return {
    type: UPDATE_90DAY_PLAN_FAIL,
    payload: error,
  };
};

export const updateTechStack = () => {
  return {
    type: UPDATE_TECH_STACK,
  };
};

export const updateTechStackSuccess = (data) => {
  return {
    type: UPDATE_TECH_STACK_SUCCESS,
    payload: data,
  };
};

export const updateTechStackFail = (error) => {
  return {
    type: UPDATE_TECH_STACK_FAIL,
    payload: error,
  };
};

export const updateTeslaValue = () => {
  return {
    type: UPDATE_TESLA_VALUE,
  };
};

export const updateTeslaValueSuccess = (data) => {
  return {
    type: UPDATE_TESLA_VALUE_SUCCESS,
    payload: data,
  };
};

export const updateTeslaValueFail = (error) => {
  return {
    type: UPDATE_TESLA_VALUE_FAIL,
    payload: error,
  };
};

export const updateVisionBoard = () => {
  return {
    type: UPDATE_VISION_BOARD,
  };
};

export const updateVisionBoardSuccess = (data) => {
  return {
    type: UPDATE_VISION_BOARD_SUCCESS,
    payload: data,
  };
};

export const updateVisionBoardFail = (error) => {
  return {
    type: UPDATE_VISION_BOARD_FAIL,
    payload: error,
  };
};
