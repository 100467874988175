import {
  GET_RIDRER_AND_DRIVER,
  GET_RIDRER_AND_DRIVER_SUCCESS,
  GET_RIDRER_AND_DRIVER_FAIL,
  GET_DAY90_PLAN,
  GET_DAY90_PLAN_SUCCESS,
  GET_DAY90_PLAN_FAIL,
  GET_TECH_STACK,
  GET_TECH_STACK_SUCCESS,
  GET_TECH_STACK_FAIL,
  GET_TESLA_VALUE,
  GET_TESLA_VALUE_SUCCESS,
  GET_TESLA_VALUE_FAIL,
  GET_VISION_BOARD,
  GET_VISION_BOARD_SUCCESS,
  GET_VISION_BOARD_FAIL,
} from "./actionTypes";

export const getRiderAndDriver = () => {
  return {
    type: GET_RIDRER_AND_DRIVER,
  };
};

export const getRiderAndDriverSuccess = (data) => {
  return {
    type: GET_RIDRER_AND_DRIVER_SUCCESS,
    payload: data,
  };
};

export const getRiderAndDriverFail = (error) => {
  return {
    type: GET_RIDRER_AND_DRIVER_FAIL,
    payload: error,
  };
};

export const getDay90Plan = () => {
  return {
    type: GET_DAY90_PLAN,
  };
};

export const getDay90PlanSuccess = (data) => {
  return {
    type: GET_DAY90_PLAN_SUCCESS,
    payload: data,
  };
};

export const getDay90PlanFail = (error) => {
  return {
    type: GET_DAY90_PLAN_FAIL,
    payload: error,
  };
};

export const getTechStack = () => {
  return {
    type: GET_TECH_STACK,
  };
};

export const getTechStackSuccess = (data) => {
  return {
    type: GET_TECH_STACK_SUCCESS,
    payload: data,
  };
};

export const getTechStackFail = (error) => {
  return {
    type: GET_TECH_STACK_FAIL,
    payload: error,
  };
};

export const getTeslaValue = () => {
  return {
    type: GET_TESLA_VALUE,
  };
};

export const getTeslaValueSuccess = (data) => {
  return {
    type: GET_TESLA_VALUE_SUCCESS,
    payload: data,
  };
};

export const getTeslaValueFail = (error) => {
  return {
    type: GET_TESLA_VALUE_FAIL,
    payload: error,
  };
};

export const getVisionBoard = () => {
  return {
    type: GET_VISION_BOARD,
  };
};

export const getVisionBoardSuccess = (data) => {
  return {
    type: GET_VISION_BOARD_SUCCESS,
    payload: data,
  };
};

export const getVisionBoardFail = (error) => {
  return {
    type: GET_VISION_BOARD_FAIL,
    payload: error,
  };
};
