import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Checkout";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
const ChangeCard = ({ showModal, handleModalClose, cardId, customerId }) => {
  const { state } = useLocation();
  const [userDetails, setUserDetails] = useState({
    cardName: "",
  });
  const [CheckValidation, setCheckValidation] = useState(false);

  const handleInput = (e) =>
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  useEffect(() => {
    if (state) {
      setUserDetails({ ...userDetails, email: state.email });
    }
  }, [state]);

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
        className="change-card-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Card Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="">
            <div className="header">
              <div className="header-inner"> */}
          <span className="card-detail-title">Card Holder Name</span>
          {/* </div>
            </div> */}
          {/* <div className="right-content-inner"> */}
          {/* <form className="pre-login-form"> */}
          {/* <div className="top-block"> */}
          <form>
            <div className="form-group">
              <label htmlFor="name of card">Name of Card</label>
              <input
                type="text"
                className="form-control"
                id="name of card"
                placeholder="Card Holder Name"
                name="cardName"
                value={userDetails.cardName}
                onChange={(e) => handleInput(e)}
              />
              {CheckValidation && userDetails.cardName === "" && (
                <p className="err-msg"> Card Name is required</p>
              )}
            </div>
            <div className="pay-info">
              <span className="card-detail-title">Card Information</span>
              <div className="pay-info-inner">
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    userDetails={userDetails}
                    setCheckValidation={setCheckValidation}
                    handleModalClose={handleModalClose}
                    cardId={cardId}
                    customerId={customerId}
                  />
                </Elements>
              </div>
            </div>
            {/* </div> */}
          </form>
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeCard;
