import React, { useEffect, useState } from "react";
import editIcon from "../../../assets/images/edit.svg";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import { deleteAccount, getUserById } from "../../../helpers/backendHelper";
import { useNavigate } from "react-router-dom";
import UpdateUserName from "./updateUserDetails";
import { UserId } from "../../../Utils/getUserId";
import UpdateUserCountry from "./updateUserCountry";
import DeleteConfirm from "../../common/deleteConfirm";

const Profile = () => {
  const navigate = useNavigate();

  const [UserData, setUserData] = useState();
  const [showEditName, setShowEditName] = useState(false);
  const [showEditCountry, setShowEditCountry] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const user_id = UserId();
  useEffect(() => {
    if (user_id) loadUserData(user_id);
  }, [user_id]);

  const loadUserData = async (id) => {
    let userPayload = {
      payload: id,
    };
    await getUserById(userPayload).then((res) => {
      if (res.code === 200) setUserData(res.data);
      else showError(res.message);
    });
  };
  document.title = "User Details | ATW";

  const handleDeleteClose = () => setShowDeleteModal(false);
  const deleteRecord = async () => {
    handleDeleteClose();
    await deleteAccount().then((res) => {
      if (res.code === 200) {
        showSuccess("Account Deleted Successfully");
        navigate("/");
      } else showError(res.message);
    });
  };

  return (
    <div className="custom-tabs-body profile-detail">
      <div className="tabs-body-inner">
        <div className="row">
          <div className="col-md-8 mb-3">
            <div className="outer-content-wrap">
              <button
                className="edit-btn"
                onClick={() => setShowEditName(true)}
              >
                <img src={editIcon} alt="" />
              </button>
              <div className="settings-title-block">
                <span className="title">{UserData?.name || ""}</span>
                <span className="subtitle">{UserData?.email || ""}</span>

                <span className="subtitle">{UserData?.title || ""}</span>
              </div>
              <p>{UserData?.description}</p>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="outer-content-wrap">
              <button
                className="edit-btn"
                onClick={() => setShowEditCountry(true)}
              >
                <img src={editIcon} alt="" />
              </button>
              <div className="settings-title-block">
                <span className="title">Country</span>
                <span className="subtitle">{UserData?.country || ""}</span>
              </div>
              <div className="settings-title-block">
                <span className="title">Mobile</span>
                <a href="tel:0413291782" className="subtitle">
                  {UserData?.contactNumber || ""}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-wrap d-flex justify-content-between ">
          <button
            className="btn gray-bg"
            onClick={() => navigate("/my-models")}
          >
            My Models
          </button>
          <button
            className="btn red-bg "
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Account
          </button>
        </div>
      </div>
      {showEditName && (
        <UpdateUserName
          showEditName={showEditName}
          setShowEditName={setShowEditName}
          UserData={UserData}
          loadUserData={loadUserData}
        />
      )}

      {showEditCountry && (
        <UpdateUserCountry
          showEditCountry={showEditCountry}
          setShowEditCountry={setShowEditCountry}
          UserData={UserData}
          loadUserData={loadUserData}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={handleDeleteClose}
          deleteRecord={deleteRecord}
          msg={"Are you sure you want to Delete the Account?"}
          icon={"info"}
        />
      )}
    </div>
  );
};

export default Profile;
