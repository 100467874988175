import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USER_SUBSCRIPTION, GET_USER_CARD_DETAILS } from "./actionTypes";
import {
  getUserSubscriptionSuccess,
  getUserSubscriptionFail,
  getUserCardDetailsSuccess,
  getUserCardDetailsFail,
} from "./actions";
import {
  getUserSubscription,
  getUserCardDetails,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onGetUserSubscription(data) {
  try {
    const response = yield call(getUserSubscription, data);

    if (response) {
      if (response.code === 200) {
        yield put(getUserSubscriptionSuccess(response.data[0]));
      } else {
        yield put(getUserSubscriptionFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getUserSubscriptionFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUserSubscriptionFail(error.response));
  }
}

function* onGetUserCardDetails(data) {
  try {
    const response = yield call(getUserCardDetails, data);

    if (response) {
      if (response.code === 200) {
        yield put(getUserCardDetailsSuccess(response.data[0]));
      } else {
        yield put(getUserCardDetailsFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(getUserCardDetailsFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(getUserCardDetailsFail(error.response));
  }
}

function* SubscriptionSaga() {
  yield takeLatest(GET_USER_SUBSCRIPTION, onGetUserSubscription);
  yield takeLatest(GET_USER_CARD_DETAILS, onGetUserCardDetails);
}

export default SubscriptionSaga;
