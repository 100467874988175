import {
  GET_ALL_CANVAS_LIST,
  GET_ALL_CANVAS_LIST_SUCCESS,
  GET_ALL_CANVAS_LIST_FAIL,
  GET_CANVAS_BY_STATUS,
  GET_CANVAS_BY_STATUS_SUCCESS,
  GET_CANVAS_BY_STATUS_FAIL,
  GET_ALL_CANVAS_BY_STATUS,
  GET_ALL_CANVAS_BY_STATUS_SUCCESS,
  GET_ALL_CANVAS_BY_STATUS_FAIL,
} from "./actionTypes";

const initialState = {
  getCanvasList: null,
  canvasByStatus: null,
  allCanvasByStatus: null,
  canvasLoading: false,
  error: {
    message: "",
  },
};

const CanvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CANVAS_LIST:
      state = {
        ...state,
        canvasLoading: true,
      };
      break;
    case GET_ALL_CANVAS_LIST_SUCCESS:
      state = {
        ...state,
        canvasLoading: false,
        getCanvasList: action.payload,
      };
      break;
    case GET_ALL_CANVAS_LIST_FAIL:
      state = {
        ...state,
        getCanvasList: null,
        error: {
          message: action.payload,
        },
        canvasLoading: false,
      };
      break;

    case GET_CANVAS_BY_STATUS:
      state = {
        ...state,
        canvasLoading: true,
      };
      break;
    case GET_CANVAS_BY_STATUS_SUCCESS:
      state = {
        ...state,
        canvasLoading: false,
        canvasByStatus: action.payload,
      };
      break;
    case GET_CANVAS_BY_STATUS_FAIL:
      state = {
        ...state,
        canvasByStatus: null,
        error: {
          message: action.payload,
        },
        canvasLoading: false,
      };
      break;

    case GET_ALL_CANVAS_BY_STATUS:
      state = {
        ...state,
        canvasLoading: true,
      };
      break;
    case GET_ALL_CANVAS_BY_STATUS_SUCCESS:
      state = {
        ...state,
        canvasLoading: false,
        allCanvasByStatus: action.payload,
      };
      break;
    case GET_ALL_CANVAS_BY_STATUS_FAIL:
      state = {
        ...state,
        allCanvasByStatus: null,
        error: {
          message: action.payload,
        },
        canvasLoading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CanvasReducer;
