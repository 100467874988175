import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { sendMail } from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";

function MailModal({
  show,
  handleClose,
  sendMailTo,
  setSendMailTo,
  modalSelected,
  loadData,
  setIsloading,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setSendMailTo({
      ...sendMailTo,
      [name]: value,
    });
  };
  const handleMail = async () => {
    if (sendMailTo.subject === "") {
      showError("Subject Can't Be Empty");
    } else if (sendMailTo.message === "") {
      showError("Message Can't Be Empty");
    } else {
      setIsloading(true);
      handleClose();
      const mailPayload = {
        payload: {
          sendMailTo,
          model_id: modalSelected.id,
          model_type: modalSelected.name,
        },
      };

      await sendMail(mailPayload).then((res) => {
        if (res.code === 200) {
          showSuccess("Mail Send Successfully");
          loadData();
          setIsloading(false);
        } else {
          setIsloading(false);
          showError(res.message);
        }
      });
    }
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} className="mail-dialog">
        <Modal.Header closeButton>
          <Modal.Title>Send Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="form-group with-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="reg-label">Email address</Form.Label>
              <Form.Control
                className="form-control"
                type="email"
                value={sendMailTo.mailTo}
                placeholder="name@example.com"
                name="mailTo"
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>

            <Form.Group
              className="form-group with-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label className="reg-label">Subject</Form.Label>
              <Form.Control
                className="form-control"
                type="text"
                placeholder="Subject"
                autoFocus
                name="subject"
                value={sendMailTo.subject}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>

            <Form.Group
              className="form-group with-label"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="reg-label">Message</Form.Label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                name="message"
                value={sendMailTo.message}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn secondary-bg" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn black-bg" onClick={handleMail}>
            Send Mail
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default MailModal;
