import {
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  GET_USER_DATA,
  GET_ACTIVE_USER_LIST,
  GET_ACTIVE_USER_LIST_SUCCESS,
  GET_ACTIVE_USER_LIST_FAIL,
  GET_PENDING_USER_LIST,
  GET_PENDING_USER_LIST_SUCCESS,
  GET_PENDING_USER_LIST_FAIL,
  GET_INACTIVE_USER_LIST,
  GET_INACTIVE_USER_LIST_SUCCESS,
  GET_INACTIVE_USER_LIST_FAIL,
} from "./actionTypes";

export const getLogin = () => {
  return {
    type: GET_LOGIN,
  };
};

export const getLoginSuccess = (data) => {
  return {
    type: GET_LOGIN_SUCCESS,
    payload: data,
  };
};

export const getLoginFail = (error) => {
  return {
    type: GET_LOGIN_FAIL,
    payload: error,
  };
};

export const register = () => {
  return {
    type: REGISTER,
  };
};

export const registerSuccess = (data) => {
  return {
    type: REGISTER_SUCCESS,
    payload: data,
  };
};

export const registerFail = (error) => {
  return {
    type: REGISTER_FAIL,
    payload: error,
  };
};

export const forgetPassword = () => {
  return {
    type: FORGET_PASSWORD,
  };
};

export const forgetPasswordSuccess = (data) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgetPasswordFail = (error) => {
  return {
    type: FORGET_PASSWORD_FAIL,
    payload: error,
  };
};

export const resetPassword = () => {
  return {
    type: RESET_PASSWORD,
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: error,
  };
};

export const getUserData = () => {
  return {
    type: GET_USER_DATA,
  };
};

export const getCountryList = () => {
  return {
    type: GET_COUNTRY_LIST,
  };
};

export const getCountryListSuccess = (data) => {
  return {
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: data,
  };
};

export const getCountryListFail = (error) => {
  return {
    type: GET_COUNTRY_LIST_FAIL,
    payload: error,
  };
};

export const updateUserProfile = () => {
  return {
    type: UPDATE_USER_PROFILE,
  };
};

export const updateUserProfileSuccess = (data) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

export const updateUserProfileFail = (error) => {
  return {
    type: UPDATE_USER_PROFILE_FAIL,
    payload: error,
  };
};

export const getActiveUserList = () => {
  return {
    type: GET_ACTIVE_USER_LIST,
  };
};

export const getActiveUserListSuccess = (data) => {
  return {
    type: GET_ACTIVE_USER_LIST_SUCCESS,
    payload: data,
  };
};

export const getActiveUserListFail = (error) => {
  return {
    type: GET_ACTIVE_USER_LIST_FAIL,
    payload: error,
  };
};

export const getPendingUserList = () => {
  return {
    type: GET_PENDING_USER_LIST,
  };
};

export const getPendingUserListSuccess = (data) => {
  return {
    type: GET_PENDING_USER_LIST_SUCCESS,
    payload: data,
  };
};

export const getPendingUserListFail = (error) => {
  return {
    type: GET_PENDING_USER_LIST_FAIL,
    payload: error,
  };
};
export const getInactiveUserList = () => {
  return {
    type: GET_INACTIVE_USER_LIST,
  };
};

export const getInactiveUserListSuccess = (data) => {
  return {
    type: GET_INACTIVE_USER_LIST_SUCCESS,
    payload: data,
  };
};

export const getInactiveUserListFail = (error) => {
  return {
    type: GET_INACTIVE_USER_LIST_FAIL,
    payload: error,
  };
};
