import { combineReducers } from "redux";
import LoginReducer from "./login/reducer";
import SaveCanvasReducer from "./saveCanvas/reducer";
import UpdateCanvas from "./updateCanvas/reducer";
import GetCanvas from "./getCanvas/reducer";
import canvas from "./canvas/reducer";
import Subscription from "./subscription/reducer";

const rootReducer = combineReducers({
  LoginReducer,
  SaveCanvasReducer,
  UpdateCanvas,
  GetCanvas,
  canvas,
  Subscription,
});

export default rootReducer;
