import React, { useState, useRef, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import { TECH_STACK } from "../../../../store/saveCanvas/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { UserId } from "../../../../Utils/getUserId";
import { techStackSuccess } from "../../../../store/saveCanvas/actions";
import { UPDATE_TECH_STACK } from "../../../../store/updateCanvas/actionTypes";
import { updateTechStackSuccess } from "../../../../store/updateCanvas/actions";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../../common/sharedCanvasModal";
import Loader from "../../../common/loader";
import { useNavigatingAway } from "../../../../Utils/useNavigatingAway";
import DeleteConfirm from "../../../common/deleteConfirm";
import StackIcon from "../../../../assets/images/stack.png";

const options = [
  { value: "start", label: "Started" },
  { value: "parked", label: "Parked" },
  { value: "need_help", label: "Need Help" },
  { value: "in_prodution", label: "In Production" },
];

function TechStack({ techData }) {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [needHelp, setNeedHelp] = useState(false);

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [activeTab, setActiveTab] = useState("");
  const [status, setStatus] = useState({ value: "start", label: "Started" });
  const [addRow, setAddRow] = useState([]);
  const [headerActive, setHeaderActive] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [subHeaderActive, setSubHeaderActive] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [data, setData] = useState({
    keyUserInterface: "",
    keyDatabase: "",
    keyBackend: "",
    keyNotification: "",
    keyHosting: "",
    userInterface: [],
    database: [],
    backend: [],
    notification: [],
    Hosting: [],
  });
  const [canvasId, setCanvasId] = useState("");

  let { techStack, saveCanvasLoading } = useSelector(
    (state) => state.SaveCanvasReducer
  );
  let { updateTechStack } = useSelector((state) => state.UpdateCanvas);
  let { loadingLogins } = useSelector((state) => state.GetCanvas);

  document.title = "Technology Stack | ATW";

  const handleChange = (e, tab) => {
    setCanShowDialogLeavingPage(true);
    let tabValue = data[tab];
    if (tab.charAt(0) === "k") {
      if (e.key === "Enter") {
        tabValue = e.target.value;
        // inputRef.current.value = "";
        setActiveTab("");
      }
    } else {
      if (e.key === "Enter") {
        if (e.target.value.trim() === "") {
          showError("Value Can't Be Empty");
          return;
        }
        tabValue.push(e.target.value);
        inputRef.current.value = "";
      }
    }
    setData({ ...data, [tab]: tabValue });
  };

  const handleDelete = (index, tab) => {
    let tabValue = data[tab];
    tabValue.splice(index, 1);
    setData({ ...data, tab: tabValue });
  };

  useEffect(() => {
    if (techData) {
      setData({
        keyUserInterface: techData.keyUserInterface || "",
        keyDatabase: techData.keyDatabase,
        keyBackend: techData.keyBackend,
        keyNotification: techData.keyNotification,
        keyHosting: techData.keyHosting,
        userInterface: techData.user_interface,
        database: techData.database,
        backend: techData.backend,
        notification: techData.notification_massage,
        Hosting: techData.hosting_cloud_services,
      });
      setCanvasId(techData._id);
      setStatus(
        options.find((option) => {
          if (option.value === techData.model_status) return option;
        })
      );
      setAddRow(techData.additionalRow);
      setHeaderText(techData.title);
      setSubHeaderText(techData.sub_title);
    }
  }, [techData]);

  const printDocument = async () => {
    handleSave("pdf");
  };

  const handleSave = (checkPdf) => {
    setCanShowDialogLeavingPage(false);

    if (headerText === "") {
      showError("Enter Canvas Tile");
      return;
    }
    if (subHeaderText === "") {
      showError("Enter Canvas Description");
      return;
    }
    if (checkValidation()) {
      showError("Canvas can't be Empty");
    } else {
      if (checkPdf === "pdf") setGeneratePdf(true);
      let payload = {
        user_id: UserId(),
        modal_id: id,
        title: headerText,
        sub_title: subHeaderText,
        keyUserInterface: data.keyUserInterface,
        keyDatabase: data.keyDatabase,
        keyBackend: data.keyBackend,
        keyNotification: data.keyNotification,
        keyHosting: data.keyHosting,
        user_interface: data.userInterface,
        database: data.database,
        backend: data.backend,
        notification_massage: data.notification,
        hosting_cloud_services: data.Hosting,
        additionalRow: addRow,
        model_status: status.value,
        completed: canvasCompleted(),
      };

      if (canvasId === "") {
        dispatch({
          type: TECH_STACK,
          payload,
        });
      } else {
        payload.modal_id = techData.modal_id.id;
        payload["id"] = canvasId;
        delete payload.user_id;
        dispatch({
          type: UPDATE_TECH_STACK,
          payload,
        });
      }
    }
  };

  useEffect(() => {
    if (techStack) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/tack_stack/pdf/${techStack._id}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/tack_stack/pdf/${techStack._id}`,
            "_blank"
          );
        }

        setGeneratePdf(false);
      } else {
        if (canvasId === "") {
          setCanvasId(techStack._id);
          showSuccess("Canvas Saved Successfully");
          navigate(`/edit/canvas/tech-stack/${techStack._id}`);
        }
      }
      dispatch(techStackSuccess(null));
    }
  }, [techStack]);

  useEffect(() => {
    if (updateTechStack) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/tack_stack/pdf/${canvasId}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/tack_stack/pdf/${canvasId}`,
            "_blank"
          );
        }
        setGeneratePdf(false);
      } else {
        showSuccess("Canvas Updated Successfully");
      }
      dispatch(updateTechStackSuccess(null));
    }
  }, [updateTechStack]);

  const checkValidation = () => {
    let checkValidation = false;
    if (
      (data.userInterface === null || data.userInterface.length === 0) &&
      (data.database === null || data.database.length === 0) &&
      (data.backend === null || data.backend.length === 0) &&
      (data.notification === null || data.notification.length === 0) &&
      (data.Hosting === null || data.Hosting.length === 0)
    )
      checkValidation = true;
    if (addRow.length !== 0) {
      addRow.map((row) => {
        if (
          row[Object.keys(row)[0]].length !== 0 &&
          row[Object.keys(row)[1]].length !== 0
        ) {
          checkValidation = false;
        }
      });
    }

    return checkValidation;
  };

  const canvasCompleted = () => {
    let partialValue = 0;
    for (const property in data) {
      if (data[property]) {
        if (data[property].length !== 0) partialValue += 1;
      }
    }

    if (addRow.length !== 0) {
      addRow.map((row) => {
        if (
          row[Object.keys(row)[0]].length !== 0 &&
          row[Object.keys(row)[1]].length !== 0
        ) {
          partialValue += 1;
        }
      });
    }
    return Math.round((100 * partialValue) / partialValue);
  };
  const handleStatusChange = (value) => {
    setCanShowDialogLeavingPage(true);

    setStatus(value);
  };

  const handleAddRow = () => {
    setActiveTab("");
    let rowLength = addRow.length;
    const key = `key${rowLength}`;
    const value = `value${rowLength}`;
    setAddRow([...addRow, { [key]: [], [value]: [] }]);
  };

  const handleAddRowChange = (e, tab, index) => {
    setCanShowDialogLeavingPage(true);

    let temporaryarray = addRow.slice();
    if (tab.charAt(0) === "k") {
      if (e.key === "Enter") {
        if (e.target.value.trim() === "") {
          showError("Value Can't Be Empty");
          return;
        }
        temporaryarray[index][tab] = e.target.value;
        inputRef.current.value = "";
        setAddRow(temporaryarray);
        setActiveTab("");
      }
    } else {
      if (e.key === "Enter") {
        if (e.target.value.trim() === "") {
          showError("Value Can't Be Empty");
          return;
        }
        temporaryarray[index][tab].push(e.target.value);
        inputRef.current.value = "";
        setAddRow(temporaryarray);
      }
    }
  };

  const handleDeleteRowChange = (index, tab, recordIndex) => {
    let tabValue = addRow.slice();
    tabValue[index][tab].splice(recordIndex, 1);
    setAddRow(tabValue);
  };

  const handleDeleteRow = (index) => {
    let tabValue = addRow.slice();
    tabValue.splice(index, 1);

    setAddRow(tabValue);
  };

  const deleteRow = (tab) => {
    setData((prevData) => {
      const newData = { ...prevData };
      newData[tab] = null;
      return newData;
    });
  };

  const handleHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        headerText === "" && showError("Heading Can't Be Empty");
        setHeaderActive(false);
        return;
      }
      setHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setHeaderText(e.target.value);
  };

  const handleSubHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        subHeaderText === "" && showError("Description Can't Be Empty");
        setSubHeaderActive(false);
        return;
      }
      setSubHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setSubHeaderText(e.target.value);
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block light-blue-bg">
          <div className="title-inner">
            <div className="left-block">
              <i className="icon">
                <img src={StackIcon} alt="Stack" />
              </i>
              <h2 className="title">Technology Stack</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>

              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>Technology Stack Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>Use the editable text fields to create your Model.</p>
                    <p className="mt-3">
                      Use your mouse cursor to click on the various grey boxes
                      to edit the Model Title, Model Description and each of the
                      key information sections in the model itself.
                    </p>

                    <p className="mt-3">
                      Once finished, click{" "}
                      <span className="gray-btn">Save</span>, and then click
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      to open the drop down menu. You can then choose whether to
                      Share or download. Clicking download will open the filled
                      model in a PDF for you to save to your computer.
                    </p>

                    <p className="mt-3">
                      Use the button to <span className="gray-btn">Add</span> a
                      new Tech Stage and editable Services Row. To remove any of
                      the rows, simply click the{" "}
                      <span className="gray-btn">x</span> located on the far
                      right of the model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-inner" onClick={() => setHeaderActive(true)}>
            {headerActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleHeaderText(e)}
                  placeholder="Enter Canvas Heading"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : headerText === "" ? (
              <h1 className="title">Enter Canvas Heading</h1>
            ) : (
              <h1 className="title">{headerText}</h1>
            )}
          </div>
          <div className="title-inner" onClick={() => setSubHeaderActive(true)}>
            {subHeaderActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleSubHeaderText(e)}
                  placeholder="Enter Canvas Description"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : subHeaderText === "" ? (
              <span className="sub-title">Enter Canvas Description</span>
            ) : (
              <span className="sub-title">{subHeaderText}</span>
            )}
          </div> */}
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Title"
                  autoFocus
                  className="form-control heading"
                  onChange={(e) => {
                    setHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={headerText}
                />
              </div>
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Description"
                  className="form-control description"
                  onChange={(e) => {
                    setSubHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={subHeaderText}
                />
              </div>
            </div>
          </div>
          <div className="right-wrap d-flex">
            <div className="right-inner">
              <div className="mb-2">
                <button
                  type="button"
                  className="custom-dropdown-wrapper btn gray-bg"
                >
                  <span>Status:</span>
                  <Select
                    className="custom-select"
                    options={options}
                    value={status}
                    isSearchable={false}
                    onChange={(e) => handleStatusChange(e)}
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "transform 0.3s",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }),
                    }}
                  />
                </button>
              </div>
              <div className="d-flex mb-2">
                <button
                  type="button"
                  className="btn gray-bg mr-3"
                  onClick={() => handleSave()}
                  disabled={saveCanvasLoading}
                >
                  {canvasId === "" ? "Save" : "Update"}
                </button>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle className="gray-bg btn">
                    Other Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                <button
                // onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                >
                  Video
                </button>
              </Dropdown.Item> */}
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => printDocument()}
                      >
                        Download
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => {
                          canvasId === ""
                            ? showError("Please Save The Canvas")
                            : setShowShareModal(true);
                        }}
                      >
                        Share
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-inner">
          <div className="data-block-outer">
            {/* <div className="info-wrapper">
              <img src={info} alt="Info" />
              <span className="tooltiptext">
                Technology Stack Canvas provides the framework to add the set of
                technologies used to develop an application. It includes the
                programming languages, frameworks, databases, front-end and
                back-end tools, and APIs.
              </span>
            </div> */}
            <div className="data-block">
              <div className="grid-block two-col-grid tech-stack">
                <div className="row title-row">
                  <div className="left-col">
                    <div className="content-block">
                      <h3 className="title">TECH STAGE</h3>
                    </div>
                  </div>
                  <div className="right-col">
                    <div className="content-block">
                      <h3 className="title">SERVICES</h3>
                      <button
                        className="btn black-bg"
                        onClick={() => handleAddRow()}
                      >
                        Add Row
                      </button>
                    </div>
                  </div>
                </div>
                {data.userInterface && (
                  <div className="row">
                    <div className="left-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("keyUserInterface")}
                      >
                        {activeTab !== "keyUserInterface" &&
                          data.keyUserInterface.trim().length === 0 && (
                            <h4 className="title">User Interface</h4>
                          )}

                        {activeTab === "keyUserInterface" ? (
                          <div className="content">
                            <div className="list-wrapper">
                              <textarea
                                rows={4}
                                type="text"
                                onKeyUp={(e) =>
                                  handleChange(e, "keyUserInterface")
                                }
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          </div>
                        ) : (
                          data.keyUserInterface.trim().length !== 0 && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.keyUserInterface,
                              }}
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="right-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("userInterface")}
                      >
                        <div className="content">
                          <ol>
                            {activeTab !== "userInterface" &&
                              data.userInterface.length === 0 && (
                                <>
                                  {/* <p className="text mb-3">Click to Add</p> */}
                                  <div className="textArea-Common-background">
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Click to add"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Example :-"
                                      />
                                    </div>

                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Web Application - HTML, CSS, React"
                                      />
                                    </div>

                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Mobile Application - React, iOS, Kotlin"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {data.userInterface.length !== 0 &&
                              data.userInterface.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>

                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "userInterface")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "userInterface" && (
                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) =>
                                    handleChange(e, "userInterface")
                                  }
                                  autoFocus
                                  ref={inputRef}
                                />
                                {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                      <button
                        className="delete-row"
                        onClick={() => deleteRow("userInterface")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {data.database && (
                  <div className="row">
                    <div className="left-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("keyDatabase")}
                      >
                        {activeTab !== "keyDatabase" &&
                          data.keyDatabase.trim().length === 0 && (
                            <h4 className="title">Database</h4>
                          )}

                        {activeTab === "keyDatabase" ? (
                          <div className="content">
                            <div className="list-wrapper">
                              <textarea
                                rows={4}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "keyDatabase")}
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          </div>
                        ) : (
                          data.keyDatabase.trim().length !== 0 && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.keyDatabase,
                              }}
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="right-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("database")}
                      >
                        <div className="content">
                          <ol>
                            {activeTab !== "database" &&
                              data.database.length === 0 && (
                                <>
                                  <div className="textArea-Common-background">
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Click to add"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Example :-"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="MySQL"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {data.database.length !== 0 &&
                              data.database.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>

                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "database")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "database" && (
                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "database")}
                                  autoFocus
                                  ref={inputRef}
                                />
                                {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                      <button
                        className="delete-row"
                        onClick={() => deleteRow("database")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}

                {data.backend && (
                  <div className="row">
                    <div className="left-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("keyBackend")}
                      >
                        {activeTab !== "keyBackend" &&
                          data.keyBackend.trim().length === 0 && (
                            <h4 className="title">Backend</h4>
                          )}

                        {activeTab === "keyBackend" ? (
                          <div className="content">
                            <div className="list-wrapper">
                              <textarea
                                rows={4}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "keyBackend")}
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          </div>
                        ) : (
                          data.keyBackend.trim().length !== 0 && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.keyBackend,
                              }}
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="right-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("backend")}
                      >
                        <div className="content">
                          <ol>
                            {activeTab !== "backend" &&
                              data.backend.length === 0 && (
                                <>
                                  <div className="textArea-Common-background">
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Click to add"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Example :-"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Django, Python"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {data.backend.length !== 0 &&
                              data.backend.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>

                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "backend")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "backend" && (
                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "backend")}
                                  autoFocus
                                  ref={inputRef}
                                />
                                {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                      <button
                        className="delete-row"
                        onClick={() => deleteRow("backend")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {data.notification && (
                  <div className="row">
                    <div className="left-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("keyNotification")}
                      >
                        {activeTab !== "keyNotification" &&
                          data.keyNotification.trim().length === 0 && (
                            <h4 className="title">
                              {" "}
                              Notification &amp; Message
                            </h4>
                          )}

                        {activeTab === "keyNotification" ? (
                          <div className="content">
                            <div className="list-wrapper">
                              <textarea
                                rows={4}
                                type="text"
                                onKeyUp={(e) =>
                                  handleChange(e, "keyNotification")
                                }
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          </div>
                        ) : (
                          data.keyNotification.trim().length !== 0 && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.keyNotification,
                              }}
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="right-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("notification")}
                      >
                        <div className="content">
                          <ol>
                            {activeTab !== "notification" &&
                              data.notification.length === 0 && (
                                <>
                                  <div className="textArea-Common-background">
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Click to add"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Example :-"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Firebase"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Twilio"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Sendgrid"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {data.notification.length !== 0 &&
                              data.notification.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>

                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "notification")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "notification" && (
                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) =>
                                    handleChange(e, "notification")
                                  }
                                  autoFocus
                                  ref={inputRef}
                                />
                                {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                      <button
                        className="delete-row"
                        onClick={() => deleteRow("notification")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {data.Hosting && (
                  <div className="row">
                    <div className="left-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("keyHosting")}
                      >
                        {activeTab !== "keyHosting" &&
                          data.keyHosting.trim().length === 0 && (
                            <h4 className="title">
                              {" "}
                              Hosting &amp; Cloud Services
                            </h4>
                          )}

                        {activeTab === "keyHosting" ? (
                          <div className="content">
                            <div className="list-wrapper">
                              <textarea
                                rows={4}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "keyHosting")}
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          </div>
                        ) : (
                          data.keyHosting.trim().length !== 0 && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: data.keyHosting,
                              }}
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="right-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("Hosting")}
                      >
                        <div className="content">
                          <ol>
                            {activeTab !== "Hosting" &&
                              data.Hosting.length === 0 && (
                                <>
                                  <div className="textArea-Common-background">
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Click to add"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Example :-"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Amazon Web Services"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="New Relic &amp; Sentry"
                                      />
                                    </div>
                                    <div className="list-wrapper">
                                      <textarea
                                        rows={1}
                                        type="text"
                                        placeholder="Stripe"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {data.Hosting.length !== 0 &&
                              data.Hosting.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>

                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "Hosting")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "Hosting" && (
                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "Hosting")}
                                  autoFocus
                                  ref={inputRef}
                                />
                                {/* <button
                              className="delete-row"
                              onClick={() => setActiveTab("")}
                            >
                              +
                            </button> */}
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                      <button
                        className="delete-row"
                        onClick={() => deleteRow("Hosting")}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
                {addRow.length !== 0 &&
                  addRow.map((row, index) => (
                    <div className="row" key={index}>
                      <div className="left-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab(`key${index}`)}
                        >
                          <div className="content">
                            <h3 className="title">
                              {activeTab !== `key${index}` &&
                                row[Object.keys(row)[0]].length === 0 &&
                                "Additional Information"}

                              {activeTab !== `key${index}` &&
                                row[Object.keys(row)[0]].length !== 0 && (
                                  <div>
                                    <div className="list-wrapper">
                                      <div
                                        className="text-value"
                                        dangerouslySetInnerHTML={{
                                          __html: row[Object.keys(row)[0]],
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                )}

                              {activeTab === `key${index}` && (
                                <div className="list-wrapper">
                                  <textarea
                                    rows={4}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleAddRowChange(
                                        e,
                                        `key${index}`,
                                        index
                                      )
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                  {/* <button
                                  className="delete-row"
                                  onClick={() => {
                                    setActiveTab("");
                                  }}
                                >
                                  +
                                </button> */}
                                </div>
                              )}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="right-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab(`value${index}`)}
                        >
                          <div className="content">
                            <ol>
                              {activeTab !== `value${index}` &&
                                row[Object.keys(row)[1]].length === 0 && (
                                  // <li> Click to add </li>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}

                              {row[Object.keys(row)[1]].length !== 0 &&
                                row[Object.keys(row)[1]].map(
                                  (value, indexx) => (
                                    <div key={indexx}>
                                      <li>
                                        <div className="list-wrapper">
                                          <div
                                            className="text-value"
                                            dangerouslySetInnerHTML={{
                                              __html: value,
                                            }}
                                          ></div>

                                          <button
                                            className="delete-row"
                                            onClick={() =>
                                              handleDeleteRowChange(
                                                index,
                                                `value${index}`,
                                                indexx
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </li>
                                    </div>
                                  )
                                )}
                              {activeTab === `value${index}` && (
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleAddRowChange(
                                        e,
                                        `value${index}`,
                                        index
                                      )
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                  {/* <button
                                  className="delete-row"
                                  onClick={() => {
                                    setActiveTab("");
                                  }}
                                >
                                  +
                                </button> */}
                                </div>
                              )}
                            </ol>
                          </div>
                        </div>
                        <button
                          className="delete-row"
                          onClick={() => handleDeleteRow(index)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={canvasId}
            adminId={techData && techData.user_id._id}
          />
        )}

        {showDialogLeavingPage && (
          <DeleteConfirm
            handleDeleteClose={cancelNavigation}
            deleteRecord={confirmNavigation}
            msg={
              "There are some changes. If you proceed your changes will be lost"
            }
            icon={"error"}
          />
        )}
      </div>
    </>
  );
}

export default TechStack;
