export const UPDATE_RIDRER_AND_DRIVER = "UPDATE_RIDRER_AND_DRIVER";
export const UPDATE_RIDRER_AND_DRIVER_SUCCESS =
  "UPDATE_RIDRER_AND_DRIVER_SUCCESS";
export const UPDATE_RIDRER_AND_DRIVER_FAIL = "UPDATE_RIDRER_AND_DRIVER_FAIL";

export const UPDATE_90DAY_PLAN = "UPDATE_90DAY_PLAN";
export const UPDATE_90DAY_PLAN_SUCCESS = "UPDATE_90DAY_PLAN_SUCCESS";
export const UPDATE_90DAY_PLAN_FAIL = "UPDATE_90DAY_PLAN_FAIL";

export const UPDATE_TECH_STACK = "UPDATE_TECH_STACK";
export const UPDATE_TECH_STACK_SUCCESS = "UPDATE_TECH_STACK_SUCCESS";
export const UPDATE_TECH_STACK_FAIL = "UPDATE_TECH_STACK_FAIL";

export const UPDATE_TESLA_VALUE = "UPDATE_TESLA_VALUE";
export const UPDATE_TESLA_VALUE_SUCCESS = "UPDATE_TESLA_VALUE_SUCCESS";
export const UPDATE_TESLA_VALUE_FAIL = "UPDATE_TESLA_VALUE_FAIL";

export const UPDATE_VISION_BOARD = "UPDATE_VISION_BOARD";
export const UPDATE_VISION_BOARD_SUCCESS = "UPDATE_VISION_BOARD_SUCCESS";
export const UPDATE_VISION_BOARD_FAIL = "UPDATE_VISION_BOARD_FAIL";
