import {
  RIDRER_AND_DRIVER,
  RIDRER_AND_DRIVER_SUCCESS,
  RIDRER_AND_DRIVER_FAIL,
  DAY90_PLAN,
  DAY90_PLAN_SUCCESS,
  DAY90_PLAN_FAIL,
  TECH_STACK,
  TECH_STACK_SUCCESS,
  TECH_STACK_FAIL,
  TESLA_VALUE,
  TESLA_VALUE_SUCCESS,
  TESLA_VALUE_FAIL,
  VISION_BOARD,
  VISION_BOARD_SUCCESS,
  VISION_BOARD_FAIL,
} from "./actionTypes";

const initialState = {
  riderAndDriver: null,
  day90Plan: null,
  techStack: null,
  teslaValue: null,
  visionBoard: null,
  saveCanvasLoading: false,
  error: {
    message: "",
  },
};

const SaveCanvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case RIDRER_AND_DRIVER:
      state = {
        ...state,
        saveCanvasLoading: true,
      };
      break;
    case RIDRER_AND_DRIVER_SUCCESS:
      state = {
        ...state,
        saveCanvasLoading: false,
        riderAndDriver: action.payload,
      };
      break;
    case RIDRER_AND_DRIVER_FAIL:
      state = {
        ...state,
        riderAndDriver: null,
        error: {
          message: action.payload,
        },
        saveCanvasLoading: false,
      };
      break;

    case DAY90_PLAN:
      state = {
        ...state,
        saveCanvasLoading: true,
      };
      break;
    case DAY90_PLAN_SUCCESS:
      state = {
        ...state,
        saveCanvasLoading: false,
        day90Plan: action.payload,
      };
      break;
    case DAY90_PLAN_FAIL:
      state = {
        ...state,
        day90Plan: null,
        error: {
          message: action.payload,
        },
        saveCanvasLoading: false,
      };
      break;

    case TECH_STACK:
      state = {
        ...state,
        saveCanvasLoading: true,
      };
      break;
    case TECH_STACK_SUCCESS:
      state = {
        ...state,
        saveCanvasLoading: false,
        techStack: action.payload,
      };
      break;
    case TECH_STACK_FAIL:
      state = {
        ...state,
        techStack: null,
        error: {
          message: action.payload,
        },
        saveCanvasLoading: false,
      };
      break;

    case TESLA_VALUE:
      state = {
        ...state,
        saveCanvasLoading: true,
      };
      break;
    case TESLA_VALUE_SUCCESS:
      state = {
        ...state,
        saveCanvasLoading: false,
        teslaValue: action.payload,
      };
      break;
    case TESLA_VALUE_FAIL:
      state = {
        ...state,
        teslaValue: null,
        error: {
          message: action.payload,
        },
        saveCanvasLoading: false,
      };
      break;

    case VISION_BOARD:
      state = {
        ...state,
        saveCanvasLoading: true,
      };
      break;
    case VISION_BOARD_SUCCESS:
      state = {
        ...state,
        saveCanvasLoading: false,
        visionBoard: action.payload,
      };
      break;
    case VISION_BOARD_FAIL:
      state = {
        ...state,
        visionBoard: null,
        error: {
          message: action.payload,
        },
        saveCanvasLoading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SaveCanvasReducer;
