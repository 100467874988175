import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError } from "../../../Utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import { updateUserPassword } from "../../../helpers/backendHelper";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    const passData = { payload: data };

    await updateUserPassword(passData).then((res) => {
      if (res.code === 200) {
        showSuccess("Password updated Successfully");
        localStorage.clear();
        navigate("/admin");
      } else {
        showError(res.message);
      }
    });
  };

  return (
    <>
      <div className="custom-tabs-body update-pass">
        {/* <div className="header">
          <div className="header-inner">
            <div className="title-block d-flex direction-column align-items-center">
              <h2 className="text-center">Edit User Details</h2>
            </div>
          </div>
        </div> */}
        <div className="tabs-body-inner">
          <form onSubmit={handleSubmit(onSubmit)} className="pre-login-form">
            <div className="top-block">
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Old Password<em>*</em></label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Old Password"
                  {...register("old_password", {
                    required: "Old Password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Password length should be at least 8 characters",
                    },
                    maxLength: {
                      value: 12,
                      message: "Password cannot exceed more than 12 characters",
                    },
                  })}
                />
                <p className="error-message">{errors.old_password?.message}</p>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password<em>*</em></label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter New Password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Password length should be at least 8 characters",
                    },
                    maxLength: {
                      value: 12,
                      message: "Password cannot exceed more than 12 characters",
                    },
                  })}
                />
                <p className="error-message">{errors.password?.message}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Confirm Password<em>*</em></label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  id="Confirm Password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                />
                <p className="error-message">
                  {errors.confirmPassword?.message}
                </p>
              </div>
              <div className="d-flex justify-content-center my-3 mx-auto">
                <button type="submit" className="btn white-border">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
