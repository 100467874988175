import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ACTIVE_USER_LIST } from "../../../../store/login/actionTypes";
import { getActiveUserListSuccess } from "../../../../store/login/actions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import searchicon from "../../../../assets/images/search.png";
import DeleteConfirm from "../../../../views/common/deleteConfirm";
import {
  changeUserStatus,
  updateUserData,
} from "../../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import UserModel from "./userModel";

const UserList = () => {
  const dispatch = useDispatch();
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);

  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState({});
  const [search, setSearch] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeUserId, setActiveUserId] = useState("");

  const { activeUserList } = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    loadData();

    return () => dispatch(getActiveUserListSuccess(null));
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_ACTIVE_USER_LIST,
      payload: "active",
    });
  };

  useEffect(() => {
    if (activeUserList) {
      setUserData(getFilteredData(0));
      setTotalPage(Math.ceil(activeUserList.length / recordPerPage));
    }
  }, [activeUserList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (activeUserList[i]) list.push(activeUserList[i]);
    }
    return list;
  };

  const handlePageClick = (e) => {
    setUserData(getFilteredData(e.selected * recordPerPage));
  };

  const handleDeleteClose = () => setShowDeleteModal(false);

  const deleteRecord = async () => {
    handleDeleteClose();

    const statusPayload = {
      payload: {
        user_id: changeStatus.user_id,
        status: changeStatus.status === "active" ? "inactive" : "active",
      },
    };

    await changeUserStatus(statusPayload).then((res) => {
      if (res.code === 200) {
        showSuccess("status changed Successfully");
      } else {
        showError(res.message);
      }
      loadData();
    });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setUserData(getFilteredData(0));

      return;
    }
    setUserData(
      activeUserList.filter((canvas) => {
        return (
          canvas.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          canvas.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
          (canvas.contactNumber &&
            canvas.contactNumber
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
          // canvas.status.toLowerCase().includes(e.target.value.toLowerCase())
        );
      })
    );
  };
  const handleContacted = async (id, data) => {
    const connected = {
      payload: {
        user_id: id,
        value: { isConnected: !data },
      },
    };
    await updateUserData(connected).then((res) => {
      if (res.code === 200) {
        if (!data) showSuccess("User Contacted Successfully");
        else showSuccess("Not able To Conatct User");
        loadData();
      } else {
        showError(res.message);
      }
    });
  };

  return (
    <>
      <div className="nav-wrap">
        <div className="right-wrap margin-left-auto admin-seach">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            className="form-control"
            onChange={(e) => handleSearch(e)}
          />
          <img src={searchicon} alt="Logo" />
        </div>
      </div>
      <div className="admin-table-wrapper">
        <table className="table table-hover admin-table">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Created On</th>
              <th scope="col" className="text-center">
                Contacted
              </th>
              <th scope="col">Status</th>
              <th scope="col" className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {userData.length !== 0 ? (
              userData.map(
                (
                  {
                    name,
                    email,
                    contactNumber,
                    createdAt,
                    status,
                    _id,
                    isConnected,
                  },
                  index
                ) => (
                  <tr key={index} className="pointer">
                    {/* <th>{index + 1}</th> */}
                    <td
                      onClick={() => {
                        setShowShareModal(true);
                        setActiveUserId(_id);
                      }}
                    >
                      {name}
                    </td>
                    <td
                      onClick={() => {
                        setShowShareModal(true);
                        setActiveUserId(_id);
                      }}
                    >
                      {email}
                    </td>
                    <td
                      onClick={() => {
                        setShowShareModal(true);
                        setActiveUserId(_id);
                      }}
                    >
                      {contactNumber}
                    </td>
                    <td
                      onClick={() => {
                        setShowShareModal(true);
                        setActiveUserId(_id);
                      }}
                    >
                      {moment(createdAt).format("Do MMMM YYYY")}
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={isConnected}
                        onChange={() => handleContacted(_id, isConnected)}
                      />
                    </td>
                    <td
                      onClick={() => {
                        setShowShareModal(true);
                        setActiveUserId(_id);
                      }}
                    >
                      {status}
                    </td>
                    <td className="text-center">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input ml-0"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={status === "active" ? false : true}
                          onChange={() => {
                            setShowDeleteModal(true);
                            setChangeStatus({
                              user_id: _id,
                              status,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        ></label>
                      </div>
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "50px" }}
                >
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalPage > 1 && search === "" && (
        <div className="my-pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={totalPage}
            previousLabel="Prev "
            renderOnZeroPageCount={null}
          />
        </div>
      )}

      {showDeleteModal && (
        <DeleteConfirm
          handleDeleteClose={handleDeleteClose}
          deleteRecord={deleteRecord}
          msg={"Are you sure you want to inactive this user ?"}
          icon={"info"}
        />
      )}
      {showShareModal && (
        <UserModel
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
          userId={activeUserId}
        />
      )}
    </>
  );
};

export default UserList;
