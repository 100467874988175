import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_USER_PROFILE,
  GET_COUNTRY_LIST,
} from "../../../store/login/actionTypes";
import { updateUserProfileSuccess } from "../../../store/login/actions";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import { deleteAccount } from "../../../helpers/backendHelper";
import DeleteConfirm from "../../common/deleteConfirm";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("data"));
  const {
    email,
    name,
    contactNumber,
    address,
    city,
    state,
    country,
    postCode,
  } = userData;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email,
      name,
      contactNumber,
      address,
      state,
      country,
      city,
      postCode,
    },
  });
  document.title = "Edit User | ATW";

  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState(state);
  const [countryName, setCountryName] = useState(country);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { updateUser, countryList, loadingLogins } = useSelector(
    (state) => state.LoginReducer
  );
  const onSubmit = (data) => {
    userData.contactNumber = data.contactNumber;
    userData.name = data.name;
    userData.country = data.country;
    localStorage.setItem("data", JSON.stringify(userData));

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: data,
    });
  };

  useEffect(() => {
    if (!countryList) {
      dispatch({
        type: GET_COUNTRY_LIST,
      });
    } else {
      if (country && countryList) {
        countryList.find((list) => {
          if (list.country == country) {
            setStateList(list.states);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (country && countryList) {
      countryList.find((list) => {
        if (list.country == country) {
          setStateList(list.states);
        }
      });
    }
  }, [countryList]);

  // useEffect(() => {
  //   if (updateUser) {
  //     showSuccess("User updated Successfully");
  //     dispatch(updateUserProfileSuccess(null));
  //   }
  // }, [updateUser]);

  const getStateList = (e) => {
    setCountryName(e.target.value);
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];

    let jobTypeParentId = optionElement.getAttribute("data-id")
      ? optionElement.getAttribute("data-id")
      : "";
    if (jobTypeParentId !== "") {
      setStateList(countryList[jobTypeParentId].states);
    }
  };

  const handleDeleteClose = () => setShowDeleteModal(false);

  const deleteRecord = async () => {
    handleDeleteClose();
    await deleteAccount().then((res) => {
      if (res.code === 200) {
        showSuccess("Account Deleted Successfully");
        navigate("/");
      } else showError(res.message);
    });
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="custom-tabs-body update-profile">
        <div className="tabs-body-inner">
          <form onSubmit={handleSubmit(onSubmit)} className="pre-login-form">
            <div className="top-block">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
                <p className="error-message">{errors.name?.message}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Contact Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="contact number"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Contact Number"
                  {...register("contactNumber", {
                    pattern: {
                      value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                      message: "Please enter a valid Contact Number",
                    },
                  })}
                />
                <p className="error-message">{errors.contactNumber?.message}</p>
              </div>
              <div className="form-group  ">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="text"
                  className="form-control not-allowed"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  disabled
                  placeholder="Enter email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                <p className="error-message">{errors.email?.message}</p>
              </div>
              {/* <div className="form-group address">
                <label htmlFor="exampleInputEmail1">Street Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Address"
                  {...register("address", {
                    required: "address is required",
                  })}
                />
                <p className="error-message">{errors.address?.message}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Suburb</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your City"
                  {...register("city", {
                    required: "City is required",
                  })}
                />
                <p className="error-message">{errors.city?.message}</p>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="Postal Code"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Post Code"
                  {...register("postCode", {
                    required: "Postal Code is required",
                  })}
                />
                <p className="error-message">{errors.postCode?.message}</p>
              </div> */}
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Country</label>

                <select
                  className="form-select"
                  {...register("country", {
                    required: "country is required",
                  })}
                  value={countryName}
                  onChange={(e) => getStateList(e)}
                >
                  <option value="" data-id="">
                    Select Country
                  </option>
                  {countryList &&
                    countryList.length !== 0 &&
                    countryList.map(({ country }, index) => (
                      <option value={country} key={index} data-id={index}>
                        {country}
                      </option>
                    ))}
                </select>

                <p className="error-message">{errors.country?.message}</p>
              </div>

              <div className="d-flex justify-content-center my-3 mx-auto">
                <div className="two-btn-wrap">
                  <button
                    type="button"
                    className="btn white-border"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Account
                  </button>

                  <button type="submit" className="btn white-border">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {showDeleteModal && (
          <DeleteConfirm
            handleDeleteClose={handleDeleteClose}
            deleteRecord={deleteRecord}
            msg={"Are you sure you want to Delete the Account?"}
            icon={"info"}
          />
        )}
      </div>
    </>
  );
};

export default SignUp;
