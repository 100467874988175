import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_LOGIN } from "../../../store/login/actionTypes";
import { getLoginSuccess } from "../../../store/login/actions";
import { useForm } from "react-hook-form";
import PreLoginLeftBlock from "./PreLoginLeftBlock";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../helpers/backendHelper";
import { showError } from "../../../Utils/alertMessage";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";
import Loader from "../../common/loader";
import passwordHide from "../../../assets/images/password-hide.svg";
import passwordShow from "../../../assets/images/password-show.svg";


const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  document.title = "Login | ATW";

  const onSubmit = async (data) => {
    // localStorage.setItem(
    //   "data",
    //   JSON.stringify({
    //     email: "test@gmail.com",
    //     password: "test123",
    //     token: "token",
    //   })
    // );
    // navigate("/dashboard");
    // dispatch({
    //   type: GET_LOGIN,
    //   payload: data,
    // });
    setIsLoading(true);

    const loginData = {
      payload: data,
    };
    await login(loginData).then(async (res) => {
      if (res.code === 200) {
        await dispatch(getLoginSuccess(res.data));
        await localStorage.setItem("data", JSON.stringify(res.data));
        navigate("/dashboard");
        setIsLoading(false);
      } else {
        showError(res.message);
        setIsLoading(false);

        if (res.data === "pending") {
          navigate("/payment", { state: { email: watch("email") } });
        }
      }
    });
  };

  // useEffect(() => {
  //   const getUserData = async () => {
  //     await localStorage.setItem("data", JSON.stringify(userData));
  //     // await dispatch(getLoginSuccess(null));
  //     navigate("/dashboard");
  //   };
  //   // if (userData && userData.length !== 0) {
  //   //   getUserData();
  //   // }
  // }, [userData]);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  return (
    <>
      {isLoading && <Loader />}

      <div className="wrapper">
        <div className="main-wrap login-page">
          <div className="prelogin-outer-wrap">
            <div className="img-form-section">
              <div className="bg-img">
                <img src={binaryCodeBg} alt="Background Img" />
              </div>
              <div className="container">
                <div className="row-wrap">
                  <PreLoginLeftBlock />
                  <div className="right-content-wrap pt-5">
                    <div className="title-block">
                      <h1 className="title">Log In</h1>
                      <span className="sub-title">
                        Please sign in to continue
                      </span>
                    </div>
                    <div className="right-content-inner">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="pre-login-form"
                      >
                        <div className="top-block">
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Email<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.email?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputPassword1"
                            >
                              Password<span className="required">*</span>
                            </label>
                            <div className="password-wrap">
                              <input
                                type={passwordType}                                
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter Password"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password length should be at least 8 characters",
                                  },
                                })}
                              />
                              <span className="show-hide-password" onClick={togglePassword}>
                                {passwordType === "password" ? <img src={passwordHide} alt="password hide" /> : <img src={passwordShow} alt="password show" />}
                              </span>
                            </div>
                            <p className="error-message">
                              {errors.password?.message}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center btn-wrap">
                            <button
                              type="submit"
                              className="btn transparent-bg"
                            >
                              Sign In
                            </button>
                          </div>
                          <div className="d-flex justify-content-center link-wrap">
                            <Link
                              to="/forgot-password"
                              className="primary-link"
                            >
                              Forgot Your Password?
                            </Link>
                          </div>
                        </div>
                        <div className="text-link-wrap">
                          <p className="content">Don't have an Account?</p>
                          <Link to="/signup" className="primary-link">
                            Sign up
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
