import Loadable from "react-loadable";

import { Loading } from "../../views/common/navigation";

const NeedHelpList = Loadable({
  loader: () => import("./components/NeedHelpList"),
  loading: Loading,
});

export const routes = [
  {
    path: "/admin/need-help",
    exact: true,
    name: "NeedHelpList",
    element: NeedHelpList,
  },
];

export * from "./components";
