import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBar from "../adminView/common/SideBar";

const AdminProtected = ({ Component }) => {
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  let userData = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("data")));
  }, [userData]);
  if (JSON.parse(localStorage.getItem("data")) === null) {
    navigate("/admin");
  }

  return (
    <>
      {user && user.status === "active" && user.role === "admin" && (
        <div className="main-wrap">
          <SideBar />
          <Component />
        </div>
      )}
    </>
  );
};

export default AdminProtected;
