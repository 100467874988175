import Loadable from "react-loadable";

import { Loading } from "../common/navigation";

const RidersAndDrivers = Loadable({
  loader: () => import("./components/RidersAndDrivers/index"),
  loading: Loading,
});

const EditRidersAndDrivers = Loadable({
  loader: () => import("./components/RidersAndDrivers/EditRidersAndDreivers"),
  loading: Loading,
});

const DaysPlane90 = Loadable({
  loader: () => import("./components/90DaysPlan/index"),
  loading: Loading,
});

const EditDaysPlane90 = Loadable({
  loader: () => import("./components/90DaysPlan/Edit90DayPlan"),
  loading: Loading,
});

const TechStack = Loadable({
  loader: () => import("./components/TechStack/index"),
  loading: Loading,
});

const EditTechStack = Loadable({
  loader: () => import("./components/TechStack/EditTechStack"),
  loading: Loading,
});

const TeslaValueCanvas = Loadable({
  loader: () => import("./components/TeslaValueCanvas/index"),
  loading: Loading,
});

const EditTeslaValueCanvas = Loadable({
  loader: () => import("./components/TeslaValueCanvas/EditTeslaValueCanvas"),
  loading: Loading,
});

const VisionBoard = Loadable({
  loader: () => import("./components/VisionBoard/index"),
  loading: Loading,
});

const EditVisionBoard = Loadable({
  loader: () => import("./components/VisionBoard/EditVisionBoard"),
  loading: Loading,
});

const CreateCanvas = Loadable({
  loader: () => import("./components/CreateCanvas"),
  loading: Loading,
});

export const routes = [
  {
    path: "/canvas/atw-startup/:id",
    exact: true,
    name: "RidersAndDrivers",
    element: RidersAndDrivers,
  },
  {
    path: "/edit/canvas/atw-startup/:id",
    exact: true,
    name: "EditRidersAndDrivers",
    element: EditRidersAndDrivers,
  },
  {
    path: "/canvas/90-days-plane/:id",
    exact: true,
    name: "DaysPlane90",
    element: DaysPlane90,
  },

  {
    path: "/edit/canvas/90-days-plane/:id",
    exact: true,
    name: "EditDaysPlane90",
    element: EditDaysPlane90,
  },

  {
    path: "/canvas/tech-stack/:id",
    exact: true,
    name: "TechStack",
    element: TechStack,
  },

  {
    path: "/edit/canvas/tech-stack/:id",
    exact: true,
    name: "EditTechStack",
    element: EditTechStack,
  },

  {
    path: "/canvas/value-proposition/:id",
    exact: true,
    name: "TeslaValueCanvas",
    element: TeslaValueCanvas,
  },

  {
    path: "/edit/canvas/value-proposition/:id",
    exact: true,
    name: "EditTeslaValueCanvas",
    element: EditTeslaValueCanvas,
  },
  {
    path: "/canvas/vision-board/:id",
    exact: true,
    name: "VisionBoard",
    element: VisionBoard,
  },
  {
    path: "/edit/canvas/vision-board/:id",
    exact: true,
    name: "EditVisionBoard",
    element: EditVisionBoard,
  },
  {
    path: "/create/canvas",
    exact: true,
    name: "CreateCanvas",
    element: CreateCanvas,
  },
];

export * from "./components";
