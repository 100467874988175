import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_CANVAS_BY_STATUS } from "../../../store/canvas/actionTypes";
import moment from "moment";
import ReactPaginate from "react-paginate";
import MailModal from "./MailModal";
import Loader from "../../../views/common/loader";
import searchicon from "../../../assets/images/search.png";

const NeedHelpList = () => {
  const dispatch = useDispatch();
  const recordPerPage = Number(process.env.REACT_APP_RECORD_PER_PAGE);

  const [totalPage, setTotalPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [modalSelected, setModalSelected] = useState({
    id: "",
    name: "",
  });
  const [showMailModal, setShowMailModal] = useState(false);
  const [sendMailTo, setSendMailTo] = useState({
    mailTo: "",
    subject: "",
    message: "",
    userName: "",
    canvasName: "",
    title: "",
  });

  const { allCanvasByStatus, canvasLoading } = useSelector(
    (state) => state.canvas
  );

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch({
      type: GET_ALL_CANVAS_BY_STATUS,
      payload: "need_help",
    });
  };

  const canvasList = useMemo(() => {
    let list = [];
    if (allCanvasByStatus) {
      for (const property in allCanvasByStatus) {
        if (allCanvasByStatus[property].length !== 0) {
          allCanvasByStatus[property].map((data) => list.push(data));
        }
      }
      setTotalPage(Math.ceil(list.length / recordPerPage));

      return list;
    }
  }, [allCanvasByStatus]);

  useEffect(() => {
    if (canvasList) {
      setUserData(getFilteredData(0));
      setAllUserData(getFilteredData(0));
    }
  }, [canvasList]);

  const getFilteredData = (start) => {
    let end = start + recordPerPage;
    let list = [];
    for (let i = start; i < end; i++) {
      if (canvasList[i]) list.push(canvasList[i]);
    }
    return list;
  };

  const handlePageClick = (e) => {
    setUserData(getFilteredData(e.selected * recordPerPage));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    let data = [];
    allUserData.filter((canvas) => {
      if (
        canvas.user_id.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        canvas.user_id.email
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (canvas.user_id.contactNumber &&
          canvas.user_id.contactNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase())) ||
        canvas.modal_id.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        data.push(canvas);
      }
    });

    setUserData(data);
  };

  const handleClose = () => setShowMailModal(false);

  return (
    <>
      {(isLoading || canvasLoading) && <Loader />}
      <div className="right-content-wrap admin-right-wrap">
        <div className="title-block">
          <h1 className="title">Need Help List</h1>
        </div>
        <div className="admin-right-sec">
          <div className="nav-wrap">
            <div className="right-wrap margin-left-auto admin-seach">
              <input
                type="text"
                placeholder="Search Canvas"
                value={search}
                className="form-control"
                onChange={(e) => handleSearch(e)}
              />
              <img src={searchicon} alt="Logo" />
            </div>
          </div>
          <div className="admin-table-wrapper">
            <table className="table table-hover admin-table">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Canvas Name</th>
                  <th scope="col">Updated On</th>
                  <th scope="col">Mail Sent</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userData.length !== 0 ? (
                  userData.map(
                    (
                      { user_id, modal_id, updatedAt, _id, isMailSend, title },
                      index
                    ) => (
                      <tr key={index}>
                        <td>{user_id.name}</td>
                        <td>{user_id.email}</td>
                        <td>{user_id.contactNumber}</td>
                        <td>{modal_id.name}</td>

                        <td>{moment(updatedAt).format("Do MMMM YYYY")}</td>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isMailSend}
                          />
                        </td>

                        <td>
                          <button
                            className="send-mail"
                            onClick={() => {
                              setShowMailModal(true);
                              setModalSelected({
                                id: _id,
                                name: modal_id.name,
                              });
                              setSendMailTo({
                                ...sendMailTo,
                                mailTo: user_id.email,
                                userName: user_id.name,
                                canvasName: modal_id.name,
                                title,
                              });
                            }}
                          >
                            Send Mail
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      style={{ textAlign: "center", paddingTop: "50px" }}
                    >
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {totalPage > 1 && search === "" && (
          <div className="my-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={totalPage}
              previousLabel="Prev "
              renderOnZeroPageCount={null}
            />
          </div>
        )}

        <MailModal
          show={showMailModal}
          handleClose={handleClose}
          sendMailTo={sendMailTo}
          modalSelected={modalSelected}
          setSendMailTo={setSendMailTo}
          loadData={loadData}
          setIsloading={setIsloading}
        />
      </div>
    </>
  );
};

export default NeedHelpList;
