import React, { useState, useRef, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import { TESLA_VALUE } from "../../../../store/saveCanvas/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { UserId } from "../../../../Utils/getUserId";
import { teslaValueSuccess } from "../../../../store/saveCanvas/actions";
import { UPDATE_TESLA_VALUE } from "../../../../store/updateCanvas/actionTypes";
import { updateTeslaValueSuccess } from "../../../../store/updateCanvas/actions";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../../common/sharedCanvasModal";
import Loader from "../../../common/loader";
import { useNavigatingAway } from "../../../../Utils/useNavigatingAway";
import DeleteConfirm from "../../../common/deleteConfirm";
import PropositionIcon from "../../../../assets/images/proposition.png";

const options = [
  { value: "start", label: "Started" },
  { value: "parked", label: "Parked" },
  { value: "need_help", label: "Need Help" },
  { value: "in_prodution", label: "In Production" },
];

function TeslaValueCanvas({ teslaData }) {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [activeTab, setActiveTab] = useState("");
  const [status, setStatus] = useState({ value: "start", label: "Started" });
  const [data, SetData] = useState({
    customer: [],
    product: [],
    pains: [],
    painRelievers: [],
    gains: [],
    gainsCreators: [],
  });
  const [canvasId, setCanvasId] = useState("");
  const [headerActive, setHeaderActive] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [subHeaderActive, setSubHeaderActive] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);

  let { teslaValue, saveCanvasLoading } = useSelector(
    (state) => state.SaveCanvasReducer
  );
  let { updateTeslaValue } = useSelector((state) => state.UpdateCanvas);
  let { loadingLogins } = useSelector((state) => state.GetCanvas);

  document.title = "Value Proposition | ATW";

  const handleChange = (e, tab) => {
    setCanShowDialogLeavingPage(true);
    let tabValue = data[tab];
    if (e.key === "Enter") {
      if (e.target.value.trim() === "") {
        showError("Value Can't Be Empty");
        return;
      }
      tabValue.push(e.target.value);
      inputRef.current.value = "";
    }
    SetData({ ...data, [tab]: tabValue });
  };

  const handleDelete = (index, tab) => {
    let tabValue = data[tab];

    tabValue.splice(index, 1);

    SetData({ ...data, tab: tabValue });
  };

  useEffect(() => {
    if (teslaData) {
      SetData({
        customer: teslaData.customer_jobs,
        product: teslaData.product_and_services,
        pains: teslaData.pains,
        painRelievers: teslaData.pain_relievers,
        gains: teslaData.gain,
        gainsCreators: teslaData.gain_creators,
      });
      setCanvasId(teslaData._id);
      setStatus(
        options.find((option) => {
          if (option.value === teslaData.model_status) return option;
        })
      );
      setHeaderText(teslaData.title);
      setSubHeaderText(teslaData.sub_title);
    }
  }, [teslaData]);

  const printDocument = async () => {
    handleSave("pdf");
  };

  const handleSave = (checkPdf) => {
    setCanShowDialogLeavingPage(false);
    if (headerText === "") {
      showError("Enter Canvas Title");
      return;
    }
    if (subHeaderText === "") {
      showError("Enter Canvas Description");
      return;
    }
    if (checkValidation()) {
      showError("Canvas can't be Empty");
    } else {
      if (checkPdf === "pdf") setGeneratePdf(true);
      let payload = {
        user_id: UserId(),
        modal_id: id,
        title: headerText,
        sub_title: subHeaderText,
        gain: data.gains,
        gain_creators: data.gainsCreators,
        pains: data.pains,
        pain_relievers: data.painRelievers,
        customer_jobs: data.customer,
        product_and_services: data.product,
        model_status: status.value,
        completed: canvasCompleted(),
      };

      if (canvasId === "") {
        dispatch({
          type: TESLA_VALUE,
          payload,
        });
      } else {
        payload.modal_id = teslaData.modal_id.id;
        payload["id"] = canvasId;
        delete payload.user_id;
        dispatch({
          type: UPDATE_TESLA_VALUE,
          payload,
        });
      }
    }
  };

  useEffect(() => {
    if (teslaValue) {
      if (generatePdf) {
        if (generatePdf) {
          if (window.innerWidth <= 768) {
            window.location.assign(
              `${process.env.REACT_APP_APP_URL}/tesla_value/pdf/${teslaValue._id}`,
              "_blank"
            );
          } else {
            window.open(
              `${process.env.REACT_APP_APP_URL}/tesla_value/pdf/${teslaValue._id}`,
              "_blank"
            );
          }
        }
        setGeneratePdf(false);
      } else {
        if (canvasId === "") {
          setCanvasId(teslaValue._id);
          showSuccess("Canvas Saved Successfully");
          navigate(`/edit/canvas/value-proposition/${teslaValue._id}`);
        }
      }
      dispatch(teslaValueSuccess(null));
    }
  }, [teslaValue]);

  useEffect(() => {
    if (updateTeslaValue) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/tesla_value/pdf/${canvasId}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/tesla_value/pdf/${canvasId}`,
            "_blank"
          );
        }
        setGeneratePdf(false);
      } else {
        showSuccess("Canvas Updated Successfully");
      }
      dispatch(updateTeslaValueSuccess(null));
    }
  }, [updateTeslaValue]);

  const checkValidation = () => {
    if (
      data.customer.length === 0 &&
      data.product.length === 0 &&
      data.pains.length === 0 &&
      data.painRelievers.length === 0 &&
      data.gains.length === 0 &&
      data.gainsCreators.length === 0
    )
      return true;
  };

  const canvasCompleted = () => {
    let partialValue = 0;
    for (const property in data) {
      if (data[property].length !== 0) partialValue += 1;
    }

    return Math.round((100 * partialValue) / Object.keys(data).length);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        headerText === "" && showError("Heading Can't Be Empty");
        setHeaderActive(false);
        return;
      }
      setHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setHeaderText(e.target.value);
  };

  const handleSubHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        subHeaderText === "" && showError("Description Can't Be Empty");
        setSubHeaderActive(false);
        return;
      }
      setSubHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setSubHeaderText(e.target.value);
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block purple-bg">
          <div className="title-inner">
            <div className="left-block">
              <i className="icon">
                <img src={PropositionIcon} alt="Value Proposition" />
              </i>
              <h2 className="title">Value Proposition</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>

              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>Value Proposition Model Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>Use the editable text fields to create your Model.</p>
                    <p className="mt-3">
                      Use your mouse cursor to click on the various grey boxes
                      to edit the Model Title, Model Description and each of the
                      key information sections in the model itself.
                    </p>

                    <p className="mt-3">
                      Once finished, click{" "}
                      <span className="gray-btn">Save</span>, and then click
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      to open the drop down menu. You can then choose whether to
                      Share or download. Clicking download will open the filled
                      model in a PDF for you to save to your computer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-inner" onClick={() => setHeaderActive(true)}>
            {headerActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleHeaderText(e)}
                  placeholder="Enter Canvas Heading"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : headerText === "" ? (
              <h1 className="title">Enter Canvas Heading</h1>
            ) : (
              <h1 className="title">{headerText}</h1>
            )}
          </div>
          <div className="title-inner" onClick={() => setSubHeaderActive(true)}>
            {subHeaderActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleSubHeaderText(e)}
                  placeholder="Enter Canvas Description"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : subHeaderText === "" ? (
              <span className="sub-title">Enter Canvas Description</span>
            ) : (
              <span className="sub-title">{subHeaderText}</span>
            )}
          </div> */}
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Title"
                  autoFocus
                  className="form-control heading"
                  onChange={(e) => {
                    setHeaderText(e.target.value);

                    setCanShowDialogLeavingPage(true);
                  }}
                  value={headerText}
                />
              </div>
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Description"
                  className="form-control description"
                  onChange={(e) => {
                    setSubHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={subHeaderText}
                />
              </div>
            </div>
          </div>
          <div className="right-wrap d-flex">
            <div className="right-inner">
              <div className="mb-2">
                <button
                  type="button"
                  className="custom-dropdown-wrapper btn gray-bg"
                >
                  <span>Status:</span>
                  <Select
                    className="custom-select"
                    options={options}
                    value={status}
                    isSearchable={false}
                    onChange={(e) => handleStatusChange(e)}
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "transform 0.3s",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }),
                    }}
                  />
                </button>
              </div>
              <div className="d-flex mb-2">
                <button
                  type="button"
                  className="btn gray-bg mr-3"
                  onClick={() => handleSave()}
                  disabled={saveCanvasLoading}
                >
                  {canvasId === "" ? "Save" : "Update"}
                </button>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle className="gray-bg btn">
                    Other Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                <button
                // onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                >
                  Video
                </button>
              </Dropdown.Item> */}
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => printDocument()}
                      >
                        Download
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => {
                          canvasId === ""
                            ? showError("Please Save The Canvas")
                            : setShowShareModal(true);
                        }}
                      >
                        Share
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-inner">
          <div className="data-block-outer">
            {/* <div className="info-wrapper">
              <img src={info} alt="Info" />
              <span className="tooltiptext">
                Value Proposition Canvas is a framework which can help ensure
                that a product / service is positioned around what the customer
                values and needs.
              </span>
            </div> */}
            <div className="data-block">
              <div className="grid-block two-col-grid tesla-value">
                <div className="row title-row">
                  <div className="left-col">
                    <div className="content-block">
                      <h3 className="title">CUSTOMER PROFILE</h3>
                    </div>
                  </div>
                  <div className="right-col">
                    <div className="content-block">
                      <h3 className="title">VALUE PROPOSITION</h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="left-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("customer")}
                    >
                      <h4 className="title">CUSTOMER JOBS</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "customer" &&
                            data.customer.length === 0 && (
                              <>
                                <div className="textArea-Common-background">
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Example :-"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Be different"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Show success"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Durability"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Drive an environment friendly vehicle"
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {data.customer.length !== 0 &&
                            data.customer.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "customer")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "customer" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "customer")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("product")}
                    >
                      <h4 className="title">PRODUCT &amp; SERVICES</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "product" &&
                            data.product.length === 0 && (
                              <>
                                <div className="textArea-Common-background">
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Example :-"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Smart touch screen provided in car to customise settings based on user profile"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="8 year battery warranty"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Online forums and Webinars to educate new buyers"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="36 months free roadside assistance"
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {data.product.length !== 0 &&
                            data.product.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "product")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "product" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "product")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="left-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("pains")}
                    >
                      <h4 className="title">PAINS</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "pains" && data.pains.length === 0 && (
                            <>
                              <div className="textArea-Common-background">
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Click to add"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Example :-"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Lack of charging stations"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Fear of dead battery"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder=" A lot of time is spent driving"
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {data.pains.length !== 0 &&
                            data.pains.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "pains")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "pains" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "pains")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("painRelievers")}
                    >
                      <h4 className="title">PAIN RELIEVERS</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "painRelievers" &&
                            data.painRelievers.length === 0 && (
                              <>
                                <div className="textArea-Common-background">
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Example :-"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Option to find nearby charging networks on touch screen"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Option to do a quick charge while on longer routes"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Option to self drive"
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {data.painRelievers.length !== 0 &&
                            data.painRelievers.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "painRelievers")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "painRelievers" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) =>
                                  handleChange(e, "painRelievers")
                                }
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="left-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("gains")}
                    >
                      <h4 className="title">GAINS</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "gains" && data.gains.length === 0 && (
                            <>
                              <div className="textArea-Common-background">
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Click to add"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Example :-"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="High range Battery with longer life"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="A diverse charging network"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Quick charge options"
                                  />
                                </div>
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Buying from a revolutionary brand"
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {data.gains.length !== 0 &&
                            data.gains.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "gains")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "gains" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "gains")}
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="right-col">
                    <div
                      className="content-block"
                      onClick={() => setActiveTab("gainsCreators")}
                    >
                      <h4 className="title">GAINS CREATORS</h4>
                      <div className="content">
                        <ol>
                          {activeTab !== "gainsCreators" &&
                            data.gainsCreators.length === 0 && (
                              <>
                                <div className="textArea-Common-background">
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Example :-"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Brand Image"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="High Tech car design"
                                    />
                                  </div>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Zero emissions and noise"
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                          {data.gainsCreators.length !== 0 &&
                            data.gainsCreators.map((value, index) => (
                              <div key={index}>
                                <li>
                                  <div className="list-wrapper">
                                    <div
                                      className="text-value"
                                      dangerouslySetInnerHTML={{
                                        __html: value,
                                      }}
                                    ></div>

                                    <button
                                      className="delete-row"
                                      onClick={() =>
                                        handleDelete(index, "gainsCreators")
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </li>
                              </div>
                            ))}
                          {activeTab === "gainsCreators" && (
                            <div className="list-wrapper">
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) =>
                                  handleChange(e, "gainsCreators")
                                }
                                autoFocus
                                ref={inputRef}
                              />
                              {/* <button
                                    className="delete-row"
                                    onClick={() => {
                                      setActiveTab("");
                                    }}
                                  >
                                    +
                                  </button> */}
                            </div>
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={canvasId}
            adminId={teslaData && teslaData.user_id._id}
          />
        )}
        {showDialogLeavingPage && (
          <DeleteConfirm
            handleDeleteClose={cancelNavigation}
            deleteRecord={confirmNavigation}
            msg={
              "There are some changes. If you proceed your changes will be lost"
            }
            icon={"error"}
          />
        )}
      </div>
    </>
  );
}

export default TeslaValueCanvas;
