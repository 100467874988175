import {
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAIL,
  GET_USER_CARD_DETAILS,
  GET_USER_CARD_DETAILS_SUCCESS,
  GET_USER_CARD_DETAILS_FAIL,
} from "./actionTypes";

export const getUserSubscription = () => {
  return {
    type: GET_USER_SUBSCRIPTION,
  };
};

export const getUserSubscriptionSuccess = (data) => {
  return {
    type: GET_USER_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

export const getUserSubscriptionFail = (error) => {
  return {
    type: GET_USER_SUBSCRIPTION_FAIL,
    payload: error,
  };
};

export const getUserCardDetails = () => {
  return {
    type: GET_USER_CARD_DETAILS,
  };
};

export const getUserCardDetailsSuccess = (data) => {
  return {
    type: GET_USER_CARD_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getUserCardDetailsFail = (error) => {
  return {
    type: GET_USER_CARD_DETAILS_FAIL,
    payload: error,
  };
};
