import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FORGET_PASSWORD } from "../../../store/login/actionTypes";
import { forgetPasswordSuccess } from "../../../store/login/actions";
import { showSuccess } from "../../../Utils/alertMessage";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";
import Loader from "../../common/loader";
import atwLogoHeader from "../../../assets/images/logo-large.png";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  document.title = "Forget Password | ATW";

  const { forgetPassword, loadingLogins } = useSelector(
    (state) => state.LoginReducer
  );

  const onSubmit = (data) => {
    dispatch({
      type: FORGET_PASSWORD,
      payload: data,
    });
  };

  useEffect(() => {
    if (forgetPassword) {
      showSuccess("Reset Link has been send to your mail Id");
      navigate("/");
      dispatch(forgetPasswordSuccess(null));
    }
  }, [forgetPassword]);

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="wrapper">
        <div className="main-wrap login-page">
          <div className="prelogin-outer-wrap">
            <div className="img-form-section">
              <div className="bg-img">
                <img src={binaryCodeBg} alt="Background Img" />
              </div>
              <div className="container">
                <div className="row-wrap small-width">
                  <div className="right-content-wrap">
                    <div className="title-block">
                      <h1 className="title">Log In</h1>
                      <span className="sub-title">Password Reset</span>
                    </div>
                    <div className="right-content-inner">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="pre-login-form"
                      >
                        <div className="top-block">
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Email<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Please enter email id"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.email?.message}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center btn-wrap">
                            <button
                              type="button"
                              className="btn transparent-bg"
                              onClick={() => navigate("/")}
                            >
                              Back to Log In
                            </button>
                            <button
                              type="submit"
                              className="btn transparent-bg"
                            >
                              Send Reset
                            </button>
                          </div>
                          <div className="logo-wrap-bottom">
                            <i>
                              <img src={atwLogoHeader} alt="Logo" />
                            </i>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
