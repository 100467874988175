// import React from "react";
// import editIcon from "../../../assets/images/edit.svg";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_USER_SUBSCRIPTION,
  GET_USER_CARD_DETAILS,
} from "../../../store/subscription/actionTypes";
import {
  getUserSubscriptionSuccess,
  getUserCardDetailsSuccess,
} from "../../../store/subscription/actions";
import moment from "moment";
import DeleteConfirm from "../../common/deleteConfirm";
import {
  cancelStripePayment,
  getUserById,
} from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import ChangeCard from "./ChangeCard";
import { UserId } from "../../../Utils/getUserId";

const Subscription = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const [UserData, setUserData] = useState();
  console.log(UserData);

  const { userSubscription, cardDetails } = useSelector(
    (state) => state.Subscription
  );

  const user_id = UserId();
  useEffect(() => {
    if (user_id) loadUserData(user_id);
  }, [user_id]);

  useEffect(() => {
    document.title = "Subscription Details | ATW";

    dispatch({
      type: GET_USER_SUBSCRIPTION,
    });

    dispatch({
      type: GET_USER_CARD_DETAILS,
    });
    return () => {
      dispatch(getUserSubscriptionSuccess(null));
      dispatch(getUserCardDetailsSuccess(null));
    };
  }, []);

  const handleDeleteClose = () => setShowModal(false);
  const handleModalClose = () => setShowCardModal(false);

  const deleteRecord = async () => {
    await cancelStripePayment(userSubscription.subscription_id).then((res) => {
      if (res.code === 200) {
        showSuccess("Your Subscription has been Cancelled");
        dispatch({
          type: GET_USER_SUBSCRIPTION,
        });

        handleDeleteClose();
      } else {
        showError(res.message);

        handleDeleteClose();
      }
    });
  };

  const loadUserData = async (id) => {
    let userPayload = {
      payload: id,
    };
    await getUserById(userPayload).then((res) => {
      if (res.code === 200) setUserData(res.data);
      else showError(res.message);
    });
  };

  return (
    <div className="custom-tabs-body subscription-detail">
      <div className="tabs-body-inner">
        <div className="outer-content-wrap mb-3">
          {/* <button className="edit-btn">
            <img src={editIcon} alt="" />
          </button> */}
          <div className="row">
            <div className="col-md-7 col-lg-8 mb-3">
              <div className="settings-title-block">
                <span className="title">Name</span>
                <span className="subtitle small">
                  {userSubscription && userSubscription.user_id.name}
                </span>
              </div>
              <div className="settings-title-block">
                <span className="title">Account Created</span>
                <span className="subtitle small">
                  {userSubscription &&
                    moment(userSubscription.createdAt).format("Do MMMM YYYY")}
                </span>
              </div>
              <div className="settings-title-block">
                <span className="title">Payment Details</span>
                <div className="card-detail">
                  <span>
                    xxxx xxxx xxxx {cardDetails && cardDetails.card_number}
                  </span>
                  <span>{cardDetails && cardDetails.expire_date}</span>
                </div>
              </div>
              {UserData && UserData.status != "trial" && (
                <div className="btn-wrap">
                  <button
                    className="btn gray-bg"
                    onClick={() => setShowCardModal(true)}
                  >
                    Edit Payment Details
                  </button>
                </div>
              )}
            </div>
            <div className="col-md-5 col-lg-4 mb-3">
              <div className="settings-title-block">
                <span className="title">Subscription Status</span>
                <span className="btn green-bg">
                  {userSubscription && userSubscription.status.toUpperCase()}
                  {UserData && UserData.status == "trial" && "Trial "}
                </span>
              </div>
              <div className="settings-title-block">
                <span className="title">Subscription Renewal</span>
                <span className="subtitle small">
                  {UserData &&
                    UserData.trialExpire &&
                    moment(UserData.trialExpire).format("Do MMMM YYYY")}
                  {userSubscription &&
                    moment(userSubscription.plan_expire_date).format(
                      "Do MMMM YYYY"
                    )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {UserData && UserData.status != "trial" && (
          <div className="outer-btn-wrap justify-content-end">
            <button className="btn gray-bg" onClick={() => setShowModal(true)}>
              Disable Auto-renew
            </button>
            {/* <button className="btn red-bg">Cancel Subscription</button> */}
          </div>
        )}
      </div>

      {showModal && (
        <DeleteConfirm
          handleDeleteClose={handleDeleteClose}
          deleteRecord={deleteRecord}
          msg={`Are you sure that want to disable your subscription ? You will not be able to renew your account after ${
            userSubscription &&
            moment(userSubscription.plan_expire_date).format("Do MMMM YYYY")
          }.`}
          icon={"warning"}
        />
      )}

      {showCardModal && (
        <ChangeCard
          showModal={showCardModal}
          handleModalClose={handleModalClose}
          cardId={cardDetails && cardDetails.cardId}
          customerId={cardDetails && cardDetails.user_id.strip_customer_id}
        />
      )}
    </div>
  );
};

export default Subscription;
