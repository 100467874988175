import { routes as login } from "../views/Registration";
import { routes as adminLogin } from "../adminView/Login";

import { routes as home } from "../views/Home";
import { routes as canvas } from "../views/Canvas";
import { routes as ModelsStarted } from "../views/Models_Started";
import { routes as Models_NeedHelp } from "../views/Models_NeedHelp";
import { routes as Models_parked } from "../views/Models_parked";
import { routes as Models_production } from "../views/Models_production";
import { routes as Settings } from "../views/Settings";

import { routes as UserList } from "../adminView/userList";
import { routes as FeaturedList } from "../adminView/featuredList";
import { routes as NeedHelpList } from "../adminView/NeedHelpList";
import { routes as AdminSettings } from "../adminView/Settings";
import { routes as AdminHome } from "../adminView/Home";

export const routes = [
  ...home,
  ...canvas,
  ...ModelsStarted,
  ...Models_NeedHelp,
  ...Models_parked,
  ...Models_production,
  ...Settings,
];

// export const userRoutes = [
//   ...dashboard,
//   ...employee,
//   ...assets,
//   ...unitsManagement,
//   ...hrPortal,
// ];

export const adminRoutes = [
  ...UserList,
  ...FeaturedList,
  ...NeedHelpList,
  ...AdminSettings,
  ...AdminHome,
];

export const authRoutes = [...login, ...adminLogin];
