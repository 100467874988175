import React from "react";
import { useDispatch } from "react-redux";
import { getLoginSuccess } from "../../../store/login/actions";
import { useForm } from "react-hook-form";
import PreLoginLeftBlock from "../../../views/Registration/components/PreLoginLeftBlock";
import { Link, useNavigate } from "react-router-dom";
import { adminLogin } from "../../../helpers/backendHelper";
import { showError } from "../../../Utils/alertMessage";
import atwLogo from "../../../assets/images/atw-logo.png";
import atwLogoHeader from "../../../assets/images/atw-logo-header.svg";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    const loginData = {
      payload: data,
    };
    await adminLogin(loginData).then(async (res) => {
      if (res.code === 200) {
        await dispatch(getLoginSuccess(res.data));
        await localStorage.setItem("data", JSON.stringify(res.data));
        navigate("/admin/dashboard");
      } else {
        showError(res.message);
      }
    });
  };
  return (
    <>
      <div className="wrapper">
        <div className="main-wrap login-page">
          <div className="prelogin-outer-wrap">
            <div className="prelogin-header">
              <div className="container">
                <div className="logo-wrap">
                  <img src={atwLogoHeader} alt="ATW" />
                </div>
              </div>
            </div>
            <div className="img-form-section">
              <div className="bg-img">
                <img src={binaryCodeBg} alt="Background Img" />
              </div>
              <div className="container">
                <div className="row-wrap">
                  <PreLoginLeftBlock />
                  <div className="right-content-wrap">
                    <div className="title-block">
                      <h1 className="title">Admin Login</h1>
                      <span className="sub-title">Sign In to continue</span>
                    </div>
                    <div className="right-content-inner">
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="pre-login-form"
                      >
                        <div className="top-block">
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputEmail1"
                            >
                              Email address <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email *"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message: "Please enter a valid email",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.email?.message}
                            </p>
                          </div>
                          <div className="form-group">
                            <label
                              className="reg-label"
                              htmlFor="exampleInputPassword1"
                            >
                              Password <span className="required">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder="Password *"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password length should be at least 8 characters",
                                },
                              })}
                            />
                            <p className="error-message">
                              {errors.password?.message}
                            </p>
                          </div>
                          <div className="d-flex justify-content-center btn-wrap">
                            <button type="submit" className="btn black-bg mr-3">
                              Sign In
                            </button>
                            <button
                              type="button"
                              onClick={() => navigate("/")}
                              className="btn secondary-bg"
                            >
                              back
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-footer">
              <div className="container">
                <Link to="/" className="logo-wrap">
                  <img src={atwLogo} alt="ATW" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
