import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import AllRoutes from "./routes/AllRoutes";
import { useDispatch } from "react-redux";
import { getUserData } from "./store/login/actions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "After The Why";

    dispatch(getUserData());
  }, []);
  return (
    <div className="App">
      <AllRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
