import React, { useState, useRef, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import InputBox from "../../../../Utils/InputBox";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import { RIDRER_AND_DRIVER } from "../../../../store/saveCanvas/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { UserId } from "../../../../Utils/getUserId";
import { riderAndDriverSuccess } from "../../../../store/saveCanvas/actions";
import { UPDATE_RIDRER_AND_DRIVER } from "../../../../store/updateCanvas/actionTypes";
import { updateRiderAndDriverSuccess } from "../../../../store/updateCanvas/actions";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../../common/sharedCanvasModal";
import Loader from "../../../common/loader";
import { useNavigatingAway } from "../../../../Utils/useNavigatingAway";
import DeleteConfirm from "../../../common/deleteConfirm";
import FrameIcon from "../../../../assets/images/frame-black.png";

const options = [
  { value: "start", label: "Started" },
  { value: "parked", label: "Parked" },
  { value: "need_help", label: "Need Help" },
  { value: "in_prodution", label: "In Production" },
];

const RidersAndDrivers = ({ riderData }) => {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [activeTab, setActiveTab] = useState("");
  const [headerActive, setHeaderActive] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [subHeaderActive, setSubHeaderActive] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);

  const [status, setStatus] = useState({ value: "start", label: "Started" });
  const [data, SetData] = useState({
    problem: [],
    existing: [],
    solution: [],
    keyMetrics: [],
    unique: [],
    high: [],
    unfair: [],
    channels: [],
    customer: [],
    early: [],
    cost: [],
    revenue: [],
  });
  const [canvasId, setCanvasId] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);

  let { riderAndDriver, saveCanvasLoading } = useSelector(
    (state) => state.SaveCanvasReducer
  );
  let { updateRiderAndDriver } = useSelector((state) => state.UpdateCanvas);
  let { loadingLogins } = useSelector((state) => state.GetCanvas);

  document.title = "ATW Start Up Model | ATW";

  useEffect(() => {
    if (riderData) {
      SetData({
        problem: riderData.problem,
        existing: riderData.existing_alternatives,
        solution: riderData.solution,
        keyMetrics: riderData.key_metrics,
        unique: riderData.unique_value_proposition,
        high: riderData.high_Level_concept,
        unfair: riderData.unfair_advantage,
        channels: riderData.channels,
        customer: riderData.customer_segmentsoblem,
        early: riderData.early_adopters,
        cost: riderData.cost_structure,
        revenue: riderData.revenue_streams,
      });
      setCanvasId(riderData._id);
      setStatus(
        options.find((option) => {
          if (option.value === riderData.model_status) return option;
        })
      );
      setHeaderText(riderData.title);
      setSubHeaderText(riderData.sub_title);
    }
  }, [riderData]);

  const handleChange = (e, tab) => {
    // if (e.target.value.length > 0 && e.target.value === "\n") {
    //   showError("Text cannot be blank ");
    // } else {
    setCanShowDialogLeavingPage(true);
    let tabValue = data[tab];
    if (e.key === "Enter") {
      if (e.target.value.trim() === "") {
        showError("Value Can't Be Empty");
        return;
      }
      tabValue.push(e.target.value);
      // tabValue.push(e.target.value.replace(/(.{1,10})/g, "$1<br/>"));
      inputRef.current.value = "";
    }
    SetData({ ...data, [tab]: tabValue });
    // }
  };

  const printDocument = async () => {
    handleSave("pdf");
  };

  const handleDelete = (index, tab) => {
    let tabValue = data[tab];
    tabValue.splice(index, 1);
    SetData({ ...data, tab: tabValue });
  };

  const handleSave = (checkPdf) => {
    setCanShowDialogLeavingPage(false);

    if (headerText === "") {
      showError("Enter Canvas Title");
      return;
    }
    if (subHeaderText === "") {
      showError("Enter Canvas Description");
      return;
    }

    if (checkValidation()) {
      showError("Canvas can't be Empty");
      return;
    } else {
      if (checkPdf === "pdf") setGeneratePdf(true);

      let payload = {
        user_id: UserId(),
        modal_id: id,
        title: headerText,
        sub_title: subHeaderText,
        problem: data.problem,
        solution: data.solution,
        unique_value_proposition: data.unique,
        unfair_advantage: data.unfair,
        customer_segmentsoblem: data.customer,
        existing_alternatives: data.existing,
        key_metrics: data.keyMetrics,
        high_Level_concept: data.high,
        channels: data.channels,
        early_adopters: data.early,
        cost_structure: data.cost,
        revenue_streams: data.revenue,
        model_status: status.value,
        completed: canvasCompleted(),
      };

      if (canvasId === "") {
        dispatch({
          type: RIDRER_AND_DRIVER,
          payload,
        });
      } else {
        payload.modal_id = riderData.modal_id.id;
        payload["id"] = canvasId;
        delete payload.user_id;
        dispatch({
          type: UPDATE_RIDRER_AND_DRIVER,
          payload,
        });
      }
    }
  };

  useEffect(() => {
    if (riderAndDriver) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/riders/pdf/${riderAndDriver._id}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/riders/pdf/${riderAndDriver._id}`,
            "_blank"
          );
        }

        setGeneratePdf(false);
      } else {
        if (canvasId === "") {
          setCanvasId(riderAndDriver._id);
          showSuccess("Canvas Saved Successfully");
          navigate(`/edit/canvas/atw-startup/${riderAndDriver._id}`);
        }
      }

      dispatch(riderAndDriverSuccess(null));
    }
  }, [riderAndDriver]);

  useEffect(() => {
    if (updateRiderAndDriver) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/riders/pdf/${canvasId}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/riders/pdf/${canvasId}`,
            "_blank"
          );
        }
        setGeneratePdf(false);
      } else {
        showSuccess("Canvas Updated Successfully");
      }
      dispatch(updateRiderAndDriverSuccess(null));
    }
  }, [updateRiderAndDriver]);

  const checkValidation = () => {
    if (
      data.problem.length === 0 &&
      data.existing.length === 0 &&
      data.solution.length === 0 &&
      data.keyMetrics.length === 0 &&
      data.unique.length === 0 &&
      data.high.length === 0 &&
      data.unfair.length === 0 &&
      data.channels.length === 0 &&
      data.customer.length === 0 &&
      data.early.length === 0 &&
      data.cost.length === 0 &&
      data.revenue.length === 0
    )
      return true;
  };

  const canvasCompleted = () => {
    let partialValue = 0;
    for (const property in data) {
      if (data[property].length !== 0) partialValue += 1;
    }

    return Math.round((100 * partialValue) / Object.keys(data).length);
  };

  const handleStatusChange = (value) => {
    setCanShowDialogLeavingPage(true);

    setStatus(value);
  };

  const handleHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        headerText === "" && showError("Heading Can't Be Empty");
        setHeaderActive(false);
        return;
      }
      setHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setHeaderText(e.target.value);
  };

  const handleSubHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        subHeaderText === "" && showError("Description Can't Be Empty");
        setSubHeaderActive(false);
        return;
      }
      setSubHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setSubHeaderText(e.target.value);
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block green-bg">
          <div className="title-inner">
            <div className="left-block">
              <i className="icon">
                <img src={FrameIcon} alt="AtW Start Up" />
              </i>
              <h2 className="title">ATW Start Up Model</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>

              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>ATW Start Up Model Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>Use the editable text fields to create your Model.</p>
                    <p className="mt-3">
                      Use your mouse cursor to click on the various grey boxes
                      to edit the Model Title, Model Description and each of the
                      key information sections in the model itself.
                    </p>

                    <p className="mt-3">
                      Once finished, click{" "}
                      <span className="gray-btn">Save</span>, and then click
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      to open the drop down menu. You can then choose whether to
                      Share or download. Clicking download will open the filled
                      model in a PDF for you to save to your computer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-inner" onClick={() => setHeaderActive(true)}>
            {headerActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleHeaderText(e)}
                  placeholder="Enter Canvas Heading"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : headerText === "" ? (
              <h1 className="title">Enter Canvas Heading</h1>
            ) : (
              <h1 className="title">{headerText}</h1>
            )}
          </div>
          <div className="title-inner" onClick={() => setSubHeaderActive(true)}>
            {subHeaderActive ? (
              <div className="form-group">
                <textarea
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleSubHeaderText(e)}
                  placeholder="Enter Canvas Description"
                  autoFocus
                  ref={inputRef}
                  className="form-control"
                />
              </div>
            ) : subHeaderText === "" ? (
              <span className="sub-title">Enter Canvas Description</span>
            ) : (
              <span className="sub-title">{subHeaderText}</span>
            )}
          </div> */}
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Title"
                  autoFocus
                  className="form-control heading"
                  onChange={(e) => {
                    setHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={headerText}
                />
              </div>
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Description"
                  className="form-control description"
                  onChange={(e) => {
                    setSubHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={subHeaderText}
                />
              </div>
            </div>
          </div>
          <div className="right-wrap d-flex">
            <div className="right-inner">
              <div className="mb-2">
                <button
                  type="button"
                  className="custom-dropdown-wrapper btn gray-bg"
                >
                  <span>Status:</span>
                  <Select
                    className="custom-select"
                    options={options}
                    value={status}
                    isSearchable={false}
                    onChange={(e) => handleStatusChange(e)}
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "transform 0.3s",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }),
                    }}
                  />
                </button>
              </div>
              <div className="d-flex mb-2">
                <button
                  type="button"
                  className="btn gray-bg mr-3"
                  onClick={() => handleSave()}
                  disabled={saveCanvasLoading}
                >
                  {canvasId === "" ? "Save" : "Update"}
                </button>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle className="gray-bg btn">
                    Other Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                <button
                // onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                >
                  Video
                </button>
              </Dropdown.Item> */}
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => printDocument()}
                      >
                        Download
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => {
                          canvasId === ""
                            ? showError("Please Save The Canvas")
                            : setShowShareModal(true);
                        }}
                      >
                        Share
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-inner">
          <div className="data-block-outer">
            {/* <div className="info-wrapper">
              <img src={info} alt="Info" />
              <span className="tooltiptext">
                The ATW Start-Up model canvas helps you get the ideas out of
                your head into a structured form of execution. It makes it easy
                to see everything from a high level.
              </span>
            </div> */}
            <div className="data-block p-0" id="divToPrint">
              {/* <div className="grid-block atw-table-block">
                <div className="upper-block">
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper custom-col">
                        <div className="content-block" >
                          <span className="title">Problem</span>
                          <div className="content">
                            <textarea
                              rows={5}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div className="content-block">
                          <span className="title">Existing Alternatives</span>
                          <div className="content">
                            <textarea
                              rows={2}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper border-bottom custom-col">
                        <div className="content-block">
                          <span className="title">Solution</span>
                          <div className="content">
                            <textarea
                              rows={4}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div className="content-block">
                          <span className="title">Key Metrics</span>
                          <div className="content">
                            <textarea
                              rows={2}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper">
                        <div className="content-block">
                          <span className="title">
                            Unique Value Proposition
                          </span>
                          <div className="content">
                            <textarea
                              rows={5}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lower">
                        <div className="content-block">
                          <span className="title">High-Level Concept</span>
                          <div className="content">
                            <textarea
                              rows={2}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper border-bottom custom-col">
                        <div className="content-block">
                          <span className="title">Unfair Advantage</span>
                          <div className="content">
                            <textarea
                              rows={4}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div className="content-block">
                          <span className="title">Channels</span>
                          <div className="content">
                            <textarea
                              rows={2}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper">
                        <div className="content-block">
                          <span className="title">Customer Segments</span>
                          <div className="content">
                            <textarea
                              rows={5}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="lower">
                        <div className="content-block">
                          <span className="title">Early Adopters</span>
                          <div className="content">
                            <textarea
                              rows={2}
                              type="text"
                              autoFocus
                              placeholder="Click to add text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lower-block">
                  <div className="column">
                    <div className="inner-col">
                      <div className="content-block">
                        <span className="title">Cost Structure</span>
                        <div className="content">
                          <textarea
                            rows={4}
                            type="text"
                            autoFocus
                            placeholder="Click to add text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="content-block">
                        <span className="title">Revenue Streams</span>
                        <div className="content">
                          <textarea
                            rows={4}
                            type="text"
                            autoFocus
                            placeholder="Click to add text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="grid-block atw-table-block">
                <div className="upper-block">
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("problem")}
                        >
                          <span className="title">Problem</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "problem" &&
                                data.problem.length === 0 && (
                                  // <p>Enter your problem hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      // defaultValue={"Click to add"}
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}

                              {data.problem.length !== 0 &&
                                data.problem.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "problem")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "problem" && (
                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    onKeyUp={(e) => handleChange(e, "problem")}
                                    autoFocus
                                    ref={inputRef}
                                  />
                                  {/* <button
                                  className="delete-row"
                                  onClick={() => {
                                    setActiveTab("");
                                  }}
                                >
                                  +
                                </button> */}
                                </div>
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("existing")}
                        >
                          <span className="title">Existing Alternatives</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "existing" &&
                                data.existing.length === 0 && (
                                  // <p>Enter your existing hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.existing.length !== 0 &&
                                data.existing.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "existing")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "existing" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "existing")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper border-bottom custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("solution")}
                        >
                          <span className="title">Solution</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "solution" &&
                                data.solution.length === 0 && (
                                  // <p>Enter your solution hear</p>
                                  // <li>Click to add</li>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.solution.length !== 0 &&
                                data.solution.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "solution")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "solution" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "solution")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("keyMetrics")}
                        >
                          <span className="title">Key Metrics</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "keyMetrics" &&
                                data.keyMetrics.length === 0 && (
                                  // <p>Enter your keyMetrics hear</p>
                                  // <li>Click to add</li>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.keyMetrics.length !== 0 &&
                                data.keyMetrics.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "keyMetrics")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "keyMetrics" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "keyMetrics")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("unique")}
                        >
                          <span className="title">
                            Unique Value Proposition
                          </span>
                          <div className="content">
                            <ol>
                              {activeTab !== "unique" &&
                                data.unique.length === 0 && (
                                  // <p>Enter your unique hear</p>
                                  // <li>Click to add</li>
                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.unique.length !== 0 &&
                                data.unique.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "unique")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "unique" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "unique")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                      <div className="lower">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("high")}
                        >
                          <span className="title">High-Level Concept</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "high" &&
                                data.high.length === 0 && (
                                  // <p>Enter your high hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.high.length !== 0 &&
                                data.high.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "high")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "high" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "high")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper border-bottom custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("unfair")}
                        >
                          <span className="title">Unfair Advantage</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "unfair" &&
                                data.unfair.length === 0 && (
                                  // <p>Enter your unfair hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.unfair.length !== 0 &&
                                data.unfair.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>

                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "unfair")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "unfair" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "unfair")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                      <div className="lower custom-col">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("channels")}
                        >
                          <span className="title">Channels</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "channels" &&
                                data.channels.length === 0 && (
                                  // <p>Enter your channels hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.channels.length !== 0 &&
                                data.channels.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>
                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "channels")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "channels" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "channels")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div className="upper">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("customer")}
                        >
                          <span className="title">Customer Segments</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "customer" &&
                                data.customer.length === 0 && (
                                  // <p>Enter your customer hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.customer.length !== 0 &&
                                data.customer.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>
                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "customer")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "customer" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "customer")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                      <div className="lower">
                        <div
                          className="content-block"
                          onClick={() => setActiveTab("early")}
                        >
                          <span className="title">Early Adopters</span>
                          <div className="content">
                            <ol>
                              {activeTab !== "early" &&
                                data.early.length === 0 && (
                                  // <p>Enter your early hear</p>
                                  // <li>Click to add</li>

                                  <div className="list-wrapper">
                                    <textarea
                                      rows={1}
                                      type="text"
                                      placeholder="Click to add"
                                    />
                                  </div>
                                )}
                              {data.early.length !== 0 &&
                                data.early.map((value, index) => (
                                  <div key={index}>
                                    <li>
                                      <div className="list-wrapper">
                                        <div
                                          className="text-value"
                                          dangerouslySetInnerHTML={{
                                            __html: value,
                                          }}
                                        ></div>
                                        <button
                                          className="delete-row"
                                          onClick={() =>
                                            handleDelete(index, "early")
                                          }
                                        >
                                          +
                                        </button>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              {activeTab === "early" && (
                                <textarea
                                  rows={1}
                                  type="text"
                                  onKeyUp={(e) => handleChange(e, "early")}
                                  autoFocus
                                  ref={inputRef}
                                />
                              )}
                            </ol>
                          </div>
                          {/* <button className="add-btn">+</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lower-block">
                  <div className="column">
                    <div className="inner-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("cost")}
                      >
                        <span className="title">Cost Structure</span>
                        <div className="content">
                          <ol>
                            {activeTab !== "cost" && data.cost.length === 0 && (
                              // <p>Enter your cost hear</p>
                              // <li>Click to add</li>

                              <div className="list-wrapper">
                                <textarea
                                  rows={1}
                                  type="text"
                                  placeholder="Click to add"
                                />
                              </div>
                            )}
                            {data.cost.length !== 0 &&
                              data.cost.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        className="text-value"
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>
                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "cost")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "cost" && (
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "cost")}
                                autoFocus
                                ref={inputRef}
                              />
                            )}
                          </ol>
                        </div>
                        {/* <button className="add-btn">+</button> */}
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="inner-col">
                      <div
                        className="content-block"
                        onClick={() => setActiveTab("revenue")}
                      >
                        <span className="title">Revenue Streams</span>
                        <div className="content">
                          <ol>
                            {activeTab !== "revenue" &&
                              data.revenue.length === 0 && (
                                // <p>Enter your revenue hear</p>
                                // <li>Click to add</li>

                                <div className="list-wrapper">
                                  <textarea
                                    rows={1}
                                    type="text"
                                    placeholder="Click to add"
                                  />
                                </div>
                              )}
                            {data.revenue.length !== 0 &&
                              data.revenue.map((value, index) => (
                                <div key={index}>
                                  <li>
                                    <div className="list-wrapper">
                                      <div
                                        className="text-value"
                                        dangerouslySetInnerHTML={{
                                          __html: value,
                                        }}
                                      ></div>
                                      <button
                                        className="delete-row"
                                        onClick={() =>
                                          handleDelete(index, "revenue")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            {activeTab === "revenue" && (
                              <textarea
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "revenue")}
                                autoFocus
                                ref={inputRef}
                              />
                            )}
                          </ol>
                        </div>
                        {/* <button className="add-btn">+</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={canvasId}
            adminId={riderData && riderData.user_id._id}
          />
        )}
        {showDialogLeavingPage && (
          <DeleteConfirm
            handleDeleteClose={cancelNavigation}
            deleteRecord={confirmNavigation}
            msg={
              "There are some changes. If you proceed your changes will be lost"
            }
            icon={"error"}
          />
        )}
      </div>
    </>
  );
};

export default RidersAndDrivers;
