import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  shareCanvas,
  removeSharedCanvas,
  sharedCanvasList,
} from "../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../Utils/alertMessage";
import { UserId } from "../../../Utils/getUserId";

const SharedCanvas = ({
  showShareModal,
  setShowShareModal,
  canvasId,
  adminId,
  loadData,
}) => {
  const [shareEmail, setShareEmail] = useState("");
  const [shareList, setShareList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleShareModal = async () => {
    let sharedUserExits = false;
    if (canvasId !== "") {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(shareEmail)) {
        if (shareList.length !== 0) {
          shareList.map(({ user_id }) => {
            if (user_id.email == shareEmail) {
              sharedUserExits = true;
            }
          });
        }
        if (!sharedUserExits) {
          const sharePayload = {
            payload: {
              email: shareEmail,
              model_id: canvasId,
            },
          };
          await shareCanvas(sharePayload).then((res) => {
            if (res.code === 200) {
              showSuccess("Canvas Shared Successfully");
              setShowShareModal(false);
            } else showError(res.message);
          });
        } else {
          showError("The Canvas is Already Shared with This User");
        }
      } else {
        showError("Enter a Valid Email");
      }
    } else {
      showError("Please Save The Canvas");
    }
  };

  useEffect(() => {
    getSharedList();

    if (UserId() === adminId) setIsAdmin(true);
    else setIsAdmin(false);
  }, []);

  const getSharedList = async () => {
    await sharedCanvasList(canvasId).then((res) => {
      if (res.code === 200) {
        setShareList(res.data);
      }
    });
  };

  const removeUser = async (id) => {
    const removePayload = {
      payload: {
        user_id: id,
        model_id: canvasId,
      },
    };
    await removeSharedCanvas(removePayload).then((res) => {
      if (res.code === 200) {
        showSuccess("User removed successfully");
        setShowShareModal(false);
        loadData();
      } else showError(res.message);
    });
  };

  return (
    <Modal
      show={showShareModal}
      onHide={() => setShowShareModal(false)}
      className="share-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Share Canvas Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="top-block">
          {/* <Form > */}
          {isAdmin && (
            <>
              <Form.Group
                className="form-group with-label"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="reg-label">Email address</Form.Label>
                <input
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                  value={shareEmail}
                  onChange={(e) => {
                    setShareEmail(e.target.value);
                  }}
                  className="form-control"
                />
              </Form.Group>
            </>
          )}
          <Form.Group
            className="form-group with-label"
            controlId="exampleForm.ControlTextarea1"
          >
            <div>
              {shareList.length !== 0 ? (
                <div className="popup-data">
                  <ul className="d-flex">
                    <li>Name</li>
                    <li>Email</li>
                    <li>Action</li>
                  </ul>
                  {shareList.map(({ user_id }, index) => (
                    <div className="d-flex popup-data-items" key={index}>
                      <p>{user_id.name}</p>
                      <p>{user_id.email}</p>
                      <span>
                        <button
                          className="delete-row"
                          onClick={() => removeUser(user_id._id)}
                          type="button"
                        >
                          +
                        </button>
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Canvas is not shared with any one.</p>
              )}
            </div>
          </Form.Group>
          {/* </Form> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn black-bg"
          onClick={() => setShowShareModal(false)}
        >
          Close
        </Button>
        {isAdmin && (
          <Button
            className="btn secondary-bg"
            onClick={() => handleShareModal()}
          >
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SharedCanvas;
