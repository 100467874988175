import Loadable from "react-loadable";

import { Loading } from "../../views/common/navigation";

const UserList = Loadable({
  loader: () => import("./components/userList/index"),
  loading: Loading,
});

export const routes = [
  {
    path: "/admin/user-list",
    exact: true,
    name: "UserList",
    element: UserList,
  },
];

export * from "./components";
