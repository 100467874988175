import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { showError, showSuccess } from "../../../Utils/alertMessage";

import {
  UPDATE_USER_PROFILE,
  GET_COUNTRY_LIST,
} from "../../../store/login/actionTypes";
import { updateUserProfileSuccess } from "../../../store/login/actions";
import Loader from "../../common/loader";

const UpdateUserCountry = ({
  showEditCountry,
  setShowEditCountry,
  UserData,
  loadUserData,
}) => {
  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("data"));

  const { _id, contactNumber, country } = UserData;
  const [countryName, setCountryName] = useState(country);

  const { updateUser, countryList, loadingLogins } = useSelector(
    (state) => state.LoginReducer
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      country,
      contactNumber,
    },
  });

  useEffect(() => {
    if (!countryList) {
      dispatch({
        type: GET_COUNTRY_LIST,
      });
    }
  }, []);

  const onSubmit = (data) => {
    userData.name = data.name;
    userData.title = data.title;
    userData.description = data.description;
    localStorage.setItem("data", JSON.stringify(userData));
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: data,
    });
  };

  useEffect(() => {
    if (updateUser) {
      showSuccess("User updated Successfully");
      dispatch(updateUserProfileSuccess(null));
      loadUserData(_id);
      setShowEditCountry(false);
    }
  }, [updateUser]);

  return (
    <Modal
      show={showEditCountry}
      onHide={() => setShowEditCountry(false)}
      className="share-modal"
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="pre-login-form">
        <Modal.Header closeButton>
          <Modal.Title>Share Canvas Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="top-block">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Country</label>

              <select
                className="form-select"
                {...register("country", {
                  required: "country is required",
                })}
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
              >
                <option value="" data-id="">
                  Select Country
                </option>
                {countryList &&
                  countryList.length !== 0 &&
                  countryList.map(({ country }, index) => (
                    <option value={country} key={index} data-id={index}>
                      {country}
                    </option>
                  ))}
              </select>

              <p className="error-message">{errors.country?.message}</p>
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Contact Number</label>
              <input
                type="text"
                className="form-control"
                id="contact number"
                aria-describedby="emailHelp"
                placeholder="Enter Your Contact Number"
                {...register("contactNumber", {
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                    message: "Please enter a valid Contact Number",
                  },
                })}
              />
              <p className="error-message">{errors.contactNumber?.message}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn black-bg"
            onClick={() => setShowEditCountry(false)}
          >
            Close
          </Button>

          <Button className="btn secondary-bg" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </form>

      {loadingLogins && <Loader />}
    </Modal >
  );
};

export default UpdateUserCountry;
