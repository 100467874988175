import React from "react";
// import atwLogoHeader from "../../../assets/images/atw-logo-header.svg";
import atwLogo from "../../../assets/images/pre-login-logo.png";

const PreLoginLeftBlock = () => {
  return (
    <>
      <div className="left-bg-wrap">
        <div className="inner-wrap">
          {/* <div className="logo-wrap">
            <i>
              <img src={atwLogoHeader} alt="Logo" />
            </i>
          </div> */}
          <div className="frame-block">
            <img src={atwLogo} alt="" />
          </div>
          <div className="canvas-mode">
            <span>Business Model Portal</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreLoginLeftBlock;
