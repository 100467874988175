import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import atwicon from "../../../../assets/images/frame-black.png";
import techstackicon from "../../../../assets/images/stack.png";
import valueicon from "../../../../assets/images/proposition.png";
import daysicon from "../../../../assets/images/plan.png";
import visionicon from "../../../../assets/images/vision.png";

import {
  updateUserData,
  getUserById,
  getModelsCountByUserId,
} from "../../../../helpers/backendHelper";
import { showError, showSuccess } from "../../../../Utils/alertMessage";

const UserModel = ({ showShareModal, setShowShareModal, userId }) => {
  const [note, setNote] = useState("");
  const [canvasCounter, setCanvasCounter] = useState({});

  const handleClose = () => {
    setShowShareModal(false);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    let userPayload = {
      payload: userId,
    };
    await getUserById(userPayload).then((res) => {
      if (res.code === 200) setNote(res.data.note);
      else showError(res.message);
    });

    await getModelsCountByUserId(userId).then((res) => {
      if (res.code === 200) setCanvasCounter(res.data[0]);
      else showError(res.message);
    });
  };

  const handleSubmit = async () => {
    const statusPayload = {
      payload: {
        user_id: userId,
        value: { note },
      },
    };

    await updateUserData(statusPayload).then((res) => {
      if (res.code === 200) {
        showSuccess("Notes Saved Successfully");
        handleClose();
      } else {
        showError(res.message);
      }
    });
  };

  return (
    <Modal show={showShareModal} onHide={handleClose} className="user-modal">
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="four-col-block-admin row">
            <div className="col">
              <div className="inner-col">
                <div className="img-block">
                  <img src={atwicon} alt="Image" />
                </div>
                <div className="content">
                  <span className="title">ATW Start Up Modal</span>
                  <span className="number">{canvasCounter.riders}</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inner-col">
                <div className="img-block">
                  <img src={techstackicon} alt="Image" />
                </div>
                <div className="content">
                  <span className="title">Technology Stack</span>
                  <span className="number">{canvasCounter.tack_stack}</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inner-col">
                <div className="img-block">
                  <img src={valueicon} alt="Image" />
                </div>
                <div className="content">
                  <span className="title">Value Proposition</span>
                  <span className="number">{canvasCounter.tesla_alue}</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inner-col">
                <div className="img-block">
                  <img src={daysicon} alt="Image" />
                </div>
                <div className="content">
                  <span className="title">90 day Plan</span>
                  <span className="number">{canvasCounter.dayplan}</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inner-col">
                <div className="img-block">
                  <img src={visionicon} alt="Image" />
                </div>
                <div className="content">
                  <span className="title">Vision Board</span>
                  <span className="number">{canvasCounter.vision}</span>
                </div>
              </div>
            </div>
          </div>

          <Form>
            <Form.Group
              className="form-group with-label"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="reg-label">User Notes</Form.Label>
              <Form.Control
                className="form-control"
                as="textarea"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn secondary-bg" onClick={handleClose}>
          Close
        </Button>
        <Button className="btn black-bg" onClick={() => handleSubmit()}>
          Save Notes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModel;
