import React, { useState, useEffect, useRef } from "react";
import atwLogoHeader from "../../../assets/images/atw-logo-header.svg";
import binaryCodeBg from "../../../assets/images/binary-code-bg.png";
import hamburger from "../../../assets/images/hamburger.svg";
import { Link, useNavigate } from "react-router-dom";

let useClickOutside = (handler) => {
  let domNode = useRef();
  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const SideBar = () => {
  const navigate = useNavigate();
  const activeMenu = window.location.pathname.substring(1);

  const [showMenu, setShowMenu] = useState(false);

  let domNode = useClickOutside(() => {
    setShowMenu(false);
  });
  // let menuRef = useRef();

  // useEffect(() => {
  //   let handlar = (e) => {
  //     if (!menuRef.current.contains(e.target)) setShowMenu(false);
  //   };
  //   document.addEventListener("mousedown", handlar);
  // });

  return (
    <>
      <div className="mobile-header ">
        <div className="left-header">
          <i className="hamburger " onClick={() => setShowMenu(true)}>
            <img src={hamburger} alt="Hamburger" />
          </i>
          <Link to="/dashboard" className="logo-wrap">
            <i onClick={() => setShowMenu(false)}>
              <img src={atwLogoHeader} alt="Logo" />
            </i>
          </Link>
        </div>
        <div className="right-header">
          <Link
            to="#"
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            className="logout-link"
          >
            Log Out
          </Link>
        </div>
      </div>
      <div className={`sidebar ${showMenu ? `is-open` : ""}`}>
        <div className="overlay "></div>
        <div className="bg-img">
          <img src={binaryCodeBg} alt="Binarycode bg" />
        </div>
        <div className="sidebar-wrap" ref={domNode}>
          <Link to="/dashboard" className="logo-wrap">
            <i onClick={() => setShowMenu(false)}>
              <img src={atwLogoHeader} alt="Logo" />
            </i>
          </Link>
          <ul className="menu-list ">
            <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "dashboard" ? `active` : ""}
            >
              <Link to="/dashboard">Home</Link>
            </li>
            {/* <li onClick={() => setShowMenu(false)}>
              <Link to="#">Community</Link>
            </li> */}
            <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "model-started" ? `active` : ""}
            >
              <Link to="/my-models">My Models</Link>
            </li>
            {/* <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "model-parked" ? `active` : ""}
            >
              <Link to="/model-parked">Models - Parked</Link>
            </li>
            <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "model-need_help" ? `active` : ""}
            >
              <Link to="/model-need_help">Models - Need help with</Link>
            </li>
            <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "model-production" ? `active` : ""}
            >
              <Link to="/model-production">Models - In production</Link>
            </li> */}
            <li
              onClick={() => setShowMenu(false)}
              className={activeMenu === "settings" ? `active` : ""}
            >
              <Link to="/settings">Settings</Link>
            </li>
            <li onClick={() => setShowMenu(false)}>
              <Link
                to="#"
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
              >
                Log out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
