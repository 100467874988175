import { post, patch, get } from "./apiHelper";
import * as url from "./urlHelper";

//Login
export const login = (data) => post(url.LOGIN, data);

//Registration
export const Registration = (data) => post(url.REGISTER, data);

//Forget Password
export const forgetPassword = (data) => post(url.FORGET_PASSWORD, data);

//Reset password
export const resetPassword = (data) => post(url.RESET_PASSWORD, data);

/***************************************
 ░░░░░░░░░░░░░ create canvas ░░░░░░░░░░░ 
****************************************/

/***************************************
 ░░░░░░░░░░░░░ get canvas by id ░░░░░░░░
****************************************/

// get rider and driver
export const getRiderAndDriver = (data) =>
  get(url.GET_RIDER_AND_DRIVER + data.payload, data);

// get 90 day plan
export const get90DayPlan = (data) =>
  get(url.GET_90DAY_PLAN + data.payload, data);

// get tech stack
export const getTechStack = (data) =>
  get(url.GET_TECh_STACK + data.payload, data);

// get tesla_value
export const getTeslaValue = (data) =>
  get(url.GET_TESLA_VALUE + data.payload, data);

// get vision board
export const getVisionBoard = (data) =>
  get(url.GET_VISION_BOARD + data.payload, data);

/***************************************
 ░░░░░░░░░░░░░ get canvas by id ░░░░░░░░
****************************************/

/*************************************
 ░░░░░░░░░░░░░ save canvas ░░░░░░░░░░░ 
**************************************/

// save rider and driver
export const saveRiderAndDriver = (data) =>
  post(url.SAVE_RIDER_AND_DRIVER, data);

// save 90 day plan
export const save90DayPlan = (data) => post(url.SAVE_90DAY_PLAN, data);

// save tech stack
export const saveTechStack = (data) => post(url.SAVE_TECh_STACK, data);

// save tesla_value
export const saveTeslaValue = (data) => post(url.SAVE_TESLA_VALUE, data);

// save vision board
export const saveVisionBoard = (data) => post(url.SAVE_VISION_BOARD, data);

/*************************************
 ░░░░░░░░░░░░░ save canvas ░░░░░░░░░░░ 
**************************************/

/***************************************
 ░░░░░░░░░░░░░ update canvas ░░░░░░░░░░░ 
****************************************/

// update rider and driver
export const updateRiderAndDriver = (data) =>
  patch(url.UPDATE_RIDER_AND_DRIVER + data.payload.id, data);

// update 90 day plan
export const update90DayPlan = (data) =>
  patch(url.UPDATE_90DAY_PLAN + data.payload.id, data);

// update tech stack
export const updateTechStack = (data) =>
  patch(url.UPDATE_TECh_STACK + data.payload.id, data);

// update tesla_value
export const updateTeslaValue = (data) =>
  patch(url.UPDATE_TESLA_VALUE + data.payload.id, data);

// update vision board
export const updateVisionBoard = (data) =>
  patch(url.UPDATE_VISION_BOARD + data.payload.id, data);

/***************************************
 ░░░░░░░░░░░░░ update canvas ░░░░░░░░░░░ 
****************************************/

/***************************************
 ░░░░░░░░░░░░░ delete canvas ░░░░░░░░░░░ 
****************************************/

// delete rider and driver
export const deleteRiderAndDriver = (data) =>
  get(url.DELETE_RIDER_AND_DRIVER + data.payload, data);

// delete 90 day plan
export const delete90DayPlan = (data) =>
  get(url.DELETE_90DAY_PLAN + data.payload, data);

// delete tech stack
export const deleteTechStack = (data) =>
  get(url.DELETE_TECh_STACK + data.payload, data);

// delete tesla_value
export const deleteTeslaValue = (data) =>
  get(url.DELETE_TESLA_VALUE + data.payload, data);

// delete Vision Board
export const deleteVisionBoard = (data) =>
  get(url.DELETE_VISION_BOARD + data.payload, data);

/***************************************
 ░░░░░░░░░░░░░ delete canvas ░░░░░░░░░░░ 
****************************************/

// get all canvas list
export const getAllCanvasList = (data) => get(url.GET_ALL_CANVAS_LIST);

// get canvas by status
export const getCanvasByStatus = (data) =>
  get(url.GET_CANVAS_BY_STATUS + data.payload);

// get all canvas by status
export const getAllCanvasByStatus = (data) =>
  get(url.GET_All_CANVAS_BY_STATUS + data.payload);

// buy SUBSCRIPTION
export const buySubscription = (data) => post(url.BUY_SUBSCRIPTION, data);

// get user subscription
export const getUserSubscription = (data) =>
  get(url.GET_USER_SUBSCRIPTION, data);

// GET COUNTRY LIST
export const getCountryList = (data) => get(url.GET_COUNTRY_LIST, data);

// update user profile
export const updateUserProfile = (data) => post(url.UPDATE_USER_PROFILE, data);

// update user Password
export const updateUserPassword = (data) =>
  post(url.UPDATE_USER_PASSWORD, data);

// share canvas
export const shareCanvas = (data) => post(url.SHARE_CANVAS, data);

// remove shared canvas
export const removeSharedCanvas = (data) =>
  post(url.REMOVE_SHARED_CANVAS, data);

// get shared canvas list
export const sharedCanvasList = (data) =>
  get(url.SHARED_CANVAS_LIST + data, data);

/***************************************
 ░░░░░░░░░░░░░ admin apis ░░░░░░░░░░░░░░ 
****************************************/

//Admin Login
export const adminLogin = (data) => post(url.ADMINLOGIN, data);

// get user list
export const getUserList = (data) =>
  get(url.GET_USER_LIST + data.payload, data);

// change user status
export const changeUserStatus = (data) => patch(url.CHANGE_USER_STATUS, data);

// send mail
export const sendMail = (data) => post(url.SEND_MAIL, data);

// update user data
export const updateUserData = (data) => patch(url.UPDATE_USER_DATA, data);

// update featured models
export const updateFeaturedModels = (data) =>
  post(url.UPDATE_FEATURED_MODELS, data);

// get user by id
export const getUserById = (data) =>
  get(url.GET_USER_BY_ID + data.payload, data);

// get models count
export const getModelsCount = (data) => get(url.GET_MODELS_COUNT, data);

// get user count
export const getUserCount = (data) => get(url.GET_USER_COUNT, data);

// get models count by userId
export const getModelsCountByUserId = (data) =>
  get(url.GET_MODELS_COUNT_BU_USERID + data, data);

/***************************************
 ░░░░░░░░░░░░░ admin apis ░░░░░░░░░░░░░░
****************************************/
// create stripe payment
export const createStripePayment = (data) =>
  post(url.CREATE_STRIPE_PAYMENT, data);

// cancel stripe payment
export const cancelStripePayment = (data) =>
  get(url.CANCEL_STRIPE_PAYMENT + data, data);

// get user card details
export const getUserCardDetails = (data) =>
  get(url.GET_USER_CARD_DETAILS, data);

// update user card details
export const updateUserCardDetails = (data) =>
  patch(url.UPDATE_USER_CARD_DETAILS, data);

// delete Account
export const deleteAccount = (data) => get(url.DELETE_ACCOUNT, data);
