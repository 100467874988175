import {
  GET_USER_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION_SUCCESS,
  GET_USER_SUBSCRIPTION_FAIL,
  GET_USER_CARD_DETAILS,
  GET_USER_CARD_DETAILS_SUCCESS,
  GET_USER_CARD_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  cardDetails: null,
  userSubscription: null,
  loadingLogins: false,
  error: {
    message: "",
  },
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTION:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_USER_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        userSubscription: action.payload,
      };
      break;
    case GET_USER_SUBSCRIPTION_FAIL:
      state = {
        ...state,
        userSubscription: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_USER_CARD_DETAILS:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_USER_CARD_DETAILS_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        cardDetails: action.payload,
      };
      break;
    case GET_USER_CARD_DETAILS_FAIL:
      state = {
        ...state,
        cardDetails: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SubscriptionReducer;
