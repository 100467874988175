import { takeLatest, put, call } from "redux-saga/effects";
import {
  UPDATE_RIDRER_AND_DRIVER,
  UPDATE_90DAY_PLAN,
  UPDATE_TECH_STACK,
  UPDATE_TESLA_VALUE,
  UPDATE_VISION_BOARD,
} from "./actionTypes";
import {
  updateRiderAndDriverSuccess,
  updateRiderAndDriverFail,
  update90DayPlanSuccess,
  update90DayPlanFail,
  updateTechStackSuccess,
  updateTechStackFail,
  updateTeslaValueSuccess,
  updateTeslaValueFail,
  updateVisionBoardSuccess,
  updateVisionBoardFail,
} from "./actions";
import {
  updateRiderAndDriver,
  update90DayPlan,
  updateTechStack,
  updateTeslaValue,
  updateVisionBoard,
} from "../../helpers/backendHelper";
import { showError } from "../../Utils/alertMessage";

function* onUpdateRiderAndDriver(data) {
  try {
    const response = yield call(updateRiderAndDriver, data);

    if (response) {
      if (response.code === 200) {
        yield put(updateRiderAndDriverSuccess(response.data));
      } else {
        yield put(updateRiderAndDriverFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateRiderAndDriverFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateRiderAndDriverFail(error.response));
  }
}

function* onUpdate90DayPlan(data) {
  try {
    const response = yield call(update90DayPlan, data);

    if (response) {
      if (response.code === 200) {
        yield put(update90DayPlanSuccess(response.data));
      } else {
        yield put(update90DayPlanFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(update90DayPlanFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(update90DayPlanFail(error.response));
  }
}

function* onUpdateTechStack(data) {
  try {
    const response = yield call(updateTechStack, data);

    if (response) {
      if (response.code === 200) {
        yield put(updateTechStackSuccess(response.data));
      } else {
        yield put(updateTechStackFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateTechStackFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateTechStackFail(error.response));
  }
}

function* onUpdateTeslaValue(data) {
  try {
    const response = yield call(updateTeslaValue, data);

    if (response) {
      if (response.code === 200) {
        yield put(updateTeslaValueSuccess(response.data));
      } else {
        yield put(updateTeslaValueFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateTeslaValueFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateTeslaValueFail(error.response));
  }
}

function* onUpdateVisionBoard(data) {
  try {
    const response = yield call(updateVisionBoard, data);

    if (response) {
      if (response.code === 200) {
        yield put(updateVisionBoardSuccess(response.data));
      } else {
        yield put(updateVisionBoardFail(response.message));
        showError(response.message);
      }
    } else {
      yield put(updateVisionBoardFail("Didn't get api response"));
    }
  } catch (error) {
    yield put(updateVisionBoardFail(error.response));
  }
}

function* UpdateCanvasSaga() {
  yield takeLatest(UPDATE_RIDRER_AND_DRIVER, onUpdateRiderAndDriver);
  yield takeLatest(UPDATE_90DAY_PLAN, onUpdate90DayPlan);
  yield takeLatest(UPDATE_TECH_STACK, onUpdateTechStack);
  yield takeLatest(UPDATE_TESLA_VALUE, onUpdateTeslaValue);
  yield takeLatest(UPDATE_VISION_BOARD, onUpdateVisionBoard);
}

export default UpdateCanvasSaga;
