import React, { useState, useRef, useEffect } from "react";
import info from "../../../../assets/images/info.svg";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../../Utils/alertMessage";
import { VISION_BOARD } from "../../../../store/saveCanvas/actionTypes";
import { UserId } from "../../../../Utils/getUserId";
import { VisionBoardSuccess } from "../../../../store/saveCanvas/actions";
import { UPDATE_VISION_BOARD } from "../../../../store/updateCanvas/actionTypes";
import { updateVisionBoardSuccess } from "../../../../store/updateCanvas/actions";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import SharedCanvas from "../../../common/sharedCanvasModal";
import DatePicker from "react-datepicker";
import MilestoneModal from "./milestoneModal";
import moment from "moment";
import Loader from "../../../common/loader";
import { useNavigatingAway } from "../../../../Utils/useNavigatingAway";
import DeleteConfirm from "../../../common/deleteConfirm";
import VisionIcon from "../../../../assets/images/vision.png";

const options = [
  { value: "start", label: "Started" },
  { value: "parked", label: "Parked" },
  { value: "need_help", label: "Need Help" },
  { value: "in_prodution", label: "In Production" },
];

function VisionBoard({ vision }) {
  const dispatch = useDispatch();
  const inputRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [activeTab, setActiveTab] = useState({
    tab: "",
    index: "",
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState({
    index: "",
    show: false,
  });

  const [needHelp, setNeedHelp] = useState(false);
  const [status, setStatus] = useState({ value: "start", label: "Started" });
  const [data, SetData] = useState([]);
  const [canvasId, setCanvasId] = useState("");
  const [headerActive, setHeaderActive] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [subHeaderActive, setSubHeaderActive] = useState(false);
  const [subHeaderText, setSubHeaderText] = useState("");
  const [generatePdf, setGeneratePdf] = useState(false);
  const [sort, setSort] = useState({
    priority: "dec",
    dueDate: "dec",
  });

  let { visionBoard, saveCanvasLoading } = useSelector(
    (state) => state.SaveCanvasReducer
  );
  let { updateVisionBoard } = useSelector((state) => state.UpdateCanvas);
  let { loadingLogins } = useSelector((state) => state.GetCanvas);

  document.title = "Vision Board | ATW";
  const handleChange = (e, tab, index) => {
    setCanShowDialogLeavingPage(true);
    let tabValue = [...data];
    if (index !== undefined) {
      if (e.key === "Enter") {
        if (e.target.value.trim() === "") {
          showError("Value Can't Be Empty");
          return;
        }
        if (tab === "priority") {
          tabValue[index][tab] = Number(e.target.value);
        } else {
          tabValue[index][tab] = e.target.value;
        }
        inputRef.current.value = "";
        setActiveTab({ tab: "", index: "" });
      }
    } else {
      if (e.key === "Enter") {
        if (e.target.value.trim() === "") {
          showError("Value Can't Be Empty");
          return;
        }
        tabValue.push({
          priority: tabValue.length + 1,
          achievements: "",
          why: "",
          what: [],
          status: "",
          dueDate: "",
          how: "",
        });
        tabValue[tabValue.length - 1][tab] = e.target.value;
        inputRef.current.value = "";
        setActiveTab({ tab: "", index: "" });
      }
    }
    SetData(tabValue);
  };

  const handleStateChange = (e, index) => {
    let tabValue = [...data];
    tabValue[index].status = e.target.value;
    setActiveTab({ tab: "", index: "" });
  };

  const handleDateChange = (value, index) => {
    let tabValue = [...data];
    tabValue[index].dueDate = value;
    setActiveTab({ tab: "", index: "" });
  };

  const printDocument = async () => {
    handleSave("pdf");
  };

  useEffect(() => {
    if (vision) {
      SetData(vision.vision_data);
      setCanvasId(vision._id);
      setStatus(
        options.find((option) => {
          if (option.value === vision.model_status) return option;
        })
      );
      setHeaderText(vision.title);
      setSubHeaderText(vision.sub_title);
    }
  }, [vision]);

  const handleDelete = (index) => {
    let tabValue = [...data];

    tabValue.splice(index, 1);

    SetData(tabValue);
  };
  const handleSave = (checkPdf) => {
    setCanShowDialogLeavingPage(false);
    if (headerText === "") {
      showError("Enter Canvas Title");
      return;
    }
    if (subHeaderText === "") {
      showError("Enter Canvas Description");
      return;
    }
    if (checkValidation()) {
      showError("Canvas can't be Empty");
    } else {
      if (checkPdf === "pdf") setGeneratePdf(true);
      let payload = {
        user_id: UserId(),
        modal_id: id,
        title: headerText,
        sub_title: subHeaderText,
        vision_data: data,
        // prayority: data.priority,
        // achievement: data.achievements,
        // why: data.why,
        // what: data.what,
        // how: data.how,
        // due_date: data.dueDate,
        // vision_status: data.status,
        model_status: status.value,
        completed: canvasCompleted(),
      };
      if (canvasId === "") {
        dispatch({
          type: VISION_BOARD,
          payload,
        });
      } else {
        payload.modal_id = vision.modal_id.id;
        payload["id"] = canvasId;
        delete payload.user_id;
        dispatch({
          type: UPDATE_VISION_BOARD,
          payload,
        });
      }
    }
  };

  useEffect(() => {
    if (visionBoard) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/vision/pdf/${visionBoard._id}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/vision/pdf/${visionBoard._id}`,
            "_blank"
          );
        }

        setGeneratePdf(false);
      } else {
        if (canvasId === "") {
          setCanvasId(visionBoard._id);
          showSuccess("Canvas Saved Successfully");
          navigate(`/edit/canvas/vision-board/${visionBoard._id}`);
        }
      }
      dispatch(VisionBoardSuccess(null));
    }
  }, [visionBoard]);

  useEffect(() => {
    if (updateVisionBoard) {
      if (generatePdf) {
        if (window.innerWidth <= 768) {
          window.location.assign(
            `${process.env.REACT_APP_APP_URL}/vision/pdf/${canvasId}`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.REACT_APP_APP_URL}/vision/pdf/${canvasId}`,
            "_blank"
          );
        }
        setGeneratePdf(false);
      } else {
        showSuccess("Canvas Updated Successfully");
      }
      dispatch(updateVisionBoardSuccess(null));
    }
  }, [updateVisionBoard]);

  const checkValidation = () => {
    if (data.length === 0) return true;
  };

  const canvasCompleted = () => {
    let partialValue = 0;
    for (const property in data) {
      let what = data[property].what;
      let completeCount = 0;
      if (what.length !== 0) {
        what.map(({ complete }) => {
          if (complete) completeCount += 1;
        });
        if (completeCount === what.length) partialValue += 1;
      }
    }
    return Math.round((100 * partialValue) / data.length);
  };

  const handleStatusChange = (value) => {
    setCanShowDialogLeavingPage(true);
    setStatus(value);
  };

  const handleHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        headerText === "" && showError("Heading Can't Be Empty");
        setHeaderActive(false);
        return;
      }
      setHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setHeaderText(e.target.value);
  };

  const handleSubHeaderText = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        subHeaderText === "" && showError("Description Can't Be Empty");
        setSubHeaderActive(false);
        return;
      }
      setSubHeaderActive(false);
    }
    e.target.value.trim().length !== 0 && setSubHeaderText(e.target.value);
  };
  const sortPriority = () => {
    if (data.length !== 0) {
      let sortValue = [...data];
      if (sort.priority === "asc") {
        sortValue.sort((a, b) => (a.priority > b.priority ? 1 : -1));
        setSort({
          priority: "dec",
          dueDate: "asc",
        });
      } else {
        sortValue.sort((a, b) => (a.priority < b.priority ? 1 : -1));
        setSort({
          priority: "asc",
          dueDate: "asc",
        });
      }
      SetData(sortValue);
    }
  };

  const sortDueDate = () => {
    if (data.length !== 0) {
      let sortValue = [...data];
      if (sort.dueDate === "asc") {
        sortValue.sort((a, b) =>
          new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1
        );
        setSort({
          priority: "asc",
          dueDate: "dec",
        });
      } else {
        sortValue.sort((a, b) =>
          new Date(a.dueDate) < new Date(b.dueDate) ? 1 : -1
        );
        setSort({
          priority: "asc",
          dueDate: "asc",
        });
      }
      SetData(sortValue);
    }
  };

  return (
    <>
      {loadingLogins && <Loader />}
      <div className="right-content-wrap">
        <div className="title-block yellow-bg">
          <div className="title-inner">
            <div className="left-block">
              <i className="icon">
                <img src={VisionIcon} alt="Vision" />
              </i>
              <h2 className="title">Vision Board</h2>
            </div>
            <div className="right-block">
              <button
                type="button"
                className="btn gray-bg big-btn"
                onClick={() => setNeedHelp(true)}
              >
                Help
              </button>

              <div className={`help-popup ${needHelp ? `active` : ""}`}>
                <div className="overlay"></div>
                <div className="help-popup-inner">
                  <div className="title-wrap">
                    <h2>Vision Board Model Navigation</h2>
                    <button
                      className="close-btn"
                      onClick={() => setNeedHelp(false)}
                    ></button>
                  </div>
                  <div className="content">
                    <p>Use the editable text fields to create your Model.</p>
                    <p className="mt-3">
                      Use your mouse cursor to click on the various grey boxes
                      to edit the Model Title, Model Description and each of the
                      key information sections in the model itself.
                    </p>

                    <p className="mt-3">
                      Once finished, click{" "}
                      <span className="gray-btn">Save</span>, and then click
                      <span className="gray-btn">
                        Other Options <i className="icon"></i>
                      </span>{" "}
                      to open the drop down menu. You can then choose whether to
                      Share or download. Clicking download will open the filled
                      model in a PDF for you to save to your computer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="title-inner" onClick={() => setHeaderActive(true)}>
            {headerActive ? (
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleHeaderText(e)}
                  placeholder="Enter Canvas Heading"
                  autoFocus
                  ref={inputRef}
                />
              </div>
            ) : headerText === "" ? (
              <h1 className="title">Enter Canvas Heading</h1>
            ) : (
              <h1 className="title">{headerText}</h1>
            )}
          </div>
          <div className="title-inner" onClick={() => setSubHeaderActive(true)}>
            {subHeaderActive ? (
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows={1}
                  type="text"
                  onKeyUp={(e) => handleSubHeaderText(e)}
                  placeholder="Enter Canvas Description"
                  autoFocus
                  ref={inputRef}
                />
              </div>
            ) : subHeaderText === "" ? (
              <span className="sub-title">Enter Canvas Description</span>
            ) : (
              <span className="sub-title">{subHeaderText}</span>
            )}
          </div> */}
        </div>
        <div className="nav-wrap">
          <div className="left-side">
            <div className="left-inner">
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Title"
                  autoFocus
                  className="form-control heading"
                  onChange={(e) => {
                    setHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={headerText}
                />
              </div>
              <div className="form-group mb-2">
                <textarea
                  rows={1}
                  type="text"
                  placeholder="Click to add Description"
                  className="form-control description"
                  onChange={(e) => {
                    setSubHeaderText(e.target.value);
                    setCanShowDialogLeavingPage(true);
                  }}
                  value={subHeaderText}
                />
              </div>
            </div>
          </div>
          <div className="right-wrap d-flex">
            <div className="right-inner">
              <div className="mb-2">
                <button
                  type="button"
                  className="custom-dropdown-wrapper btn gray-bg"
                >
                  <span>Status:</span>
                  <Select
                    className="custom-select"
                    options={options}
                    value={status}
                    isSearchable={false}
                    onChange={(e) => handleStatusChange(e)}
                    styles={{
                      dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "transform 0.3s",
                        transform: state.selectProps.menuIsOpen
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }),
                    }}
                  />
                </button>
              </div>
              <div className="d-flex mb-2">
                <button
                  type="button"
                  className="btn gray-bg mr-3"
                  onClick={() => handleSave()}
                  disabled={saveCanvasLoading}
                >
                  {canvasId === "" ? "Save" : "Update"}
                </button>

                <Dropdown className="d-flex justify-content-end">
                  <Dropdown.Toggle className="gray-bg btn">
                    Other Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                <button
                // onClick={() => navigate(`/edit${redirect_path}/${_id}`)}
                >
                  Video
                </button>
              </Dropdown.Item> */}
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => printDocument()}
                      >
                        Download
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="btn gray-bg"
                        onClick={() => {
                          canvasId === ""
                            ? showError("Please Save The Canvas")
                            : setShowShareModal(true);
                        }}
                      >
                        Share
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="right-content-inner">
          <div className="data-block-outer overflow-visible">
            {/* <div className="info-wrapper">
              <img src={info} alt="Info" />
              <span className="tooltiptext">
                Vision Board Canvas is a framework that visually affirm your
                goals and dreams into a collection of action items including
                detail about them such as Why, What, How, status and due date.
                The items can be rated based on priority.
              </span>
            </div> */}
            <div className="data-block">
              <div className="grid-block vision-board">
                <table className="table visionboard-table">
                  <thead className="">
                    <tr>
                      <th
                        style={{ maxWidth: "95px" }}
                        scope="col"
                        onClick={() => sortPriority()}
                        className={`sort-by ${
                          sort.priority === "asc" ? "asc" : "desc"
                        }`}
                      >
                        Priority
                      </th>
                      <th scope="col">Achievement</th>
                      <th scope="col">Why</th>
                      <th scope="col">What ( Milestones )</th>
                      {/* <th scope="col" className="sort-by">Milestones</th> */}
                      <th scope="col">How</th>
                      <th scope="col">Status</th>
                      <th
                        scope="col"
                        className={`sort-by ${
                          sort.dueDate === "asc" ? "asc" : "desc"
                        }`}
                        onClick={() => sortDueDate()}
                      >
                        Due Date
                      </th>
                      <th
                        scope="col"
                        style={{ maxWidth: "75px", textAlign: "center" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length !== 0 &&
                      data.map(
                        (
                          {
                            priority,
                            achievements,
                            why,
                            what,
                            status,
                            dueDate,
                            how,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <th
                              style={{ maxWidth: "60px" }}
                              scope="row"
                              onClick={() =>
                                setActiveTab({
                                  tab: "priority",
                                  index,
                                })
                              }
                            >
                              {activeTab.tab === "priority" &&
                              activeTab.index === index ? (
                                <div className="list-wrapper">
                                  <textarea
                                    className="form-control"
                                    rows={2}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleChange(e, "priority", index)
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                </div>
                              ) : (
                                priority || index + 1
                              )}
                            </th>
                            <td
                              onClick={() =>
                                setActiveTab({
                                  tab: "achievements",
                                  index,
                                })
                              }
                            >
                              {activeTab.tab === "achievements" &&
                              activeTab.index === index ? (
                                <div className="list-wrapper">
                                  <textarea
                                    className="form-control"
                                    rows={2}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleChange(e, "achievements", index)
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                </div>
                              ) : (
                                achievements
                              )}
                            </td>

                            <td
                              onClick={() =>
                                setActiveTab({
                                  tab: "why",
                                  index,
                                })
                              }
                            >
                              {activeTab.tab === "why" &&
                              activeTab.index === index ? (
                                <div className="list-wrapper">
                                  <textarea
                                    className="form-control"
                                    rows={2}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleChange(e, "why", index)
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                </div>
                              ) : (
                                why
                              )}
                            </td>
                            <td
                              onClick={() => {
                                setActiveTab({
                                  tab: "what",
                                  index,
                                });
                                setShowMilestoneModal({
                                  index,
                                  show: true,
                                });
                              }}
                              className="milestone-td"
                            >
                              {what.length !== 0 &&
                                what.map(({ complete, value }, index) => (
                                  <div
                                    key={index}
                                    className={complete ? "text-strick" : ""}
                                  >
                                    {value}
                                  </div>
                                ))}
                              {/* {activeTab.tab === "what" &&
                                activeTab.index === index && (
                                  <div className="list-wrapper">
                                    <textarea
                                    className="form-control"
                                      rows={1}
                                      type="text"
                                      onKeyUp={(e) => {
                                        handleChange(e, "what", index);

                                      
                                      }}
                                      autoFocus
                                      ref={inputRef}
                                    />
                                  </div>
                                )} */}
                            </td>

                            <td
                              onClick={() =>
                                setActiveTab({
                                  tab: "how",
                                  index,
                                })
                              }
                            >
                              {activeTab.tab === "how" &&
                              activeTab.index === index ? (
                                <div className="list-wrapper">
                                  <textarea
                                    className="form-control"
                                    rows={2}
                                    type="text"
                                    onKeyUp={(e) =>
                                      handleChange(e, "how", index)
                                    }
                                    autoFocus
                                    ref={inputRef}
                                  />
                                </div>
                              ) : (
                                how
                              )}
                            </td>
                            <td
                              onClick={() =>
                                setActiveTab({
                                  tab: "Status",
                                  index,
                                })
                              }
                            >
                              <div className="form-group mb-0">
                                <select
                                  className="form-control custom-select"
                                  value={status}
                                  onChange={(e) => handleStateChange(e, index)}
                                >
                                  <option value="">Status</option>
                                  <option value="start">Started</option>
                                  <option value="parked">Parked</option>
                                  <option value="need_help">Need Help</option>
                                  <option value="in_prodution">
                                    In Production
                                  </option>
                                </select>
                              </div>
                            </td>

                            <td
                              onClick={() =>
                                setActiveTab({
                                  tab: "dueDate",
                                  index,
                                })
                              }
                            >
                              {/* <div className="list-wrapper"> */}
                              <div className="form-group mb-0">
                                <DatePicker
                                  dateFormat="dd/MM/yyy"
                                  onChange={(value) => {
                                    handleDateChange(value, index);
                                  }}
                                  className="custom-datepicker form-control"
                                  placeholderText="Enter Due Date"
                                  selected={dueDate && new Date(dueDate)}
                                  minDate={moment().toDate()}
                                />
                              </div>
                            </td>
                            <td style={{ maxWidth: "60px" }}>
                              <button
                                className="close-btn"
                                onClick={() => handleDelete(index)}
                              >
                                x
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    <tr>
                      <th
                        style={{ maxWidth: "60px" }}
                        scope="row"
                        onClick={() =>
                          setActiveTab({
                            tab: "priority",
                            index: data.length,
                          })
                        }
                      >
                        {/* {data.length + 1} */}
                      </th>
                      <td
                        onClick={() =>
                          setActiveTab({
                            tab: "achievements",
                            index: data.length,
                          })
                        }
                      >
                        {activeTab.tab === "achievements" &&
                        activeTab.index === data.length ? (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              onKeyUp={(e) => handleChange(e, "achievements")}
                              autoFocus
                              ref={inputRef}
                            />
                          </div>
                        ) : (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              placeholder="Click to add"
                            />
                          </div>
                        )}
                      </td>

                      <td
                        onClick={() =>
                          setActiveTab({
                            tab: "why",
                            index: data.length,
                          })
                        }
                      >
                        {activeTab.tab === "why" &&
                        activeTab.index === data.length ? (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              onKeyUp={(e) => handleChange(e, "why")}
                              autoFocus
                              ref={inputRef}
                            />
                          </div>
                        ) : (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              placeholder="Click to add"
                            />
                          </div>
                        )}
                      </td>
                      <td
                        onClick={() => {
                          setActiveTab({
                            tab: "what",
                            index: data.length,
                          });
                          setShowMilestoneModal({
                            index: "new",
                            show: true,
                          });
                        }}
                      >
                        <div className="list-wrapper">
                          <textarea
                            className="form-control"
                            rows={2}
                            type="text"
                            disabled={true}
                            placeholder="Click to add"
                          />
                        </div>
                        {/* {activeTab.tab === "what" &&
                          activeTab.index === data.length && (
                            <div className="list-wrapper">
                              <textarea
                              className="form-control"
                                rows={1}
                                type="text"
                                onKeyUp={(e) => handleChange(e, "what")}
                                autoFocus
                                ref={inputRef}
                              />
                            </div>
                          )} */}
                      </td>

                      <td
                        onClick={() =>
                          setActiveTab({
                            tab: "how",
                            index: data.length,
                          })
                        }
                      >
                        {activeTab.tab === "how" &&
                        activeTab.index === data.length ? (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              onKeyUp={(e) => handleChange(e, "how")}
                              autoFocus
                              ref={inputRef}
                            />
                          </div>
                        ) : (
                          <div className="list-wrapper">
                            <textarea
                              className="form-control"
                              rows={2}
                              type="text"
                              placeholder="Click to add"
                            />
                          </div>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          setActiveTab({
                            tab: "Status",
                            index: data.length,
                          })
                        }
                      >
                        <div className="form-group mb-0">
                          <select
                            className="form-control"
                            value={""}
                            onChange={(e) =>
                              SetData([
                                ...data,
                                {
                                  priority: data.length + 1,
                                  achievements: "",
                                  why: "",
                                  what: [],
                                  how: "",
                                  status: e.target.value,
                                  dueDate: "",
                                },
                              ])
                            }
                          >
                            <option value="">Status</option>
                            <option value="start">Started</option>
                            <option value="parked">Parked</option>
                            <option value="need_help">Need Help</option>
                            <option value="in_prodution">In Production</option>
                          </select>
                        </div>
                      </td>

                      <td
                        onClick={() =>
                          setActiveTab({
                            tab: "dueDate",
                            index: data.length,
                          })
                        }
                      >
                        {/* <div className="list-wrapper"> */}
                        <div className="form-group mb-0">
                          <DatePicker
                            className="custom-datepicker form-control"
                            onChange={(value) => {
                              SetData([
                                ...data,
                                {
                                  priority: data.length + 1,
                                  achievements: "",
                                  why: "",
                                  what: [],
                                  how: "",
                                  status: "",
                                  dueDate: value,
                                },
                              ]);
                            }}
                            placeholderText="Enter Due Date"
                            minDate={moment().toDate()}
                          />
                        </div>
                      </td>
                      <td style={{ maxWidth: "60px" }}></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {showMilestoneModal && (
          <MilestoneModal
            showMilestoneModal={showMilestoneModal}
            setShowMilestoneModal={setShowMilestoneModal}
            data={data}
            SetData={SetData}
            setCanShowDialogLeavingPage={setCanShowDialogLeavingPage}
          />
        )}

        {showShareModal && (
          <SharedCanvas
            showShareModal={showShareModal}
            setShowShareModal={setShowShareModal}
            canvasId={canvasId}
            adminId={vision && vision.user_id._id}
          />
        )}
        {showDialogLeavingPage && (
          <DeleteConfirm
            handleDeleteClose={cancelNavigation}
            deleteRecord={confirmNavigation}
            msg={
              "There are some changes. If you proceed your changes will be lost"
            }
            icon={"error"}
          />
        )}
      </div>
    </>
  );
}

export default VisionBoard;
