import {
  GET_LOGIN,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  GET_USER_DATA,
  GET_ACTIVE_USER_LIST,
  GET_ACTIVE_USER_LIST_SUCCESS,
  GET_ACTIVE_USER_LIST_FAIL,
  GET_PENDING_USER_LIST,
  GET_PENDING_USER_LIST_SUCCESS,
  GET_PENDING_USER_LIST_FAIL,
  GET_INACTIVE_USER_LIST,
  GET_INACTIVE_USER_LIST_SUCCESS,
  GET_INACTIVE_USER_LIST_FAIL,
} from "./actionTypes";

const initialState = {
  userData: null,
  registration: null,
  forgetPassword: null,
  resetPassword: null,
  countryList: null,
  updateUser: null,
  activeUserList: null,
  pendingUserList: null,
  inactiveUserList: null,
  loadingLogins: false,
  error: {
    message: "",
  },
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN:
      state = {
        ...state,
        loadingLogins: true,
        userData: null,
      };
      break;
    case GET_LOGIN_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        userData: action.payload,
      };
      break;
    case GET_LOGIN_FAIL:
      state = {
        ...state,
        userData: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case REGISTER:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case REGISTER_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        registration: action.payload,
      };
      break;
    case REGISTER_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        registration: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case FORGET_PASSWORD:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        forgetPassword: action.payload,
      };
      break;
    case FORGET_PASSWORD_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        forgetPassword: null,
        error: {
          message: action.payload,
        },
      };
      break;

    case RESET_PASSWORD:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        resetPassword: action.payload,
      };
      break;
    case RESET_PASSWORD_FAIL:
      state = {
        ...state,
        loadingLogins: false,
        resetPassword: null,
        error: {
          message: action.payload,
        },
      };
      break;
    case GET_USER_DATA:
      let data = JSON.parse(localStorage.getItem("data"));
      state = {
        ...state,
        loadingLogins: false,
        userData: data,
      };
      break;

    case GET_COUNTRY_LIST:
      state = {
        ...state,
        loadingLogins: true,
        countryList: null,
      };
      break;
    case GET_COUNTRY_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        countryList: action.payload,
      };
      break;
    case GET_COUNTRY_LIST_FAIL:
      state = {
        ...state,
        userData: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case UPDATE_USER_PROFILE:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case UPDATE_USER_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        updateUser: action.payload,
      };
      break;
    case UPDATE_USER_PROFILE_FAIL:
      state = {
        ...state,
        updateUser: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_ACTIVE_USER_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_ACTIVE_USER_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        activeUserList: action.payload,
      };
      break;
    case GET_ACTIVE_USER_LIST_FAIL:
      state = {
        ...state,
        activeUserList: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_PENDING_USER_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_PENDING_USER_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        pendingUserList: action.payload,
      };
      break;
    case GET_PENDING_USER_LIST_FAIL:
      state = {
        ...state,
        pendingUserList: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    case GET_INACTIVE_USER_LIST:
      state = {
        ...state,
        loadingLogins: true,
      };
      break;
    case GET_INACTIVE_USER_LIST_SUCCESS:
      state = {
        ...state,
        loadingLogins: false,
        inactiveUserList: action.payload,
      };
      break;
    case GET_INACTIVE_USER_LIST_FAIL:
      state = {
        ...state,
        inactiveUserList: null,
        error: {
          message: action.payload,
        },
        loadingLogins: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginReducer;
